import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { NewformaApiClient } from "./NewformaApiClient";
import { UserDataResponse } from "../../models/UserDataResponse";

export class AuthApiService {
    private readonly authUri = "/v1";

    constructor(private newformaApiClient: NewformaApiClient, private requestWrapper: HttpRequestWrapper) {}

    async getUserData(): Promise<UserDataResponse> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}${this.authUri}/me`;
        const options = {
            url: url,
            method: "GET",
            headers: { "x-newforma-agent": this.newformaApiClient.getNewformaAgent() },
        };

        return this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.get(url, undefined, signedOptions.headers, undefined)
        );
    }
}

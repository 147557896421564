export class ExpiredSessionError extends Error {
    private static Message = "The session has expired";

    constructor() {
        super(ExpiredSessionError.Message);

        Object.setPrototypeOf(this, ExpiredSessionError.prototype);
    }

    static isInstanceOf(value: any): value is ExpiredSessionError {
        return value.message === ExpiredSessionError.Message;
    }
}

import { AwsCredentials } from "../models/AwsCredentials";
import { IdpConfiguration } from "../models/IdpConfiguration";
import { ConfigurationService } from "./ConfigurationService";
import { HttpRequestWrapper } from "./HttpRequestWrapper";
import { OpenIDCredentials } from "../models/OpenIDCredentials";
import { Logger } from "./Logger";
import { Eula } from "../models/EulaModels";
import { ConfigurationsResponse } from "../models/ConfigurationsResponse";

export interface RegionsResponse {
    region_list: Region[];
    regions: string;
}

export interface Region {
    name: string;
    name_default: string;
    newforma_region: string;
}

export class UnauthenticatedNewformaApiClient {
    constructor(
        private config: ConfigurationService,
        private requestWrapper: HttpRequestWrapper,
        private logger: Logger
    ) {}

    login(credentials: OpenIDCredentials, eula: Eula | undefined, region?: string): Promise<AwsCredentials> {
        const payload = {
            idToken: credentials.idToken,
            code: credentials.code,
            global: region ? true : false,
            region: region,
            eula: eula,
        };

        this.logger.info(
            `UnauthenticatedNewformaApiClient.login(): logging in to ${
                this.config.hostNameWithProtocol
            }/v1/login (using id_token: ${credentials.idToken.substring(
                credentials.idToken.length - 20
            )}; using code: ${credentials.code.substring(credentials.code.length - 20)})`
        );

        return this.requestWrapper.post(
            `${this.config.hostNameWithProtocol}/v1/login`,
            undefined,
            { "x-newforma-agent": this.getNewformaAgent() },
            JSON.stringify(payload),
            { contentType: "application/json" }
        );
    }

    getIdpConfigForUser(emailAddress: string): Promise<IdpConfiguration> {
        const configUrl = `${this.config.hostNameWithProtocol}/v1/idp-configuration?email=${emailAddress}`;
        return this.requestWrapper.get(configUrl, undefined, { "x-newforma-agent": this.getNewformaAgent() });
    }

    getRegions(twoLettersLanguage: string): Promise<RegionsResponse> {
        const configUrl = `${this.config.hostNameWithProtocol}/v1/properties/regions`;
        return this.requestWrapper.get(configUrl, undefined, {
            "x-newforma-agent": this.getNewformaAgent(),
            "Accept-Language": twoLettersLanguage,
        });
    }

    getConfigurations(): Promise<ConfigurationsResponse> {
        const configUrl = `${this.config.hostNameWithProtocol}/v1/configurations`;
        return this.requestWrapper.get(configUrl, undefined, {
            "x-newforma-agent": this.getNewformaAgent(),
        });
    }

    private getNewformaAgent(): string {
        return `Outlook Web Add-in/${this.config.version}`;
    }
}

export interface CreateUploadSessionRequest {
    folderNrn?: string;
    requestedFileName: string;
    fileSizeInBytes: number;
    mimeType: string;
    allowOverwrite?: boolean;
    batchId: string;
    conflictBehavior?: string;
}

export interface CreateUploadSessionResponse {
    fileNrn: string;
    status: string;
    uploadSessionId: string;
    uploadUrl: string | string[];
    uploadId?: string;
}

export interface CompleteUploadSessionRequest {
    status: UploadSessionStatus;
    uploadId?: string;
    parts?: object[];
}

export enum FileUploadConflictBehavior {
    Fail = "fail",
    Overwrite = "overwrite",
    Rename = "rename",
}

export enum UploadSessionStatus {
    complete = "UploadComplete",
}

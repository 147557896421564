import * as $ from "jquery";

export class HttpRequestWrapper {
    private currentRequest: Array<JQuery.jqXHR<any> | AbortController> = [];

    async get(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: any,
        options?: any
    ) {
        const getObj = $.ajax({ url, dataType, headers, data, type: "GET", ...options });
        this.currentRequest.push(getObj);
        return getObj;
    }

    async post(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: string,
        options?: any
    ) {
        const postObj = $.ajax({ url, dataType, headers, data, type: "POST", ...options });
        this.currentRequest.push(postObj);
        return postObj;
    }

    async patch(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: string,
        options?: any,
        contentType?: string
    ) {
        const patchObj = $.ajax({ url, dataType, headers, data, type: "PATCH", ...options, contentType });
        this.currentRequest.push(patchObj);
        return patchObj;
    }

    async put(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: string | Blob,
        options?: any
    ) {
        const putObj = $.ajax({ url, dataType, headers, data, type: "PUT", ...options });
        this.currentRequest.push(putObj);
        return putObj;
    }

    async sendPut(url: string, data?: string | Blob): Promise<Response> {
        const controller = new AbortController();
        const signal = controller.signal;
        this.currentRequest.push(controller);
        const fetchObj = fetch(url, { method: "PUT", body: data, signal });
        return fetchObj;
    }

    abortCurrentRequest() {
        if (this.currentRequest.length === 0) {
            return;
        }
        this.currentRequest.forEach((request) => (request as JQuery.jqXHR<any> | AbortController).abort());
        this.currentRequest = [];
    }
}

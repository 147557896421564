import { Authenticator } from "../Authenticator";
import { AWSSignatureVersion4Provider } from "../AWSSignatureVersion4Provider";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { ConfigurationService } from "../ConfigurationService";
import { Request } from "aws-sign-web";
import { Logger } from "../Logger";

export class NewformaApiClient {
    private readonly refreshErrors: string[] = [
        "bad_request",
        "expired_token",
        "missing_authentication_token",
        "invalid_signature",
    ];

    constructor(
        private config: ConfigurationService,
        private aws: AWSSignatureVersion4Provider,
        private authenticator: Authenticator,
        private logger: Logger
    ) {}

    private triggerLogoutEvent(): void {
        const event = new Event("logoutEvent");
        window.dispatchEvent(event);
    }

    async makeRequest(options: Request, requestFunc: (headers: Request) => Promise<any>): Promise<any> {
        let credentials = this.authenticator.getStoredCredentials();
        if (!credentials) {
            try {
                await this.authenticator.refreshCredentials();
                credentials = this.authenticator.getStoredCredentials();
                if (!credentials) {
                    this.authenticator.logoutFromApp();
                    this.triggerLogoutEvent();
                    throw new ExpiredSessionError();
                }
            } catch (error) {
                this.authenticator.logoutFromApp();
                this.triggerLogoutEvent();
                this.logger.error(
                    `NewformaClientApi. Logged out user because there were no aws credentails saved, ${new ExpiredSessionError()}`
                );
                throw new ExpiredSessionError();
            }
        }
        const signedOptions = this.aws.getSignatureHeaders(options, credentials);

        try {
            this.logger.info(`Making Newforma Api Request:\n${JSON.stringify(signedOptions)}`);
            return await requestFunc(signedOptions);
        } catch (error) {
            this.logger.error("NewformaClientApi. Error making Newforma API Request", error);
            if (!this.isSignatureExpired(error)) {
                throw error;
            }

            let refreshedCredentials;
            try {
                refreshedCredentials = await this.authenticator.refreshCredentials();
            } catch (refreshCredentialsError) {
                this.logger.error("NewformaClientApi. Failed to refresh AWS Credentials", refreshCredentialsError);
                this.triggerLogoutEvent();
                this.logger.error(
                    `NewformaClientApi. Logged out user due to refresh credentials error, ${new ExpiredSessionError()}`
                );
                throw new ExpiredSessionError();
            }

            if (refreshedCredentials) {
                try {
                    const signedOptionsWithRefreshedCredentials = this.aws.getSignatureHeaders(
                        options,
                        refreshedCredentials
                    );
                    this.logger.info(
                        "NewformaClientApi. Retrying to refresh AWS Credentials",
                        signedOptionsWithRefreshedCredentials
                    );
                    return await requestFunc(signedOptionsWithRefreshedCredentials);
                } catch (retryError) {
                    this.logger.error(
                        "NewformaClientApi. There was an error when retrying the Newforma API request",
                        retryError
                    );

                    if (this.isSignatureExpired(retryError)) {
                        this.triggerLogoutEvent();
                        throw new ExpiredSessionError();
                    }

                    throw retryError;
                }
            }
        }
    }

    private isSignatureExpired(error: any): boolean {
        const errorType =
            error.responseJSON?.type || JSON.parse(error.responseText?.replace(/\n/g, "") || "{}").type || "";
        return (
            error.status === 401 ||
            (error.status === 403 && this.refreshErrors.some((refreshError) => errorType.indexOf(refreshError) >= 0))
        );
    }

    getHostName(): string {
        const slashIndex = this.config.hostNameWithProtocol.indexOf("/");
        return this.config.hostNameWithProtocol.substr(slashIndex + 2);
    }

    getHostNameWithProtocol(): string {
        return this.config.hostNameWithProtocol;
    }

    getNewformaAgent(): string {
        return `Outlook Web Add-in/${this.config.version}`;
    }
}

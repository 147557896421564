import * as React from "react";
import TranslatedDatePickerComponent from "../translatedDatePicker/TranslatedDatePickerComponent";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import "./BoundDateRangePickerComponent.less";
import { ElementVisibility } from "../../../models/shared/ElementVisibility";

export interface BoundDateRangePickerComponentProps extends LocalizeContextProps {
    className?: string;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    startDateLabel: string;
    endDateLabel: string;
    onStartDateChange: (date: Date | undefined) => void;
    onEndDateChange: (date: Date | undefined) => void;
    disabled: boolean;
    startDateRequired: boolean;
    endDateRequired: boolean;
    showClearDateButtonStart?: boolean;
    showClearDateButtonEnd?: boolean;
    isFiling?: boolean;
}

export interface BoundDateRangePickerComponentState {
    startDate: Date | undefined;
    endDate: Date | undefined;
}

class BoundDateRangePickerComponent extends React.Component<
    BoundDateRangePickerComponentProps,
    BoundDateRangePickerComponentState
> {
    constructor(props: BoundDateRangePickerComponentProps, context: BoundDateRangePickerComponentState) {
        super(props, context);

        this.state = {
            startDate: undefined,
            endDate: undefined,
        };
    }

    async componentDidUpdate(
        prevProps: Readonly<BoundDateRangePickerComponentProps>,
        prevState: Readonly<BoundDateRangePickerComponentState>
    ): Promise<void> {
        if (prevProps.startDate !== this.props.startDate) {
            this.setState({ startDate: this.props.startDate });
        }

        if (prevProps.endDate !== this.props.endDate) {
            this.setState({ endDate: this.props.endDate });
        }
    }

    private onStartDateChange(date: Date | undefined): void {
        this.setState({ startDate: date });
        this.props.onStartDateChange(date);
    }

    private onEndDateChange(date: Date | undefined): void {
        this.setState({ endDate: date });
        this.props.onEndDateChange(date);
    }

    private startDateVisibility() {
        if (!!this.props.showClearDateButtonStart) {
            return ElementVisibility.Visible;
        }

        return !!this.props.showClearDateButtonEnd ? ElementVisibility.Hidden : ElementVisibility.None;
    }

    private endDateVisibility() {
        if (!!this.props.showClearDateButtonEnd) {
            return ElementVisibility.Visible;
        }

        return !!this.props.showClearDateButtonStart ? ElementVisibility.Hidden : ElementVisibility.None;
    }

    render(): JSX.Element {
        return (
            <div className={`newforma-flexRow newforma-boundDateRangeWidth ${this.props.className}`}>
                <TranslatedDatePickerComponent
                    date={this.props.startDate}
                    label={this.props.startDateLabel}
                    onDateChange={this.onStartDateChange.bind(this)}
                    className="newforma-boundDatePickerStart newforma-clearDateIsFiling"
                    disabled={this.props.disabled}
                    maxDate={this.state.endDate}
                    required={this.props.startDateRequired}
                    clearDateButtonVisibility={this.startDateVisibility()}
                    isFiling={this.props.isFiling}
                />
                <TranslatedDatePickerComponent
                    date={this.props.endDate}
                    label={this.props.endDateLabel}
                    onDateChange={this.onEndDateChange.bind(this)}
                    className="newforma-boundDatePickerEnd"
                    disabled={this.props.disabled}
                    minDate={this.state.startDate}
                    required={this.props.endDateRequired}
                    clearDateButtonVisibility={this.endDateVisibility()}
                    isFiling={this.props.isFiling}
                />
            </div>
        );
    }
}

export default withLocalize(BoundDateRangePickerComponent);

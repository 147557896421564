import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { DefaultButton, ITag, MessageBar, MessageBarType, Panel, SearchBox, getTheme } from "office-ui-fabric-react";
import { MailboxItem } from "../../services/OfficeWrapper";
import { Logger } from "../../services/Logger";
import SuggestedProjectPickerComponent from "../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import { IProjectsService } from "../../services/NewformaApi/IProjectsService";
import { ConfigurationService } from "../../services/ConfigurationService";
import "./SearchComponent.less";
import LabelComponent from "../shared/label/LabelComponent";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../services/AnalyticsManager";
import { result } from "lodash";
import { getClassForToast } from "../../helpers/ToasterHelpers";

export interface SearchProps extends LocalizeContextProps {
    analyticsManager: AnalyticsManager;
    configService: ConfigurationService;
    logger: Logger;
    projectsService: IProjectsService;
    smartFilingManager: SmartFilingManager;
    onDismiss: () => void;
    onExpiredSession: () => void;
    isLoggedIn: boolean;
    mailboxItem: MailboxItem | null;
    showSearchPanel: boolean;
    theme: string;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    toastType?: MessageBarType;
    toastMessage?: string;
    onDismissToastMessage: () => void;
}

export interface SearchState {
    projects: ITag[];
    selectedProject: ITag | null;
    isLoadingProjects: boolean;
    searchTerm: string;
    errorMessage: string | null;
    shouldFileTransferToastShow: boolean;
}

export const searchComponentDefaultState: SearchState = {
    projects: [],
    selectedProject: null,
    isLoadingProjects: false,
    searchTerm: "",
    errorMessage: null,
    shouldFileTransferToastShow: false,
};

class SearchComponent extends React.Component<SearchProps, SearchState> {
    constructor(props: Readonly<SearchProps>, context: any) {
        super(props, context);

        this.state = searchComponentDefaultState;
    }

    async componentDidMount(): Promise<void> {
        this.props.logger.info("SearchComponent mounted");
    }

    async componentDidUpdate(
        prevProps: Readonly<SearchProps>,
        prevState: Readonly<SearchState>,
        snapshot?: any
    ): Promise<void> {
        if (this.props.toastMessage !== prevProps.toastMessage && this.props.toastMessage) {
            this.setState({ shouldFileTransferToastShow: true });
        }
    }

    get isFileSearchInvalid(): boolean {
        return !this.state.searchTerm;
    }

    private async loadProjects(): Promise<void> {
        this.setState({
            isLoadingProjects: true,
            projects: [],
            selectedProject: null,
            searchTerm: "",
            errorMessage: null,
        });
        try {
            const projectsResponse = await this.props.projectsService.getProjectsSupportingSearch(true);
            const projectsITag: ITag[] = projectsResponse.projects.map((project) => {
                const projectDisplay = project.number ? `${project.number} - ${project.name}` : project.name;
                return { key: project.nrn, name: projectDisplay };
            });
            this.setState({ projects: projectsITag });
        } catch (error) {
            this.handleApiError(error, this.props.translate("SHARED.ERRORS.LOADING_PROJECTS_GENERIC") as string);
            this.setState({
                projects: [],
            });
        } finally {
            this.setState({ isLoadingProjects: false });
        }
    }

    private handleApiError(error: any, messageToDisplay?: string) {
        this.props.logger.error(`SearchComponent API error: ${messageToDisplay}`, error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        if (messageToDisplay) {
            this.setState({ errorMessage: messageToDisplay });
        }
    }

    private onSearch() {
        const nrn = this.state.selectedProject?.key;
        const webAppBase = this.props.configService.webAppURL;
        this.openFileSearch(webAppBase, nrn);
    }

    private openFileSearch(webAppBase: string, nrn: string | undefined) {
        const searchUrl = `${webAppBase}/projects/${nrn}/files?query=${this.state.searchTerm}&searchType=file`;
        window.open(searchUrl, "_blank");
        this.dismissPanel();
        this.props.analyticsManager.recordEvent(AnalyticsCategoryType.UserActions, AnalyticsActionType.ProjectSearched);
    }

    private dismissPanel() {
        this.setState(searchComponentDefaultState);
        this.props.onDismiss();
    }

    private async onProjectSelected(selectedProject: ITag | null): Promise<void> {
        this.setState({ selectedProject: selectedProject });
    }

    private onSearchTermChange(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string): void {
        this.setState({ searchTerm: newValue || "" });
    }

    private renderSearchFileTerm(): JSX.Element {
        return (
            <SearchBox
                className="newforma-searchBox newforma-searchTermBox"
                id="searchFile"
                placeholder={this.props.translate("APP.SEARCH.SEARCH_TERM_PLACEHOLDER") as string}
                required={true}
                value={this.state.searchTerm}
                onChange={this.onSearchTermChange.bind(this)}
                maxLength={256}
                onSearch={this.onSearch.bind(this)}
            />
        );
    }

    private onDismissToastMessage() {
        this.setState({ shouldFileTransferToastShow: false });
        this.props.onDismissToastMessage();
    }

    render(): JSX.Element {
        const getCustomTheme = getTheme();
        return (
            <div className="newforma-searchComponent">
                <Panel
                    className="newforma-searchPanel"
                    isOpen={this.props.showSearchPanel}
                    closeButtonAriaLabel={this.props.translate("APP.MESSAGEBARS.CLOSE") as string}
                    onOuterClick={() => {}}
                    onDismiss={this.dismissPanel.bind(this)}
                    onOpened={this.loadProjects.bind(this)}
                    data-theme={this.props.theme}
                >
                    <div className="newforma-search-error" hidden={!this.state.errorMessage}>
                        <MessageBar messageBarType={MessageBarType.severeWarning}>{this.state.errorMessage}</MessageBar>
                    </div>
                    <div
                        className="newforma-messageBarContainer newforma-messageBar-searchComponent"
                        hidden={!this.props.toastMessage || !this.state.shouldFileTransferToastShow}
                    >
                        <MessageBar
                            className={getClassForToast(
                                this.props.toastType,
                                this.props.toastMessage ? this.props.toastMessage.length : 0
                            )}
                            messageBarType={this.props.toastType}
                            styles={{
                                icon: {
                                    color: this.props.theme !== "default" ? getCustomTheme.palette.black : undefined,
                                },
                            }}
                            isMultiline={false}
                            onDismiss={() => this.onDismissToastMessage()}
                        >
                            {this.props.toastMessage}
                        </MessageBar>
                    </div>

                    <div className="newforma-header">{this.props.translate("APP.SEARCH.PANEL_TITLE") as string}</div>
                    <SuggestedProjectPickerComponent
                        logger={this.props.logger}
                        className="newforma-formSpacing search-suggestedProjectPickerComponent"
                        onProjectSelected={this.onProjectSelected.bind(this)}
                        disabled={this.state.isLoadingProjects}
                        smartFilingManager={this.props.smartFilingManager}
                        projects={this.state.projects}
                        myProjects={this.state.projects}
                        isLoadingProjects={this.state.isLoadingProjects}
                        mailboxItem={this.props.mailboxItem}
                        onRefresh={this.loadProjects.bind(this)}
                        theme={this.props.theme}
                    />

                    <div className="newforma-searchTerm">
                        <div className="newforma-searchHeader">
                            <LabelComponent
                                className="searchBoxLabel"
                                text={this.props.translate("APP.SEARCH.SEARCH_TERM") as string}
                                required={true}
                            />
                        </div>
                        {this.renderSearchFileTerm()}
                    </div>

                    <div className="newforma-searchFooter">
                        <DefaultButton
                            key="searchInProjectButton"
                            id="searchInProjectButton"
                            className="newforma-searchFooterButton"
                            primary={true}
                            onClick={this.onSearch.bind(this)}
                            text={this.props.translate("APP.MESSAGEBARS.SEARCH") as string}
                            disabled={!this.state.selectedProject || this.isFileSearchInvalid}
                        />
                    </div>
                </Panel>
            </div>
        );
    }
}

export default withLocalize(SearchComponent);

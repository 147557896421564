import { Dropdown, IDropdownOption, Label, ResponsiveMode } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Redirect } from "react-router-dom";
import "./NavigationHeaderComponent.less";

export enum AppPage {
    FileEmail,
    FileMultipleEmail,
    FileAsActionItem,
    FileAsSubmittal,
    FileAsSubmittalReviewResponse,
    FileAsRfi,
    FileAsRfiReviewResponse,
    SendAndFile,
}

export interface NavigationHeaderProps extends LocalizeContextProps {
    defaultSelectedKey: AppPage;
    onNavigationChange: (page: AppPage) => void;
    isLoggedIn: boolean;
    isActionItemsSupported: boolean;
    isSubmittalsSupported: boolean;
    isRfiSupported: boolean;
    location: string | null;
    theme: string;
    hideNoProjectsToast: () => void;
}

export interface NavigationHeaderState {
    redirectTo: string | null;
    showLogo: boolean;
}

class NavigationHeaderComponent extends React.Component<NavigationHeaderProps, NavigationHeaderState> {
    constructor(props: NavigationHeaderProps, context: NavigationHeaderState) {
        super(props, context);

        this.state = {
            redirectTo: null,
            showLogo: !(Office.context.diagnostics.platform === Office.PlatformType.OfficeOnline),
        };
    }

    componentDidMount() {
        if (this.props.location === "/fileemail") {
            this.setState({ redirectTo: "/filemultipleemail" });
        } else {
            this.setState({ redirectTo: this.props.location });
        }
    }

    private onNavigationChange(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void {
        if (!item) {
            this.setState({ redirectTo: null });
            return;
        }

        this.props.onNavigationChange(item.key as AppPage);
        switch (item.key) {
            case AppPage.FileMultipleEmail:
                this.setState({ redirectTo: "/filemultipleemail" });
                return;
            case AppPage.FileAsActionItem:
                this.setState({ redirectTo: "/actionitem" });
                this.props.hideNoProjectsToast();
                return;
            case AppPage.FileAsSubmittal:
                this.setState({ redirectTo: "/submittal" });
                this.props.hideNoProjectsToast();
                return;
            case AppPage.FileAsSubmittalReviewResponse:
                this.setState({ redirectTo: "/submittalreviewresponse" });
                this.props.hideNoProjectsToast();
                return;
            case AppPage.FileAsRfi:
                this.setState({ redirectTo: "/rfi" });
                this.props.hideNoProjectsToast();
                return;
            case AppPage.FileAsRfiReviewResponse:
                this.setState({ redirectTo: "/rfireviewresponse" });
                this.props.hideNoProjectsToast();
                return;
        }
    }

    private getDropdownOptions(): IDropdownOption[] {
        const options = [
            { key: AppPage.FileMultipleEmail, text: this.props.translate("APP.FILE_MULTIPLE_EMAIL_HEADER") as string },
        ];
        if (this.props.isActionItemsSupported) {
            options.push({
                key: AppPage.FileAsActionItem,
                text: this.props.translate("APP.FILE_AS_ACTION_ITEM_HEADER") as string,
            });
        }
        if (this.props.isSubmittalsSupported) {
            options.push({
                key: AppPage.FileAsSubmittal,
                text: this.props.translate("APP.FILE_AS_SUBMITTAL_HEADER") as string,
            });
            options.push({
                key: AppPage.FileAsSubmittalReviewResponse,
                text: this.props.translate("APP.FILE_AS_SUBMITTAL_RESPONSE_HEADER") as string,
            });
        }
        if (this.props.isRfiSupported) {
            options.push({
                key: AppPage.FileAsRfi,
                text: this.props.translate("APP.FILE_AS_RFI_HEADER") as string,
            });
            options.push({
                key: AppPage.FileAsRfiReviewResponse,
                text: this.props.translate("APP.FILE_AS_RFI_REVIEW_RESPONSE_HEADER") as string,
            });
        }
        return options;
    }

    private headerElement(): JSX.Element {
        if (this.props.defaultSelectedKey === AppPage.SendAndFile) {
            return (
                <>
                    <Label className="newforma-navPrefix">{this.props.translate("APP.FILE") as string}</Label>
                    <Label className="newforma-pageTitleText">{this.props.translate("SEND_AND_FILE.HEADER")}</Label>
                </>
            );
        }
        const dropdownOptions = this.getDropdownOptions();
        const placeholder = dropdownOptions.find((x) => x.key === this.props.defaultSelectedKey);
        return (
            <>
                <Label className="newforma-navPrefix">{this.props.translate("APP.FILE") as string} </Label>
                <Dropdown
                    className="newforma-navHeader"
                    placeholder={placeholder?.text ?? ""}
                    options={dropdownOptions}
                    responsiveMode={ResponsiveMode.large}
                    onChange={this.onNavigationChange.bind(this)}
                    defaultSelectedKey={this.props.defaultSelectedKey}
                />
            </>
        );
    }

    render(): JSX.Element {
        return (
            <div className="newforma-navHeaderComponent">
                {this.state.redirectTo ? <Redirect to={this.state.redirectTo} /> : null}
                {this.state.showLogo ? (
                    <img
                        width="32"
                        height="32"
                        src={
                            this.props.theme === "dark"
                                ? "assets/NF_white_desktop-icon.svg"
                                : "assets/NewFormaDesktopLogo.svg"
                        }
                        alt="Newforma logo"
                        className="newforma-logo"
                    />
                ) : null}
                {this.headerElement()}
            </div>
        );
    }
}

export default withLocalize(NavigationHeaderComponent);

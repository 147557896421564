import { NewformaApiClient } from "./NewformaApiClient";
import { Logger } from "../Logger";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { EulaAcceptRequest, GetEulaResponse } from "../../models/EulaModels";
import { Request } from "aws-sign-web";

export class EulaApiService {
    constructor(
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private logger: Logger
    ) {}

    private readonly eulaUri = "/v1/eula";

    getEula(): Promise<GetEulaResponse> {
        this.logger.info("Retrieving eula contents");
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}${this.eulaUri}`;

        return this.requestWrapper.get(url);
    }

    respondToEula(accepted: boolean, eulaNrn: string): Promise<{}> {
        this.logger.info(`responding to eula ${eulaNrn} with response: ${accepted}`);
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}${this.eulaUri}/${eulaNrn}/accept`;
        const requestBody: EulaAcceptRequest = {
            isAccepted: accepted,
        };
        const payload = JSON.stringify(requestBody);

        const options: Request = {
            url: url,
            method: "POST",
            body: payload,
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, async (signedOptions: Request) =>
            this.requestWrapper.post(url, undefined, signedOptions.headers, payload)
        );
    }
}

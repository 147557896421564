export enum NumberEntry {
    auto = "AutoNumber",
    fromKeywordField = "FromKeywordField",
    manual = "Manual",
}

export enum DateOffsetType {
    business = "business",
    calendar = "calendar",
}

export interface SubmittalSettings {
    numberEntryMode: NumberEntry;
    dueDateOffsetInDays: number;
    defaultDueDate: string;
    dueDateOffsetType: DateOffsetType;
    reviewResponseDueDateOffsetInDays: number;
    reviewResponseDueDate: string;
    reviewResponseDueDateOffsetType: string;
}

export interface RfiSettings {
    numberEntryMode: NumberEntry;
    dueDateOffsetInDays: number;
    defaultDueDate: string;
    dueDateOffsetType: DateOffsetType;
}

export interface TransferSettings {
    allowPartialDownload: boolean;
    defaultExpireDays: number;
    enabled: boolean;
    requireNixLoginForDownload: boolean;
    transferExpiresByDefault: boolean;
    transferWithTransmittal: boolean;
}

export interface ProjectSettingsResponse {
    submittals: SubmittalSettings;
    rfis: RfiSettings;
    transfer: TransferSettings;
}

import * as React from "react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import "./ProgressComponent.less";

export interface ProgressProps extends LocalizeContextProps {
    message: string;
    className?: string;
}

class ProgressComponent extends React.Component<ProgressProps> {
    constructor(props: ProgressProps) {
        super(props);
        this.state = {};
    }

    render(): JSX.Element {
        const { message } = this.props;

        return (
            <div className={`newforma-ProgressComponent ${this.props.className}`}>
                <Spinner size={SpinnerSize.large} label={message} />
                <br />
            </div>
        );
    }
}

export default withLocalize(ProgressComponent);

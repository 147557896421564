import "./LinkComponent.less";
import * as React from "react";
import { Icon } from "office-ui-fabric-react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";

export interface LinkComponentProps extends LocalizeContextProps {
    text: string;
    onClick: () => void;
    className?: string;
    icon?: string;
    disabled?: boolean;
}

class LinkComponent extends React.Component<LinkComponentProps, any> {
    constructor(props: LinkComponentProps) {
        super(props);
    }

    private onClick(): void {
        this.props.onClick();
    }

    render(): JSX.Element {
        return (
            <div
                className={`newforma-inputLinkContainer ${this.props.className}`}
                onClick={this.props.disabled ? undefined : this.onClick.bind(this)}
            >
                {this.props.icon ? <Icon className="newforma-inputLinkIcon" iconName={this.props.icon} /> : null}
                <div className="newforma-inputLinkButton">{this.props.text}</div>
            </div>
        );
    }
}

export default withLocalize(LinkComponent);

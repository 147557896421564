import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import * as React from "react";
import "./ChipPickerComponent.less";
import {
    CompactPeoplePicker,
    IBasePickerSuggestionsProps,
    IPersonaProps,
    ValidationState,
} from "office-ui-fabric-react";
import { KeywordListType, ProjectKeywordsResponse } from "../../../models/ProjectKeywordsResponse";
import LabelComponent from "../label/LabelComponent";

export interface ChipPickerComponentProps extends LocalizeContextProps {
    items: IPersonaProps[];
    onSelectedItemsChanged: (items: IPersonaProps[]) => void;
    disabled: boolean;
    label: string;
    required: boolean;
    selectedItems: IPersonaProps[];
    allowCustomInput: boolean;
    className?: string;
    itemLimit?: number;
    inputValidator?: (input: string) => ValidationState;
    keywordFilterProps?: {
        filter: (
            projectNrn: string,
            keywordListType: KeywordListType,
            query?: string
        ) => Promise<ProjectKeywordsResponse>;
        projectNrn: string | undefined;
        keywordListType: KeywordListType;
        onError: (error: any) => void;
    };
}

export interface ChipPickerComponentState {
    selectedItems: IPersonaProps[];
}

class ChipPickerComponent extends React.Component<ChipPickerComponentProps, ChipPickerComponentState> {
    defaultState: ChipPickerComponentState = {
        selectedItems: [],
    };

    peoplePickerRef: any = {};

    constructor(props: ChipPickerComponentProps, context: ChipPickerComponentState) {
        super(props, context);

        this.peoplePickerRef = React.createRef();
        this.state = this.defaultState;
    }

    componentDidMount(): void {
        this.setState({ selectedItems: this.props.selectedItems });
    }

    async componentDidUpdate(
        prevProps: Readonly<ChipPickerComponentProps>,
        prevState: Readonly<ChipPickerComponentState>
    ): Promise<void> {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            this.setState({ selectedItems: this.props.selectedItems });
        }
    }

    async onItemsFiltered(filter: string, selectedItems?: IPersonaProps[]): Promise<IPersonaProps[]> {
        let currentItems = this.props.items;

        const filterProps = this.props.keywordFilterProps;
        if (filterProps && filterProps.projectNrn) {
            try {
                const response = await filterProps.filter(filterProps.projectNrn, filterProps.keywordListType, filter);
                currentItems = response.items.map((item) => ({ text: item.name }));
            } catch (error) {
                filterProps.onError(error);
                return [];
            }
        }

        // remove already selected items from list
        currentItems = currentItems.filter((item) => {
            return !this.state.selectedItems.find((x) => {
                if (!x.text || !item.text) {
                    return true;
                }
                return x.text.toLocaleLowerCase().includes(item.text.toLocaleLowerCase());
            });
        });

        if (!currentItems.length) {
            return [];
        }

        if (!filter) {
            return currentItems;
        }

        // return items that contain the same text as filter
        return currentItems.filter((item) => {
            if (!item.text) {
                return false;
            }

            return item.text.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
        });
    }

    private onSelectedItemChange(items?: IPersonaProps[]) {
        if (!items) {
            this.setState({ selectedItems: [] });
            this.props.onSelectedItemsChanged([]);
            return;
        }
        this.setState({ selectedItems: items });
        this.props.onSelectedItemsChanged(items);
    }

    private getPickerSuggestionProps(): IBasePickerSuggestionsProps {
        return {
            suggestionsHeaderText: this.props.translate("CHIP_PICKER.HEADER") as string,
            noResultsFoundText: this.props.translate("CHIP_PICKER.NO_RESULTS_FOUND") as string,
            loadingText: this.props.translate("CHIP_PICKER.LOADING") as string,
            showRemoveButtons: false,
        };
    }

    onCreateGenericItem(input: string, validationState: ValidationState): any {
        return {
            text: input.trim(),
            ValidationState: validationState,
        };
    }

    onValidateInput(input: string): ValidationState {
        if (this.props.inputValidator) {
            return this.props.inputValidator(input);
        }

        if (!input.trim()) {
            return ValidationState.invalid;
        }
        return ValidationState.valid;
    }

    render(): JSX.Element {
        return (
            <div className={`newforma-chipPickerComponentContainer ${this.props.className}`}>
                <LabelComponent text={this.props.label} required={this.props.required} />
                <CompactPeoplePicker
                    className="newforma-chipPickerComponent"
                    selectedItems={this.state.selectedItems}
                    pickerSuggestionsProps={this.getPickerSuggestionProps()}
                    onResolveSuggestions={this.onItemsFiltered.bind(this)}
                    onChange={this.onSelectedItemChange.bind(this)}
                    disabled={this.props.disabled}
                    itemLimit={this.props.itemLimit}
                    resolveDelay={300}
                    onValidateInput={this.props.allowCustomInput ? this.onValidateInput.bind(this) : undefined}
                    createGenericItem={this.props.allowCustomInput ? this.onCreateGenericItem.bind(this) : undefined}
                    onBlur={() => {
                        if (this.peoplePickerRef.current) {
                            this.peoplePickerRef.current.input.current._updateValue("");
                        }
                    }}
                    componentRef={this.peoplePickerRef}
                />
            </div>
        );
    }
}

export default withLocalize(ChipPickerComponent);

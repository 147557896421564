import "./LabelComponent.less";
import * as React from "react";
import { DirectionalHint, HoverCard, HoverCardType, Label } from "office-ui-fabric-react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";

export interface LabelComponentProps extends LocalizeContextProps {
    text: string;
    required?: boolean;
    className?: string;
    showTooltip?: boolean;
}

class LabelComponent extends React.Component<LabelComponentProps, any> {
    constructor(props: LabelComponentProps, context: any) {
        super(props, context);
    }

    private renderLabel(): JSX.Element {
        return (
            <Label className={`newforma-LabelComponent ${this.props.className || ""}`}>
                {this.props.text}
                {this.props.required ? <span className="newforma-required"> *</span> : null}
            </Label>
        );
    }

    render(): JSX.Element {
        return (
            <>
                {this.props.showTooltip ? (
                    <HoverCard
                        plainCardProps={{
                            onRenderPlainCard: () => <div className="newforma-hintText">{this.props.text}</div>,
                            directionalHint: DirectionalHint.topCenter,
                            gapSpace: 5,
                        }}
                        instantOpenOnClick
                        type={HoverCardType.plain}
                        className={`${this.props.className || ""}`}
                    >
                        {this.renderLabel()}
                    </HoverCard>
                ) : (
                    this.renderLabel()
                )}
            </>
        );
    }
}

export default withLocalize(LabelComponent);

import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { KeywordListType, Paging, ProjectKeywordsResponse } from "../../models/ProjectKeywordsResponse";
import { ContractsResponse } from "../../models/projects/ContractsResponse";
import { ProjectSettingsResponse } from "../../models/projects/ProjectSettingsResponse";
import { Project, ProjectsResponse } from "../../models/ProjectsResponse";
import { CreateProjectTeamMembersResponse } from "../../models/projectTeam/CreateProjectTeamMembersResponse";
import { TeamMemberNormalized } from "../../models/projectTeam/TeamViewResponse";
import { Logger } from "../Logger";
import { IProjectsService } from "./IProjectsService";

export class ProjectsPagingErrorService implements IProjectsService {
    private readonly projectsEmpty = { projects: [] };
    constructor(private readonly projectService: IProjectsService, private readonly logger: Logger) {}

    getMyProjects(): Promise<ProjectsResponse> {
        return this.handlePagingError(() => this.projectService.getMyProjects(), this.projectsEmpty);
    }

    getGlobalProjects(paging: Paging): Promise<ProjectsResponse> {
        return this.handlePagingError(() => this.projectService.getGlobalProjects(paging), this.projectsEmpty);
    }

    getProjectsSupportingActionItems(): Promise<ProjectsResponse> {
        return this.handlePagingError(() => this.projectService.getProjectsSupportingActionItems(), this.projectsEmpty);
    }

    getProjectsSupportingSubmittals(): Promise<ProjectsResponse> {
        return this.handlePagingError(() => this.projectService.getProjectsSupportingSubmittals(), this.projectsEmpty);
    }

    getProjectsSupportingRfis(): Promise<ProjectsResponse> {
        return this.handlePagingError(() => this.projectService.getProjectsSupportingRfis(), this.projectsEmpty);
    }
    getProjectsSupportingFileTransfer(): Promise<ProjectsResponse> {
        return this.handlePagingError(
            () => this.projectService.getProjectsSupportingFileTransfer(),
            this.projectsEmpty
        );
    }

    getProjectsSupportingSearch(disableCache: boolean): Promise<ProjectsResponse> {
        return this.handlePagingError(
            () => this.projectService.getProjectsSupportingSearch(disableCache),
            this.projectsEmpty
        );
    }

    getProjectEmailFilingAddress(projectNrn: string): Promise<string | null> {
        return this.projectService.getProjectEmailFilingAddress(projectNrn);
    }

    getProjects(): Promise<ProjectsResponse> {
        return this.handlePagingError(() => this.projectService.getProjects(), this.projectsEmpty);
    }

    getInternalCloudProjects(): Promise<Project[]> {
        return this.handlePagingError(() => this.projectService.getInternalCloudProjects(), []);
    }

    getProjectKeywords(
        projectNrn: string,
        keywordListType: KeywordListType,
        query?: string
    ): Promise<ProjectKeywordsResponse> {
        return this.projectService.getProjectKeywords(projectNrn, keywordListType, query);
    }

    getProjectSettings(projectNrn: string): Promise<ProjectSettingsResponse | null> {
        return this.projectService.getProjectSettings(projectNrn);
    }

    getProjectSubmittalPurposesKeywords(projectNrn: string): Promise<ProjectKeywordsResponse> {
        return this.projectService.getProjectSubmittalPurposesKeywords(projectNrn);
    }

    getProjectForwardSubmittalPurposesKeywords(projectNrn: string): Promise<ProjectKeywordsResponse> {
        return this.projectService.getProjectForwardSubmittalPurposesKeywords(projectNrn);
    }

    getAllTeamMembersNormalized(projectNrn: string, query: string = ""): Promise<TeamMemberNormalized[]> {
        return this.projectService.getAllTeamMembersNormalized(projectNrn, query);
    }

    getTeamMembersNormalized(projectNrn: string, query: string = ""): Promise<TeamMemberNormalized[]> {
        return this.projectService.getTeamMembersNormalized(projectNrn, query);
    }

    getContracts(projectNrn: string): Promise<ContractsResponse> {
        return this.projectService.getContracts(projectNrn);
    }

    createProjectTeamMembers(emails: string[], projectNrn: string): Promise<CreateProjectTeamMembersResponse> {
        return this.projectService.createProjectTeamMembers(emails, projectNrn);
    }

    private async handlePagingError<T>(getData: () => Promise<T>, empty: T): Promise<T> {
        try {
            return await getData();
        } catch (error) {
            const castedError = error as Error & { statusCode: number };
            if (
                ExpiredSessionError.isInstanceOf(error) ||
                (castedError.statusCode === 400 &&
                    castedError.message.includes("Page not found") &&
                    castedError.message.includes("Parameter name: offsetToken"))
            ) {
                this.logger.warning(
                    "Skip this error because of NL paging issue. Hit the refresh button to refresh all projects.",
                    error
                );
                return empty;
            }
            throw error;
        }
    }
}

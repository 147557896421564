import { DetailedKeyword } from "./ProjectKeywordsResponse";
import { ContactRequest } from "./ContactRequest";

export interface CreateActionItemRequest {
    projectNrn: string;
    subject: string;
    dueDate?: string;
    complete?: number; // 0-100
    description?: string;
    type?: DetailedKeyword;
    assignedBy?: ContactRequest;
    assignedTo?: ContactRequest[];
}

export interface CreateActionItemResponse {
    nrn: string;
}

export interface AttachmentLinkResponse {
    path: string;
    status: number;
    body?: any;
}

export enum AttachmentLinkRequestOpType {
    add = "add",
    remove = "remove",
}

export interface AttachmentLinkRequest {
    op: AttachmentLinkRequestOpType;
    path: string;
}

export class AttachmentDataHelpers {
    static replaceCIDReferences(body: string, attachment: any) {
        // Extract CID references and replace them with base64 or URL images
        let processedBody = this.formatHtml(body);
        const cidMatches = body.match(/cid:([^'"\s>]+)/g);

        if (cidMatches) {
            for (const cid of cidMatches) {
                const contentId = cid.replace("cid:", "");
                const result = attachment.value.find((x: any) => x.contentId === contentId);
                if (result) {
                    const base64Image = `data:${result.contentType};base64,${result.contentBytes}`;
                    processedBody = processedBody.replace(cid, base64Image);
                }
            }
        }
        return this.adjustImageStyles(processedBody);
    }

    static formatHtml(html: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        doc.querySelectorAll("td").forEach((td) => {
            td.style.wordBreak = "normal";
            td.style.whiteSpace = "normal";
            td.style.minWidth = "50px";
        });

        return doc.body.innerHTML;
    }

    static adjustImageStyles(html: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        doc.querySelectorAll("img").forEach((img) => {
            img.style.maxWidth = "none";
        });
        return doc.body.innerHTML;
    }
}

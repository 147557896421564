export class ClearMessageService {
    private readonly elementToRemove: string[] = [
        "#divRplyFwdMsg",
        "#Signature",
        "#appendonsend",
        ".gmail_quote",
        "#x_appendonsend",
        "#x_divRplyFwdMsg",
        "#x_x_Signature",
    ];

    getClearMessageBody(messageBodyContent: string, isFroalaEditorSupported: boolean): string {
        if (isFroalaEditorSupported) {
            return messageBodyContent;
        } else {
            const parser = new DOMParser();
            const document = parser.parseFromString(messageBodyContent, "text/html");
            return this.getClearedEmailMessage(document);
        }
    }

    private getClearedEmailMessage(documentBody: Document): string {
        const body = documentBody.querySelector("body");
        if (!body?.innerHTML) {
            return "";
        }
        this.elementToRemove.forEach((node) => {
            const nodeToDele = body.querySelector(node);
            if (nodeToDele) {
                nodeToDele.remove();
            }
        });
        const clearedHtml = this.getText(body.innerHTML);
        return this.clearFromComments(clearedHtml);
    }

    private clearFromComments(html: string): string {
        const clearCommentsRegExp = new RegExp(/<!--[\s\S]*?-->/g);
        return html.replace(clearCommentsRegExp, "");
    }

    private getText(html: string): string {
        const divContainer = document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText;
    }
}

import * as React from "react";
import "./EmailListRowItemComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Checkbox, DirectionalHint, HoverCard, HoverCardType, Icon } from "office-ui-fabric-react";
import { EmailIdentifier } from "../../../models/EmailListResponse";
import moment = require("moment");

export interface EmailListRowItemComponentProps extends LocalizeContextProps {
    className?: string;
    disabled: boolean;
    subject: string;
    from: EmailIdentifier;
    preview: string;
    id: string;
    isFiled: boolean;
    isSelected: boolean;
    sentDateTime: string;
    onSelectionStateChanged: (id: string, checked: boolean) => void;
}

export interface EmailListRowItemComponentState {
    isSelected: boolean;
}

export const defaultEmailListRowItemComponentState: EmailListRowItemComponentState = {
    isSelected: false,
};

class EmailListRowItemComponent extends React.Component<
    EmailListRowItemComponentProps,
    EmailListRowItemComponentState
> {
    constructor(props: EmailListRowItemComponentProps, context: EmailListRowItemComponentState) {
        super(props, context);

        this.state = { ...defaultEmailListRowItemComponentState, isSelected: this.props.isSelected };
    }

    componentDidUpdate(
        prevProps: Readonly<EmailListRowItemComponentProps>,
        prevState: Readonly<EmailListRowItemComponentState>,
        snapshot?: any
    ) {
        if (prevProps.isSelected !== this.props.isSelected) {
            this.setState({ isSelected: this.props.isSelected });
        }
    }

    private onSelectionChange(event?: React.FormEvent<HTMLElement>, checked?: boolean) {
        if (checked === undefined || checked === null) {
            return;
        }
        this.setState({ isSelected: checked });
        this.props.onSelectionStateChanged(this.props.id, checked);
    }

    private static getDate(date: string) {
        const basicFormat = "YYYY-MM-DD";
        return moment(date).format(basicFormat);
    }

    renderFiledHint(): JSX.Element {
        return (
            <div className="newforma-hintText">
                {this.props.translate("FILE_MULTIPLE_EMAIL.FILED_BY_NEWFORMA") as string}
            </div>
        );
    }

    render(): JSX.Element {
        return (
            <div
                className={`newforma-emailListRowItemComponent ${
                    this.state.isSelected ? "newforma-attachmentSelected" : ""
                } ${this.props.className || ""}`}
            >
                <div className="newforma-checkboxContainer">
                    <Checkbox
                        className="newforma-check"
                        checked={this.state.isSelected}
                        onChange={this.onSelectionChange.bind(this)}
                        disabled={this.props.disabled}
                    />
                    {this.props.isFiled ? (
                        <HoverCard
                            plainCardProps={{
                                onRenderPlainCard: this.renderFiledHint.bind(this),
                                directionalHint: DirectionalHint.topCenter,
                                gapSpace: 5,
                            }}
                            instantOpenOnClick
                            type={HoverCardType.plain}
                            className="newforma-mailCheck"
                        >
                            <Icon iconName="MailCheck" />
                        </HoverCard>
                    ) : null}
                </div>
                <div className="newforma-emailListRowDetails">
                    <div className="newforma-emailListRowDetailTopRow">
                        <div className="newforma-from">{`${this.props.from.name}`}</div>
                        <div className="newforma-sentDate">{`${EmailListRowItemComponent.getDate(
                            this.props.sentDateTime
                        )}`}</div>
                    </div>
                    <div className="newforma-subject">{this.props.subject}</div>
                    <div className="newforma-preview">{`${this.props.preview}`}</div>
                </div>
            </div>
        );
    }
}

export default withLocalize(EmailListRowItemComponent);

import { DetailedKeyword } from "../../models/ProjectKeywordsResponse";
import { ContactRequest } from "../../models/ContactRequest";
import { PostEmailFilingResponse } from "../../models/PostEmailFilingResponse";
import { ApiRequestErrorLevel, ApiRequestErrorWithMessage } from "../../models/ApiRequestErrorWithMessage";
import { NewformaApiClient } from "./NewformaApiClient";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { OutlookApiService } from "../OutlookApiService";
import { FileUploadApiService } from "./FileUploadApiService";
import { NrnServiceWrapper } from "../NrnServiceWrapper";
import {
    AttachmentLinkRequest,
    AttachmentLinkRequestOpType,
    AttachmentLinkResponse,
    CreateActionItemRequest,
    CreateActionItemResponse,
} from "../../models/CreateActionItemModels";
import { Request } from "aws-sign-web";
import v4 = require("uuid/v4");
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import AttachmentDetails = Office.AttachmentDetails;
import { Logger } from "../Logger";
import { AttachmentUploadResponses } from "../../models/shared/AttachmentUploadResponses";
import { EmailApiService } from "./EmailApiService";

export class ActionItemApiService {
    constructor(
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private outlookApiService: OutlookApiService,
        private fileUploadApiService: FileUploadApiService,
        private nrnService: NrnServiceWrapper,
        private logger: Logger,
        private emailApiService: EmailApiService
    ) {}

    private readonly actionItemsUri = "/v1/actionitems";
    private readonly supportingDocumentsUriPart = "supportingdocuments";

    // this is the maximum amount of attachments you can include per batch as limited by newforma link
    private readonly supportingDocumentsBatchSizeLimit = 20;

    async createCompleteActionItem(
        messageNrn: string,
        projectNrn: string,
        subject: string,
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        dueDate?: string,
        complete?: number,
        description?: string,
        type?: DetailedKeyword,
        assignedTo?: ContactRequest[],
        assignedBy?: ContactRequest,
        attachmentsToUpload?: AttachmentDetails[]
    ) {
        const createActionItemResponse = await this.createActionItem(
            projectNrn,
            subject,
            dueDate,
            0,
            description,
            type,
            assignedTo,
            undefined
        );
        if (attachmentsToUpload && attachmentsToUpload.length) {
            await this.uploadEmailAttachmentsAsSupportingDocuments(
                createActionItemResponse.nrn,
                attachmentsToUpload,
                fileUploadCallback
            );
        }
        await this.fileEmailToProject(createActionItemResponse.nrn, messageNrn);
    }

    private async createActionItem(
        projectNrn: string,
        subject: string,
        dueDate?: string,
        complete?: number,
        description?: string,
        type?: DetailedKeyword,
        assignedTo?: ContactRequest[],
        assignedBy?: ContactRequest
    ): Promise<CreateActionItemResponse> {
        this.logger.info("Creating action item");
        const requestBody: CreateActionItemRequest = {
            projectNrn,
            subject,
            dueDate,
            complete,
            description,
            type,
            assignedTo,
            assignedBy,
        };

        const url = `${this.newformaApiClient.getHostNameWithProtocol()}${this.actionItemsUri}`;
        const payload = JSON.stringify(requestBody);

        const options: Request = {
            url: url,
            method: "POST",
            body: payload,
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        try {
            return await this.newformaApiClient.makeRequest(options, async (signedOptions) =>
                this.requestWrapper.post(url, undefined, signedOptions.headers, payload)
            );
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                throw error;
            }
            if (error && (error as any).status === 501) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    "ACTION_ITEM.CREATE_FAILED_INVALID_NL",
                    (error as any).status
                );
            } else {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    "ACTION_ITEM.CREATE_FAILED",
                    (error as any).status
                );
            }
        }
    }

    private async attachFilesAsSupportingDocuments(
        actionItemNrn: string,
        fileNrnsToAttach: string[]
    ): Promise<AttachmentLinkResponse[]> {
        this.logger.info("Attaching email file(s) to Action Item as supporting documents");
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}${this.actionItemsUri}/${actionItemNrn}/${
            this.supportingDocumentsUriPart
        }`;
        const requestData: AttachmentLinkRequest[] = fileNrnsToAttach.map((fileNrn) => {
            return {
                op: AttachmentLinkRequestOpType.add,
                path: `/${fileNrn}`,
            };
        });

        const payload = JSON.stringify(requestData);

        const options: Request = {
            url: url,
            method: "PATCH",
            body: payload,
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions: Request) =>
            this.requestWrapper.patch(
                url,
                undefined,
                signedOptions.headers,
                JSON.stringify(requestData),
                undefined,
                "application/json"
            )
        );
    }

    private async uploadEmailAttachmentsAsSupportingDocuments(
        actionItemNrn: string,
        attachmentsToUpload: AttachmentDetails[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void
    ): Promise<void> {
        const fileUploadResult = await this.uploadAttachments(attachmentsToUpload, actionItemNrn, fileUploadCallback);

        await this.attachFiles(
            fileUploadResult.uploadedFileNrns,
            actionItemNrn,
            fileUploadResult.failedFileIds,
            fileUploadCallback
        );

        if (fileUploadResult.failedFileIds.length) {
            throw new ApiRequestErrorWithMessage(
                "one or more files failed to upload",
                ApiRequestErrorLevel.ERROR,
                "ACTION_ITEM.CREATE_FAILED_ATTACHMENTS_UPLOAD",
                400
            );
        }
    }

    private async uploadAttachments(
        attachmentsToUpload: AttachmentDetails[],
        actionItemNrn: string,
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void
    ): Promise<AttachmentUploadResponses> {
        const filesThatFailed: string[] = [];
        let fileNrns: string[];
        try {
            fileUploadCallback(true, []);
            const batchId = v4();
            const destinationFolderNrn = this.nrnService.getActionItemDestinationFolderNrn(actionItemNrn);
            const uploadPromises = attachmentsToUpload.map((attachment) => {
                return this.outlookApiService
                    .getExtensionAndMimeForItemAttachment(
                        attachment.id,
                        attachment.attachmentType,
                        attachment.contentType
                    )
                    .then(async (attachmentMetadata) => {
                        const attachmentContent = await this.outlookApiService.getEmailAttachmentBytes(
                            attachment.id,
                            attachment.attachmentType,
                            attachmentMetadata.mimeType
                        );
                        return this.fileUploadApiService.uploadFile(
                            `${attachment.name || "_"}${attachmentMetadata.extension}`,
                            attachmentContent,
                            batchId,
                            destinationFolderNrn
                        );
                    })
                    .catch((error) => {
                        this.logger.error(
                            `ActionItemApiService.getExtensionAndMimeForItemAttachment. Attachment details ${attachment}`,
                            error
                        );
                        filesThatFailed.push(attachment.id);
                        if (ExpiredSessionError.isInstanceOf(error)) {
                            throw error;
                        }
                        if (error.status === 501) {
                            throw error;
                        }
                        return "";
                    });
            });
            fileNrns = await Promise.all(uploadPromises);
        } catch (error) {
            this.logger.error("ActionItemApiService upload attachments", error);
            fileUploadCallback(false, filesThatFailed);
            if (ExpiredSessionError.isInstanceOf(error)) {
                throw error;
            }
            throw new ApiRequestErrorWithMessage(
                (error as any).message,
                ApiRequestErrorLevel.ERROR,
                "ACTION_ITEM.CREATE_FAILED_ATTACHMENTS_UPLOAD_INVALID_NL",
                (error as any).status
            );
        }
        return {
            uploadedFileNrns: fileNrns.filter((nrn) => !!nrn),
            failedFileIds: filesThatFailed,
        };
    }

    private async attachFiles(
        filesToAttach: string[],
        actionItemNrn: string,
        filesThatFailed: string[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void
    ): Promise<void> {
        try {
            const files = filesToAttach;
            const chunks = [];

            while (files.length) {
                const chunkSize =
                    files.length > this.supportingDocumentsBatchSizeLimit
                        ? this.supportingDocumentsBatchSizeLimit
                        : files.length;
                const chunk = files.splice(0, chunkSize);
                chunks.push(chunk);
            }

            // these calls must be sequential otherwise newformalink returns a 409
            for (const chunk of chunks) {
                await this.attachFilesAsSupportingDocuments(actionItemNrn, chunk);
            }
        } catch (error) {
            this.logger.error("ActionItemApiService attach files", error);
            if (ExpiredSessionError.isInstanceOf(error)) {
                throw error;
            }
            if (error && (error as any).status === 501) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    "ACTION_ITEM.CREATE_FAILED_ATTACHMENTS_ATTACH_INVALID_NL",
                    (error as any).status
                );
            }
            if (filesThatFailed.length) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    "ACTION_ITEM.CREATE_FAILED_ATTACHMENTS_UPLOAD_AND_ATTACH",
                    (error as any).status
                );
            } else {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    "ACTION_ITEM.CREATE_FAILED_ATTACHMENTS_ATTACH",
                    (error as any).status
                );
            }
        } finally {
            fileUploadCallback(false, filesThatFailed);
        }
    }

    private async fileEmailToProject(actionItemNrn: string, messageNrn: string): Promise<void> {
        let fileToProjectResponse: PostEmailFilingResponse;
        try {
            fileToProjectResponse = await this.emailApiService.fileToProject(actionItemNrn, messageNrn);
        } catch (error) {
            this.logger.error("ActionItemApiService file email to project", error);
            if (ExpiredSessionError.isInstanceOf(error)) {
                throw error;
            }
            if (error && (error as any).status === 501) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.WARNING,
                    "ACTION_ITEM.CREATE_FAILED_EMAIL_NOT_CREATED_INVALID_NL",
                    (error as any).status
                );
            } else {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    "ACTION_ITEM.CREATE_FAILED_EMAIL_NOT_CREATED",
                    (error as any).status
                );
            }
        }
        if (fileToProjectResponse && !fileToProjectResponse.emailLogIsSupported) {
            throw new ApiRequestErrorWithMessage(
                "attaching email to action item email log is not supported in this version of newformalink",
                ApiRequestErrorLevel.WARNING,
                "ACTION_ITEM.CREATE_FAILED_EMAIL_NOT_ATTACHED",
                501
            );
        }
    }
}

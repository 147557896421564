import { NewformaApiClient } from "./NewformaApiClient";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import {
    CompleteUploadSessionRequest,
    CreateUploadSessionRequest,
    CreateUploadSessionResponse,
    FileUploadConflictBehavior,
    UploadSessionStatus,
} from "../../models/UploadFileModels";
import { Request } from "aws-sign-web";
import { Logger } from "../Logger";
import { AttachmentDetailsMetadata } from "../../models/AttachmentDetailsMetadata";
import { AttachmentUploadResponses } from "../../models/shared/AttachmentUploadResponses";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import * as pLimit from "p-limit";
import * as pRetry from "p-retry";

export class FileUploadApiService {
    private readonly fileUploadUrl = "/v1/files/uploadsessions";
    private readonly minPartFileSize = 5600000;
    private readonly maxSimultaneousChunksSending = 10;

    constructor(
        private readonly newformaApiClient: NewformaApiClient,
        private readonly requestWrapper: HttpRequestWrapper,
        private readonly logger: Logger,
        private readonly retryOptions: pRetry.Options = {
            retries: 5,
            onFailedAttempt: (error) => {
                this.logger.warning(
                    `Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`,
                    error
                );

                if (error instanceof DOMException && error.name === "AbortError") {
                    throw new pRetry.AbortError(error);
                }
            },
        }
    ) {}

    async uploadFile(
        fileName: string,
        file: Blob,
        batchId: string,
        folderNrn: string,
        fileUploadProgress?: () => any
    ): Promise<string> {
        const uploadSession = await this.createUploadSession(fileName, file.size, file.type, batchId, folderNrn);

        let parts = [] as object[];
        if (!uploadSession.uploadId) {
            await this.uploadBytesToS3(file, uploadSession.uploadUrl as string);
        } else {
            parts = await this.multiUploadBytesToS3(file, uploadSession.uploadUrl as string[]);
        }
        await this.completeUploadSession(uploadSession.uploadSessionId, uploadSession.uploadId, parts);

        if (fileUploadProgress) {
            fileUploadProgress();
        }
        return uploadSession.fileNrn;
    }

    async uploadWorkflowAttachments(
        attachmentsToUpload: AttachmentDetailsMetadata[],
        destinationFolderNrn: string,
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        batchId: string,
        fileUploadProgress?: () => any
    ): Promise<AttachmentUploadResponses> {
        const filesThatFailed: string[] = [];
        let fileNrns: string[] = [];
        fileUploadCallback(true, []);
        const uploadPromises = attachmentsToUpload.map((attachment) => {
            return this.uploadFile(
                attachment.fileName,
                attachment.file,
                batchId,
                destinationFolderNrn,
                fileUploadProgress
            ).catch((error) => {
                this.logger.error(
                    `FileUploadApiService.uploadWorkflowAttachments details: ${attachmentsToUpload} destinationFolderNrn: ${destinationFolderNrn} batchId: ${batchId}`,
                    error
                );
                filesThatFailed.push(attachment.id);
                if (ExpiredSessionError.isInstanceOf(error)) {
                    fileUploadCallback(false, filesThatFailed);
                    throw error;
                }
                return "";
            });
        });
        fileNrns = await Promise.all(uploadPromises);

        return {
            uploadedFileNrns: fileNrns.filter((nrn) => !!nrn),
            failedFileIds: filesThatFailed,
        };
    }

    private async createUploadSession(
        fileName: string,
        fileSizeInBytes: number,
        mimeType: string,
        batchId: string,
        folderNrn: string
    ): Promise<CreateUploadSessionResponse> {
        this.logger.info("Creating file upload session");
        const requestData: CreateUploadSessionRequest = {
            folderNrn: folderNrn,
            requestedFileName: fileName,
            fileSizeInBytes,
            mimeType: mimeType || "application/octet-stream",
            allowOverwrite: false,
            batchId,
            conflictBehavior: FileUploadConflictBehavior.Rename,
        };

        const payload = JSON.stringify(requestData);
        const options: Request = {
            url: `${this.newformaApiClient.getHostNameWithProtocol()}${this.fileUploadUrl}`,
            method: "POST",
            body: payload,
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions: Request) =>
            this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, payload)
        );
    }

    private uploadBytesToS3(file: Blob, signedS3Url: string): Promise<void> {
        this.logger.info("Uploading file bytes to S3");
        return this.requestWrapper.put(signedS3Url, undefined, undefined, file, {
            contentType: file.type || "application/octet-stream",
            processData: false,
        });
    }

    private async sendChunk(url: string, slicedFile: Blob): Promise<Response> {
        try {
            return await pRetry(async () => {
                const response = await this.requestWrapper.sendPut(url, slicedFile);
                if (response?.headers?.get("etag") === "") {
                    throw Error(`Chunk returned not etag for url. ${url}`);
                }
                return response;
            }, this.retryOptions);
        } catch (error) {
            this.logger.error(`FileUploadApiService. Chunk upload failed ${url}`, error);
            throw error;
        }
    }

    private async multiUploadBytesToS3(file: Blob, signedS3Url: string[]): Promise<object[]> {
        const limit = pLimit(this.maxSimultaneousChunksSending);
        this.logger.info(`Uploading file bytes to S3 (multipart upload). signedS3Url.length ${signedS3Url.length}`);
        const partFileSize = this.calculatePartFileSize(file, signedS3Url.length);
        const promises = [];
        this.logger.info(`Part size ${partFileSize}`);

        for (let index = 0; index < signedS3Url.length; index++) {
            const element = signedS3Url[index].replace(".s3.amazonaws.com", "");
            const slicedFile = file.slice(index * partFileSize, (index + 1) * partFileSize);

            promises.push(limit(() => this.sendChunk(element, slicedFile)));
        }

        this.logger.info("Uploading parts");
        const results = await Promise.all(promises);
        this.logger.info("Finish upload");

        const parts = results.map((part, index) => ({
            ETag: part?.headers?.get("etag") || "",
            PartNumber: index + 1,
        }));
        this.logger.info(`Finish upload`, parts);

        return parts;
    }

    private calculatePartFileSize(file: Blob, numberOfParts: number): number {
        const partFileSize = file.size / numberOfParts;
        return partFileSize < this.minPartFileSize ? this.minPartFileSize : partFileSize;
    }

    private async completeUploadSession(
        uploadSessionId: string,
        uploadId?: string,
        parts?: object[]
    ): Promise<CreateUploadSessionResponse> {
        this.logger.info("Completing upload session");

        const requestData: CompleteUploadSessionRequest = {
            status: UploadSessionStatus.complete,
            uploadId: uploadId,
            parts: parts,
        };
        const payload = JSON.stringify(requestData);
        const options: Request = {
            url: `${this.newformaApiClient.getHostNameWithProtocol()}${this.fileUploadUrl}/${uploadSessionId}`,
            method: "PATCH",
            body: payload,
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, (signedOptions: Request) =>
            this.requestWrapper.patch(signedOptions.url, undefined, signedOptions.headers, payload)
        );
    }
}

import "./InsertTeamMemberComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Checkbox, DefaultButton, IContextualMenuItem, ITag, MessageBarType, SearchBox } from "office-ui-fabric-react";
import LabelComponent from "../../shared/label/LabelComponent";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../../services/AnalyticsManager";
import ProgressComponent from "../../shared/progress/ProgressComponent";
import TeamMemberRowComponent from "../teamMemberRow/TeamMemberRowComponent";
import { ExpiredSessionError } from "../../../models/ExpiredSessionError";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import { Logger } from "../../../services/Logger";
import { TeamMemberNormalized } from "../../../models/projectTeam/TeamViewResponse";
import EmailAddressDetails = Office.EmailAddressDetails;
import { SendAndFileHelpers } from "../../../helpers/SendAndFile/SendAndFileHelpers";
import { OfficeWrapper } from "../../../services/OfficeWrapper";

export enum TeamMemberAddLocation {
    to = "projectTeamAddTo",
    cc = "projectTeamAddCc",
}

export interface TeamMembersWithSelectedStatus extends TeamMemberNormalized {
    isSelected: boolean;
}

export interface InsertTeamMemberComponentProps extends LocalizeContextProps {
    selectedProject: ITag | null;
    isLoadingProjects: boolean;
    analyticsManager: AnalyticsManager;
    projectsService: IProjectsService;
    logger: Logger;
    sendAndFileHelpers: SendAndFileHelpers;
    officeWrapper: OfficeWrapper;
    onExpiredSession: () => void;
    onShowToast: (message: string | null, type: MessageBarType) => void;
}

export interface InsertTeamMemberComponentState {
    selectedProject: ITag | null;
    isLoadingProjects: boolean;
    isLoadingTeamMembers: boolean;
    teamMembers: TeamMembersWithSelectedStatus[];
    visibleTeamMembers: TeamMembersWithSelectedStatus[];
    isAddingTeamMembers: boolean;
}

const defaultInsertTeamMemberComponentState: InsertTeamMemberComponentState = {
    selectedProject: null,
    isLoadingProjects: false,
    isLoadingTeamMembers: false,
    teamMembers: [],
    visibleTeamMembers: [],
    isAddingTeamMembers: false,
};

class InsertTeamMemberComponent extends React.Component<
    InsertTeamMemberComponentProps,
    InsertTeamMemberComponentState
> {
    recipientType = "externalUser";
    responseType = "none";

    constructor(props: InsertTeamMemberComponentProps, context: InsertTeamMemberComponentState) {
        super(props, context);

        this.state = {
            ...defaultInsertTeamMemberComponentState,
            selectedProject: props.selectedProject,
            isLoadingProjects: this.props.isLoadingProjects,
        };
    }

    async componentDidMount(): Promise<void> {
        await this.getProjectTeamMembers(this.props.selectedProject?.key);
    }

    async componentDidUpdate(
        prevProps: Readonly<InsertTeamMemberComponentProps>,
        prevState: Readonly<any>,
        snapshot?: any
    ): Promise<void> {
        if (prevProps.selectedProject !== this.props.selectedProject) {
            await this.getProjectTeamMembers(this.props.selectedProject?.key);
            this.setState({ selectedProject: this.props.selectedProject });
        }

        if (prevProps.isLoadingProjects !== this.props.isLoadingProjects) {
            this.setState({ isLoadingProjects: this.props.isLoadingProjects, teamMembers: [], visibleTeamMembers: [] });
        }
    }

    private async getProjectTeamMembers(projectId: string | undefined, query: string = ""): Promise<void> {
        if (!projectId) {
            this.setState({ teamMembers: [], isLoadingTeamMembers: false, visibleTeamMembers: [] });
            return;
        }

        this.setState({ isLoadingTeamMembers: true });
        try {
            const teamMembers = await this.props.projectsService.getAllTeamMembersNormalized(projectId, query);
            const sortedTeamMembers = teamMembers.sort((a, b) => a.displayName.localeCompare(b.displayName));
            if (query) {
                const teamMembersWithSelectedStatus = sortedTeamMembers.map((member) => ({
                    ...member,
                    isSelected: this.state.teamMembers.find((x) => x.nrn === member.nrn)?.isSelected || false,
                }));
                this.setState({ visibleTeamMembers: teamMembersWithSelectedStatus });
            } else {
                const teamMembersWithSelectedStatus = sortedTeamMembers.map((member) => ({
                    ...member,
                    isSelected: false,
                }));
                this.setState({
                    teamMembers: teamMembersWithSelectedStatus,
                    visibleTeamMembers: teamMembersWithSelectedStatus,
                });
            }
        } catch (error) {
            this.handleApiError(error, this.props.translate("PROJECT_TEAM.ERROR_LOADING_TEAM_MEMBERS") as string);
        } finally {
            this.setState({ isLoadingTeamMembers: false });
        }
    }

    private async onSearch(searchValue: string | null): Promise<void> {
        if (!searchValue) {
            await this.onSearchClear();
            return;
        }
        this.props.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserActions,
            AnalyticsActionType.ProjectTeamSearched
        );
        await this.getProjectTeamMembers(this.state.selectedProject?.key, searchValue);
    }

    private async onSearchClear(): Promise<void> {
        this.setState((state) => ({ visibleTeamMembers: state.teamMembers }));
    }

    private handleApiError(error: any, messageToDisplay?: string) {
        this.props.logger.error(`InsertTeamMemberComponent API error ${messageToDisplay}`, error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        if (messageToDisplay) {
            this.props.onShowToast(messageToDisplay, MessageBarType.severeWarning);
        }
    }

    private onRowSelectionStatusChange(id: string, checked: boolean): void {
        const currentTeamMembers = this.state.teamMembers;
        const currentVisibleTeamMembers = this.state.visibleTeamMembers;
        const newTeamMembers = currentTeamMembers.map((member) => {
            if (member.nrn === id) {
                return { ...member, isSelected: checked };
            }
            return member;
        });

        const newVisibleTeamMembers = currentVisibleTeamMembers.map((member) => {
            if (member.nrn === id) {
                return { ...member, isSelected: checked };
            }
            return member;
        });

        this.setState({ teamMembers: newTeamMembers, visibleTeamMembers: newVisibleTeamMembers });
    }

    onAddToOptionChanged(
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item?: IContextualMenuItem
    ): void {
        // tslint:disable-next-line
        this.onSubmit(item?.key as string);
    }

    private async onSubmit(addToLocation: string): Promise<void> {
        this.setState({ isAddingTeamMembers: true });
        const emails: EmailAddressDetails[] = this.selectedTeamMembers().map((member) => ({
            emailAddress: member.email as string,
            displayName: member.displayName as string,
            appointmentResponse: this.responseType,
            recipientType: this.recipientType,
        }));

        switch (addToLocation) {
            case TeamMemberAddLocation.to:
                this.props.analyticsManager.recordEvent(
                    AnalyticsCategoryType.UserActions,
                    AnalyticsActionType.ProjectTeamAddedTo,
                    `count: ${emails.length}`
                );
                await this.props.sendAndFileHelpers.addUniqueEmailsTo(emails);
                break;
            case TeamMemberAddLocation.cc:
                this.props.analyticsManager.recordEvent(
                    AnalyticsCategoryType.UserActions,
                    AnalyticsActionType.ProjectTeamAddedCc,
                    `count: ${emails.length}`
                );
                await this.props.sendAndFileHelpers.addUniqueEmailsCc(emails);
                break;
        }

        const uncheckedTeamMembers = this.state.teamMembers.map((member) => ({ ...member, isSelected: false }));
        const uncheckedVisibleTeamMembers = this.state.visibleTeamMembers.map((member) => ({
            ...member,
            isSelected: false,
        }));
        this.setState({
            teamMembers: uncheckedTeamMembers,
            isAddingTeamMembers: false,
            visibleTeamMembers: uncheckedVisibleTeamMembers,
        });
    }

    private renderTeamMemberRows(): JSX.Element {
        if (!this.state.selectedProject) {
            return (
                <div className="newforma-projectTeamInfo">
                    {this.props.translate("PROJECT_TEAM.SELECT_A_PROJECT") as string}
                </div>
            );
        }

        if (this.state.isLoadingTeamMembers) {
            return (
                <ProgressComponent
                    className="newforma-loadingTeamMembersIndicator"
                    message={this.props.translate("PROJECT_TEAM.LOADING") as string}
                />
            );
        }

        if (!this.state.teamMembers.length || !this.state.visibleTeamMembers.length) {
            return (
                <div className="newforma-projectTeamInfo">
                    {this.props.translate("PROJECT_TEAM.NO_TEAM_MEMBERS") as string}
                </div>
            );
        }

        const teamMemberRows = this.state.visibleTeamMembers.map((member, index) => {
            return (
                <TeamMemberRowComponent
                    teamMember={member}
                    isSelected={member.isSelected}
                    onSelectionChange={this.onRowSelectionStatusChange.bind(this)}
                    key={`teamMemberKey-${index}`}
                />
            );
        });

        return <>{teamMemberRows}</>;
    }

    private selectedTeamMembers(): TeamMembersWithSelectedStatus[] {
        return this.state.teamMembers.filter((member) => member.isSelected);
    }

    private selectedVisibleTeamMembers(): TeamMembersWithSelectedStatus[] {
        return this.state.visibleTeamMembers.filter((member) => member.isSelected);
    }

    private submitDisabled(): boolean {
        return (
            this.state.isLoadingProjects ||
            this.state.isLoadingTeamMembers ||
            !this.selectedTeamMembers().length ||
            this.props.officeWrapper.isReadMode() ||
            this.state.isAddingTeamMembers
        );
    }

    private isSelectAllIndeterminate(): boolean {
        const selectedTeamMembers = this.selectedVisibleTeamMembers();
        return selectedTeamMembers.length > 0 && selectedTeamMembers.length !== this.state.visibleTeamMembers.length;
    }

    private areAllItemsSelected(): boolean {
        const selectedTeamMembers = this.selectedVisibleTeamMembers();
        return selectedTeamMembers.length > 0 && selectedTeamMembers.length === this.state.visibleTeamMembers.length;
    }

    private onSelectAllChanged(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        if (checked === undefined) {
            return;
        }

        const teamMembers = this.state.visibleTeamMembers;

        if (checked || (!checked && this.isSelectAllIndeterminate())) {
            const visibleTeamMembersAllSelected = teamMembers.map((member) => {
                return { ...member, isSelected: true };
            });
            const teamMembersUpdatedSelected = this.state.teamMembers.map((member) => {
                if (visibleTeamMembersAllSelected.some((visibleMember) => visibleMember.nrn === member.nrn)) {
                    return { ...member, isSelected: true };
                } else {
                    return member;
                }
            });
            this.setState({
                teamMembers: teamMembersUpdatedSelected,
                visibleTeamMembers: visibleTeamMembersAllSelected,
            });
            return;
        }

        if (!checked) {
            const visibleTeamMembersNoneSelected = teamMembers.map((member) => ({ ...member, isSelected: false }));
            const teamMembersUpdatedNoneSelected = this.state.teamMembers.map((member) => {
                if (visibleTeamMembersNoneSelected.some((visibleMember) => visibleMember.nrn === member.nrn)) {
                    return { ...member, isSelected: false };
                } else {
                    return member;
                }
            });
            this.setState({
                teamMembers: teamMembersUpdatedNoneSelected,
                visibleTeamMembers: visibleTeamMembersNoneSelected,
            });
            return;
        }
    }

    private primaryButtonLabel(): string {
        const currentLabel = this.props.translate("PROJECT_TEAM.ADD_TO") as string;
        return `${currentLabel}${this.getSelectedLabel()}`;
    }

    private getSelectedLabel(): string {
        const selectedCount = this.selectedTeamMembers().length;
        const count = !!selectedCount ? ` (${selectedCount})` : "";
        return count;
    }

    render(): JSX.Element {
        return (
            <>
                <LabelComponent
                    className="searchBoxLabel"
                    text={this.props.translate("PROJECT_TEAM.SEARCH_LABEL") as string}
                />
                <SearchBox
                    className="newforma-searchBox"
                    placeholder={this.props.translate("PROJECT_TEAM.SEARCH_PLACEHOLDER") as string}
                    onSearch={this.onSearch.bind(this)}
                    onClear={this.onSearchClear.bind(this)}
                    disabled={
                        !this.state.selectedProject || this.state.isLoadingProjects || this.state.isAddingTeamMembers
                    }
                />
                <div className="newforma-selectTeamMemberHeader">
                    <Checkbox
                        id="projectTeam-selectAll"
                        className="newforma-checkbox"
                        checked={this.areAllItemsSelected()}
                        indeterminate={this.isSelectAllIndeterminate()}
                        onChange={this.onSelectAllChanged.bind(this)}
                        disabled={
                            this.state.isLoadingTeamMembers ||
                            this.state.isLoadingProjects ||
                            !this.state.teamMembers.length ||
                            this.state.isAddingTeamMembers
                        }
                    />
                    <LabelComponent
                        text={this.props.translate("PROJECT_TEAM.SELECT_TEAM_MEMBERS") as string}
                        required={true}
                    />
                </div>
                <div className="newforma-scrollableProjectTeamList">{this.renderTeamMemberRows()}</div>
                <div id="footer" key="footer" className="newforma-footer">
                    <DefaultButton
                        className="newforma-footerButton"
                        text={this.primaryButtonLabel()}
                        primaryDisabled={this.submitDisabled()}
                        disabled={
                            !this.selectedTeamMembers().length ||
                            this.state.isAddingTeamMembers ||
                            this.props.officeWrapper.isReadMode()
                        }
                        aria-roledescription="split button"
                        menuProps={{
                            items: [
                                {
                                    key: TeamMemberAddLocation.to,
                                    text: `${this.props.translate("PROJECT_TEAM.ADD_TO")}${this.getSelectedLabel()}`,
                                    onClick: this.onAddToOptionChanged.bind(this),
                                    disabled: this.props.officeWrapper.isReadMode(),
                                },
                                {
                                    key: TeamMemberAddLocation.cc,
                                    text: `${this.props.translate("PROJECT_TEAM.ADD_CC")}${this.getSelectedLabel()}`,
                                    onClick: this.onAddToOptionChanged.bind(this),
                                    disabled: this.props.officeWrapper.isReadMode(),
                                },
                            ],
                        }}
                        onClick={this.onSubmit.bind(this, TeamMemberAddLocation.to)}
                    />
                </div>
            </>
        );
    }
}

export default withLocalize(InsertTeamMemberComponent);

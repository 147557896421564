import { IndexedDbHelper } from "../../helpers/IndexedDbHelper";
import { ProjectsCacheKeys } from "../../models/StorageKeys";
import { Logger } from "../Logger";

export class InvalidateCacheService {
    constructor(
        private readonly indexedDbHelper: IndexedDbHelper,
        private readonly storage: Storage,
        private readonly logger: Logger,
        private readonly maxCacheLifetimeMs: number
    ) {}

    async invalidateCache(...cacheNames: ProjectsCacheKeys[]): Promise<void> {
        this.logger.info("Invalidating cache", cacheNames);
        try {
            const db = await this.indexedDbHelper.openDb();
            const allIndexedDbKeys = await this.indexedDbHelper.getAllKeys(db);
            if (allIndexedDbKeys) {
                const filteredKeys = allIndexedDbKeys.filter((x) => cacheNames.find((k) => x.toString().startsWith(k)));
                for (const key of filteredKeys) {
                    await this.indexedDbHelper.removeData(db, key);
                }
            }
        } catch (error) {
            this.logger.error("Cache invalidation error", error);
        }
    }
    async invalidateAllCache(): Promise<void> {
        this.logger.info("Invalidating all cache");
        const allKeys = Object.values(ProjectsCacheKeys);
        return this.invalidateCache(...allKeys);
    }

    async clearAllLeftovers(): Promise<void> {
        try {
            const db = await this.indexedDbHelper.openDb();
            const allIndexedDbKeys = await this.indexedDbHelper.getAllKeys(db);
            Object.values(ProjectsCacheKeys).forEach(async (cacheName) => {
                this.clearLeftoversLocalStorage(cacheName);
                if (allIndexedDbKeys) {
                    await this.clearLeftoversIndexedDb(cacheName, allIndexedDbKeys, db);
                }
            });
        } catch (error) {
            this.logger.error("Clear all leftovers error", error);
        }
    }
    private async clearLeftoversIndexedDb(
        cacheName: ProjectsCacheKeys,
        allIndexedDbKeys: IDBValidKey[],
        db: IDBDatabase
    ): Promise<void> {
        const filteredKeys = allIndexedDbKeys.filter((x) => x.toString().startsWith(cacheName));
        for (const key of filteredKeys) {
            const cachedValues = await this.indexedDbHelper.getData(db, key);
            if (!this.isValid(cachedValues.lastUpdatedTime)) {
                this.logger.info("clearLeftoversIndexedDb", key);
                await this.indexedDbHelper.removeData(db, key);
            }
        }
    }

    private clearLeftoversLocalStorage(cacheName: ProjectsCacheKeys): void {
        const previousStoredTime = this.storage.getItem(`${cacheName}LastUpdatedTime`);
        if (this.isValid(previousStoredTime)) {
            return;
        }
        this.logger.info("clearLeftoversLocalStorage", cacheName);
        this.storage.removeItem(cacheName);
        this.storage.removeItem(`${cacheName}LastUpdatedTime`);
    }
    private isValid(previousStoredTime: string | null): boolean {
        if (previousStoredTime) {
            const cacheLifetimeInHours = this.calculateLastStoredTime(JSON.parse(previousStoredTime));
            return cacheLifetimeInHours < this.maxCacheLifetimeMs;
        }
        return false;
    }
    private calculateLastStoredTime(storedTime: number) {
        const currentTime = new Date().getTime();
        return currentTime - storedTime;
    }
}

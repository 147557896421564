import { Paging } from "../../models/ProjectKeywordsResponse";
import { ProjectsResponse } from "../../models/ProjectsResponse";
import { Logger } from "../Logger";
import { MessageTypes, Messenger } from "../Messenger";
import { OfficeRoamingSettings } from "../OfficeRoamingSettings";
import { IProjectsService } from "./IProjectsService";

export class RefreshProjectsBackgroundTask {
    constructor(
        private readonly projectService: IProjectsService,
        private readonly logger: Logger,
        private readonly cacheRefreshIntervalMs: number,
        private readonly messenger: Messenger,
        private readonly officeRoamingSettings: OfficeRoamingSettings
    ) {}

    init(): Promise<void> {
        setInterval(RefreshProjectsBackgroundTask.refreshCache, this.cacheRefreshIntervalMs, this);
        return RefreshProjectsBackgroundTask.refreshCache(this);
    }
    private static async refreshCache(task: RefreshProjectsBackgroundTask): Promise<void> {
        const projectsResponse = await task.refreshMyProjectsCache();
        const allProjectResponses: ProjectsResponse[] = task.officeRoamingSettings.getShowAllGlobalProjects()
            ? await task.refreshGlobalProjectsCache(projectsResponse)
            : [projectsResponse];

        task.messenger.dispatch(MessageTypes.CacheRefreshed, {
            projects: allProjectResponses.map((x) => x.projects).flat(),
            myProjects: projectsResponse.projects,
        });
    }

    private async refreshMyProjectsCache(): Promise<ProjectsResponse> {
        this.logger.info("RefreshCacheService - loading my projects.");
        const projectsResponse = await this.projectService.getMyProjects();
        this.logger.info("RefreshCacheService - my projects loaded successfully.");
        return projectsResponse;
    }
    private async refreshGlobalProjectsCache(myProjects: ProjectsResponse): Promise<ProjectsResponse[]> {
        let updatedPaging: Paging | undefined = myProjects.paging || { offsetToken: "globalProject" };
        const allProjects = [myProjects];
        do {
            this.logger.info(`RefreshCacheService - loading global projects. OffsetToken ${updatedPaging.offsetToken}`);
            const projectsResponse: ProjectsResponse = await this.projectService.getGlobalProjects(updatedPaging);
            updatedPaging = projectsResponse.paging;
            this.logger.info(
                `RefreshCacheService - global projects loaded successfully. OffsetToken ${updatedPaging?.offsetToken}`
            );
            allProjects.push(projectsResponse);
        } while (updatedPaging?.offsetToken);
        return allProjects;
    }
}

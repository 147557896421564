import "./RefreshProjectComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import LabelComponent from "../label/LabelComponent";
import LinkComponent from "../linkComponent/LinkComponent";

export interface RefreshProjectComponentProps extends LocalizeContextProps {
    labelText: string;
    onRefresh: () => void;
    required?: boolean;
    className?: string;
}

class RefreshProjectComponent extends React.Component<RefreshProjectComponentProps, any> {
    constructor(props: RefreshProjectComponentProps, context: any) {
        super(props, context);
    }

    private onRefresh(): void {
        this.props.onRefresh();
    }

    render(): JSX.Element {
        return (
            <div className={`newforma-inputLabelContainer ${this.props.className || ""}`}>
                <LabelComponent text={this.props.labelText} required={this.props.required} />
                <LinkComponent
                    text={this.props.translate("SHARED.SUGGESTED_PROJECTS.REFRESH_PROJECTS") as string}
                    icon="Refresh"
                    onClick={this.onRefresh.bind(this)}
                />
            </div>
        );
    }
}

export default withLocalize(RefreshProjectComponent);

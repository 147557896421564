import { AwsCredentials } from "../models/AwsCredentials";
import { AwsSigner, Request } from "aws-sign-web";
import { Logger } from "./Logger";

export class AWSSignatureVersion4Provider {
    constructor(private logger: Logger) {}

    getSignatureHeaders(httpRequestOptions: Request, credentials: AwsCredentials): Request {
        try {
            // "X-Amz-Security-Token" is not overwritten when signing, so we need to clear it when it is already set.
            if (httpRequestOptions.headers && httpRequestOptions.headers["X-Amz-Security-Token"]) {
                httpRequestOptions.headers["X-Amz-Security-Token"] = "";
            }
            const signer = new AwsSigner({
                region: credentials.signatureScope,
                service: "execute-api",
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
            });

            const signedHeaders = signer.sign(httpRequestOptions);
            return { ...httpRequestOptions, headers: { ...httpRequestOptions.headers, ...signedHeaders } };
        } catch (err) {
            this.logger.error("AWSSignatureVersion4Provider.getSignatureHeaders(): ERROR!", err);
            throw err;
        }
    }
}

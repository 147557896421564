import { NewformaApiClient } from "./NewformaApiClient";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { Request } from "aws-sign-web";
import { Logger } from "../Logger";
import { AttachmentDetailsMetadata } from "../../models/AttachmentDetailsMetadata";
import { FileWithId } from "../../models/shared/FileWithId";
import { AnalyticsCategoryType, AnalyticsActionType, AnalyticsManager } from "../AnalyticsManager";
import AttachmentDetails = Office.AttachmentDetails;
import { OutlookApiService } from "../OutlookApiService";
import { LogFileTransferParams } from "../../models/workflow/fileTransfer/LogFileTransferParams";
import { LogFileTransferResponse } from "../../models/workflow/fileTransfer/LogFileTransferResponse";
import { v4 } from "uuid";
import { FileUploadApiService } from "./FileUploadApiService";
import { ApiRequestErrorLevel, ApiRequestErrorWithMessage } from "../../models/ApiRequestErrorWithMessage";
import { mapAttachmentsMetadataToAttachment } from "../../helpers/SendAndFile/AttachmentHelpers";

export class FileTransferApiService {
    constructor(
        private logger: Logger,
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private analyticsManager: AnalyticsManager,
        private outlookApiService: OutlookApiService,
        private fileUploadApiService: FileUploadApiService
    ) {}

    async abortFileTransfer() {
        this.requestWrapper.abortCurrentRequest();
    }

    async fileAsFileTransfer(
        projectNrn: string,
        params: LogFileTransferParams,
        messageNrn: string,
        attachments: (AttachmentDetails | FileWithId)[],
        fileUploadCallback: (isInProgress: boolean, failedIds: string[]) => void,
        fileUploadProgress: () => any,
        isLastModifiedDateSupported: boolean
    ): Promise<void> {
        this.analyticsManager.recordEvent(AnalyticsCategoryType.UserActions, AnalyticsActionType.FileTransfer);

        let attachmentsDetails: AttachmentDetailsMetadata[] = [];
        if (attachments.length > 0) {
            attachmentsDetails = await this.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                isLastModifiedDateSupported
            );
            params.attachments = mapAttachmentsMetadataToAttachment(attachmentsDetails, isLastModifiedDateSupported);
        }
        let response: LogFileTransferResponse;
        try {
            response = await this.logFileTransfer(projectNrn, params);
        } catch (error) {
            this.logger.error("FileTransferApiService. There was an error creating a file transfer", error);

            if ((error as any).status === 409) {
                throw new ApiRequestErrorWithMessage(
                    (error as any).message,
                    ApiRequestErrorLevel.ERROR,
                    "409 error",
                    (error as any).status
                );
            }

            throw new ApiRequestErrorWithMessage(
                (error as any).responseJSON.title,
                ApiRequestErrorLevel.ERROR,
                "APP.FILE_TRANSFER.ERROR",
                (error as any).status
            );
        }
        if (attachments.length > 0) {
            const batchId = v4();
            const result = await this.fileUploadApiService.uploadWorkflowAttachments(
                attachmentsDetails,
                response.uploadFolderNrn,
                fileUploadCallback,
                batchId,
                fileUploadProgress
            );
            fileUploadCallback(false, result.failedFileIds);
            if (result.failedFileIds.length > 0) {
                throw new ApiRequestErrorWithMessage(
                    "one or more files failed to upload",
                    ApiRequestErrorLevel.ERROR,
                    "APP.FILE_TRANSFER.FAILED_ATTACHMENTS_UPLOAD",
                    400
                );
            }
        }
    }

    private async logFileTransfer(projectNrn: string, params: LogFileTransferParams): Promise<LogFileTransferResponse> {
        const options: Request = {
            url: `${this.newformaApiClient.getHostNameWithProtocol()}/v1/projects/${projectNrn}/transfer`,
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };
        this.logger.info("Logging a File Transfer");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, signedOptions.body)
        );
    }
}

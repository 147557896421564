import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import * as React from "react";
import {
    DetailsList,
    IColumn,
    IGroup,
    Icon,
    DetailsRow,
    IDetailsRowProps,
    IGroupHeaderProps,
} from "office-ui-fabric-react";
import "./AttachmentListComponent.less";
import { getFileTypeIconProps } from "@uifabric/file-type-icons";
import { AttachmentItem } from "../../../../models/shared/AttachmentList";
import { Logger } from "../../../../services/Logger";
import { pathSeparator } from "../AttachmentsComponent";
import {
    AttachmentVisual,
    BackButton,
    FileAttachmentVisual,
    FolderAttachmentVisual,
    FoldersHierarchy,
} from "./AttachmentListComponentTypes";

export interface AttachmentListComponentProps extends LocalizeContextProps {
    disabled: boolean;
    attachmentsItems: AttachmentItem[];
    onItemRemove: (attachments: AttachmentItem[]) => void;
    onClear: () => void;
    logger: Logger;
    onMoveIntoSubFolder: () => void;
    onReturnToRoot: () => void;
}

export interface AttachmentListComponentState {
    isSelected: boolean;
    items: AttachmentVisual[];
    groups: IGroup[];
    isCollapsed: Boolean;
    backButton?: BackButton;
    shouldShowTotalAndClearAll?: Boolean;
}

class AttachmentListComponent extends React.Component<AttachmentListComponentProps, AttachmentListComponentState> {
    private readonly rootFolderKey: string = "bPddtp9TtGMcububNeUDk8g7fTZIPrjY.02cd99e9-8394-4406-b988-07b074365975";
    private readonly columns: IColumn[] = [
        {
            key: "remove",
            name: this.props.translate("SHARED.ATTACHMENTS.COLUMN_LABEL.REMOVE_LABEL") as string,
            minWidth: 5,
            maxWidth: 10,
            isCollapsible: false,
            onRender: (item: AttachmentVisual) => (
                <div className="row" data-selection-invoke="true" onClick={() => this.onItemRemove(item)}>
                    <Icon iconName="Cancel" className="newforma-attachmentCheck" />
                </div>
            ),
        },
        {
            key: "images",
            name: this.props.translate("SHARED.ATTACHMENTS.COLUMN_LABEL.IMAGE_LABEL") as string,
            minWidth: 5,
            maxWidth: 10,
            isCollapsible: false,
            onRender: (item: AttachmentVisual) => <div className="row">{item.image}</div>,
        },
        {
            key: "attachmentNames",
            name: this.props.translate("SHARED.ATTACHMENTS.COLUMN_LABEL.ATTACHMENTS_LABEL") as string,
            minWidth: 100,
            isResizable: true,
            isCollapsible: false,
            onRender: (item: AttachmentVisual) => {
                if (item.type === "file") {
                    return <div className="row newforma-attachmentNames">{item.name}</div>;
                }
                return (
                    <div
                        className="row newforma-attachmentNames newforma-folderNames"
                        onClick={() => {
                            this.onFolderClick(item);
                        }}
                    >
                        {item.name}
                    </div>
                );
            },
        },
    ];
    constructor(props: AttachmentListComponentProps, context: AttachmentListComponentState) {
        super(props, context);

        this.state = {
            isSelected: true,
            items: [],
            groups: [],
            isCollapsed: false,
            shouldShowTotalAndClearAll: true,
        };
    }

    componentDidMount(): void {
        this.createNewItems(this.props.attachmentsItems);
    }

    componentDidUpdate(prevProps: Readonly<AttachmentListComponentProps>): void {
        const currentAttachments = this.props.attachmentsItems;

        if (currentAttachments.length !== prevProps.attachmentsItems.length) {
            const backButton = this.getBackButton();
            if (backButton) {
                this.onFolderClick({
                    key: backButton.folderKey,
                    name: backButton.folderName,
                });
            } else {
                this.createNewItems(currentAttachments);
            }
        }
    }

    private getBackButton(): BackButton | undefined {
        const backButtonFound = this.state.backButton;
        if (!backButtonFound) {
            return;
        }
        if (
            this.props.attachmentsItems.find((attachment) =>
                this.equalFolders(attachment.id, backButtonFound.folderKey)
            )
        ) {
            return this.state.backButton;
        }
        return this.findBackButton(backButtonFound.folderKey);
    }

    private findBackButton(folderKey?: string): BackButton | undefined {
        if (!folderKey) {
            return;
        }
        const hierarchy = this.prepareFoldersStructure(this.props.attachmentsItems);
        const hierarchyItem = this.findInHierarchy(hierarchy, folderKey);
        if (hierarchyItem?.parent) {
            return this.createBackButton(hierarchyItem.name, hierarchyItem.path, hierarchyItem.parent?.path);
        }
        const lastPathSeparatorIndex = folderKey.lastIndexOf(pathSeparator);
        if (lastPathSeparatorIndex === -1) {
            return;
        }
        const parentFolderPath = folderKey.slice(0, lastPathSeparatorIndex);
        return this.findBackButton(parentFolderPath);
    }

    private getIconExtension(attachmentFullName: string): string {
        const extensionIndex = attachmentFullName.lastIndexOf(".");
        return extensionIndex > -1 ? attachmentFullName.substring(extensionIndex + 1) : "";
    }

    private createNewItems(totalAttachments: AttachmentItem[]): void {
        const hierarchy = this.prepareFoldersStructure(totalAttachments);
        this.setAttachmentHierarchy(hierarchy.children);
    }
    private setAttachmentHierarchy(
        hierarchy: FoldersHierarchy[],
        folder?: { name: string; key: string; parentKey: string }
    ) {
        const folderAndFilesHierarchy = this.createVisual(hierarchy);
        if (folder) {
            this.setState({
                backButton: this.createBackButton(folder.name, folder.key, folder.parentKey),
            });
        } else {
            this.props.onReturnToRoot();
            this.setState({
                shouldShowTotalAndClearAll: true,
                backButton: undefined,
            });
        }
        const visualAttachments: AttachmentVisual[] = folderAndFilesHierarchy.sort((a, b) =>
            this.compareFileAndFolderNames(a, b)
        );
        const groups = [
            {
                key: "attachmentGroup",
                name: "Attachments",
                startIndex: 0,
                count: visualAttachments.length,
            },
        ];
        this.setState({
            items: visualAttachments,
            groups: groups,
        });
    }

    private compareFileAndFolderNames(left: AttachmentVisual, right: AttachmentVisual): number {
        const fileNameA = left.name.toLowerCase();
        const fileNameB = right.name.toLowerCase();
        if (left.type === right.type) {
            return fileNameA < fileNameB ? -1 : 1;
        }
        return left.type === "folder" ? -1 : 1;
    }

    private createVisual(hierarchy: FoldersHierarchy[]): (FileAttachmentVisual | FolderAttachmentVisual)[] {
        return hierarchy.map((item) => {
            return item.children.length === 0
                ? {
                      image: (
                          <Icon
                              {...getFileTypeIconProps({
                                  extension: this.getIconExtension(item.name),
                                  size: 16,
                                  imageFileType: "png",
                              })}
                          />
                      ),
                      name: item.name,
                      key: item.path,
                      type: "file",
                  }
                : {
                      image: <Icon iconName="folder16_svg" />,
                      name: item.name,
                      key: item.path,
                      type: "folder",
                  };
        });
    }
    private prepareFoldersStructure(newItems: AttachmentItem[]): FoldersHierarchy {
        const foldersOnlyAttachments = newItems.filter((item) => item.id.includes(pathSeparator));

        const hierarchy: FoldersHierarchy = { children: [], name: "", path: "" };
        foldersOnlyAttachments.forEach((item) => {
            item.id
                .split(pathSeparator)
                .filter((fileOrFolderName) => fileOrFolderName)
                .reduce((h, fileOrFolderName) => {
                    const existingObj = h.children.find((i) => i.name === fileOrFolderName);
                    if (existingObj) {
                        return existingObj;
                    }
                    const newObj = {
                        children: [],
                        name: fileOrFolderName,
                        path: `${h.path}${pathSeparator}${fileOrFolderName}`,
                    };
                    h.children.push(newObj);
                    return newObj;
                }, hierarchy);
        });
        const filesOnlyAttachments = newItems
            .filter((item) => !item.id.includes(pathSeparator))
            .map((item) => ({ children: [], path: item.id, ...item }));
        return {
            name: "root",
            path: this.rootFolderKey,
            children: hierarchy.children.concat(filesOnlyAttachments),
        };
    }
    private onFolderClick(item: { name: string; key: string }): void {
        this.props.onMoveIntoSubFolder();
        this.setState({ shouldShowTotalAndClearAll: false });
        const hierarchy = this.prepareFoldersStructure(this.props.attachmentsItems);
        const hierarchyItem = this.findInHierarchy(hierarchy, item.key);
        if (!hierarchyItem) {
            this.props.logger.warning(
                `[onFolderClick] Something went wrong because can't find the attachment ${item.key} in the hierarchy.`,
                hierarchyItem
            );
            return;
        }
        this.setAttachmentHierarchy(hierarchyItem.children, {
            key: item.key,
            name: item.name,
            parentKey: hierarchyItem.parent?.path ?? this.rootFolderKey,
        });
    }

    private createBackButton(folderName: string, folderKey: string, parentKey: string): BackButton {
        return {
            folderName,
            folderKey,
            parentKey,
        };
    }
    private findInHierarchy(
        hierarchy: FoldersHierarchy,
        key: string
    ): (FoldersHierarchy & { parent?: FoldersHierarchy }) | undefined {
        if (hierarchy.path === key) {
            return hierarchy;
        }
        if (hierarchy.children.length === 0) {
            return undefined;
        }
        const foundItem = hierarchy.children.find((item) => item.path === key);
        if (foundItem) {
            return {
                ...foundItem,
                parent: hierarchy,
            };
        }
        for (const item of hierarchy.children) {
            const foundItemInChildren = this.findInHierarchy(item, key);
            if (foundItemInChildren) {
                return foundItemInChildren;
            }
        }
    }
    private onBackClick(back: BackButton): void {
        const hierarchy = this.prepareFoldersStructure(this.props.attachmentsItems);

        const foundHierarchyItem = this.findInHierarchy(hierarchy, back.parentKey);
        if (!foundHierarchyItem) {
            this.props.logger.warning(
                `[onBackClick] Something went wrong because can't find the attachment ${back.parentKey} in the hierarchy.`,
                foundHierarchyItem
            );
            return;
        }
        if (!foundHierarchyItem.parent) {
            this.setAttachmentHierarchy(hierarchy.children);
            return;
        }
        this.setAttachmentHierarchy(foundHierarchyItem.children, {
            key: foundHierarchyItem.path,
            name: foundHierarchyItem.name,
            parentKey: foundHierarchyItem.parent.path,
        });
    }

    private onRenderRow(props?: IDetailsRowProps): JSX.Element | null {
        return props ? <DetailsRow {...props} className="detailsRow" /> : null;
    }

    private onItemRemove(attachment: AttachmentVisual): void {
        switch (attachment.type) {
            case "file":
                this.props.onItemRemove(
                    this.props.attachmentsItems.filter((att) => this.equalFiles(att.id, attachment.key))
                );
                break;
            case "folder":
                this.props.onItemRemove(
                    this.props.attachmentsItems.filter((att) => this.equalFolders(att.id, attachment.key))
                );
                break;
        }
    }

    private equalFiles(left: string, right: string): boolean {
        const normalizedLeft = this.normalizeAttachmentId(left);
        const normalizedRight = this.normalizeAttachmentId(right);
        return normalizedLeft === normalizedRight;
    }

    private normalizeAttachmentId(id: string): string {
        return id.startsWith(pathSeparator) ? id.slice(1) : id;
    }

    private equalFolders(left: string, right: string): boolean {
        const normalizedLeft = this.normalizeAttachmentId(left);
        const normalizedRight = this.normalizeAttachmentId(right);
        return normalizedLeft.startsWith(normalizedRight + pathSeparator);
    }

    private onClear(): void {
        this.props.onClear();
    }

    private onRenderGroupHeader(props?: IGroupHeaderProps): JSX.Element | null {
        if (props) {
            const toggleCollapse = (): void => {
                if (props.onToggleCollapse && props.group) {
                    props.onToggleCollapse(props.group);
                    this.setState({
                        isCollapsed: props.group?.isCollapsed ? true : false,
                    });
                }
            };
            return (
                <div>
                    <div className="newforma-inputLabelContainer newforma-attachmentsLabel row">
                        {this.state.isCollapsed ? (
                            <Icon
                                iconName="ChevronRight"
                                className="newforma-attachmentsChevron"
                                onClick={toggleCollapse.bind(this)}
                            />
                        ) : (
                            <Icon
                                iconName="ChevronDown"
                                className="newforma-attachmentsChevron"
                                onClick={toggleCollapse.bind(this)}
                            />
                        )}
                        <span className="newforma-attachmentsText">
                            {this.props.translate("SHARED.ATTACHMENTS.ATTACHMENTS_LABEL") as string}{" "}
                            {this.props.attachmentsItems.length > 0 && this.state.shouldShowTotalAndClearAll ? (
                                <span className="newforma-attachmentsCount">{` (${this.props.attachmentsItems.length})`}</span>
                            ) : null}
                        </span>
                        {this.state.shouldShowTotalAndClearAll ? (
                            <div
                                className="newforma-inputLinkContainer"
                                onClick={() => {
                                    this.props.disabled ? null : this.onClear();
                                }}
                            >
                                <Icon iconName="EraseTool" className="newforma-attachmentsErase" />
                                <span className="newforma-inputLinkButton">
                                    &nbsp;{this.props.translate("SHARED.ATTACHMENTS.CLEAR") as string}
                                </span>
                            </div>
                        ) : null}
                    </div>
                    {this.state.backButton && !this.state.isCollapsed ? (
                        <div
                            className="row detailListFolderHeader detailListFolderHeaderSelected"
                            onClick={() => {
                                this.onBackClick(this.state.backButton as BackButton);
                            }}
                        >
                            <button>
                                <Icon iconName="Back" /> Back
                            </button>
                            <span>{this.state.backButton.folderName}</span>
                        </div>
                    ) : null}
                </div>
            );
        }
        return null;
    }

    render(): JSX.Element {
        return (
            <div
                className={`newforma-attachmentComponent ${
                    this.state.isSelected ? "newforma-attachmentItemSelected" : ""
                }`}
                data-is-scrollable={true}
            >
                <DetailsList
                    items={this.state.items}
                    columns={this.columns}
                    groups={this.state.groups}
                    setKey={`${this.state.items.length}`}
                    className="detailsList newforma-flexRow"
                    ariaLabelForSelectionColumn="Toggle selection"
                    isHeaderVisible={false}
                    checkboxVisibility={2}
                    onRenderRow={this.onRenderRow.bind(this)}
                    groupProps={{
                        onRenderHeader: this.onRenderGroupHeader.bind(this),
                    }}
                    disableSelectionZone={this.props.disabled}
                    onShouldVirtualize={() => false}
                />
            </div>
        );
    }
}

export default withLocalize(AttachmentListComponent);

import { Logger } from "../services/Logger";

export class IndexedDbHelper {
    constructor(
        private readonly dbName: string,
        private readonly dbVersion: number,
        private readonly defaultDbStoreName: string,
        private readonly logger: Logger
    ) {}

    isSupported(): boolean {
        return "indexedDB" in window;
    }
    openDb(): Promise<IDBDatabase> {
        const request = indexedDB.open(this.dbName, this.dbVersion);
        return new Promise((resolve, reject) => {
            request.onsuccess = (event) => {
                const db = (event.target as any)?.result;
                if (!db) {
                    reject("Can't open IndexedDB.");
                    return;
                }
                resolve(db);
            };
            request.onupgradeneeded = (event) => {
                const db = (event.target as any)?.result;
                if (!db) {
                    reject("Can't open IndexedDB when upgrading.");
                    return;
                }
                if (event.oldVersion === 0) {
                    db.createObjectStore(this.defaultDbStoreName, { keyPath: "cacheName" });
                }
                resolve(db);
            };
            request.onerror = (event) => {
                console.warn("This browser doesn't support IndexedDB.", event);
                this.logger.warning("This browser doesn't support IndexedDB.", event);
                reject("Error opening IndexedDB.");
            };
        });
    }

    getData(
        db: IDBDatabase | IDBObjectStore,
        query: IDBValidKey | IDBKeyRange,
        dbStoreName?: string
    ): Promise<any | undefined> {
        const readonlyStore = db instanceof IDBObjectStore ? db : this.getTransactionStore(db, "readonly", dbStoreName);
        const request = readonlyStore.get(query);
        return new Promise((resolve, reject) => {
            request.onsuccess = (event) => {
                const result = (event.target as any)?.result;
                resolve(result);
            };
            request.onerror = (event) => {
                console.warn("This browser doesn't support IndexedDB.", event);
                this.logger.warning("This browser doesn't support IndexedDB.", event);
                reject("Error writing data to IndexedDB.");
            };
        });
    }

    getAllKeys(
        db: IDBDatabase,
        query?: IDBValidKey | IDBKeyRange,
        dbStoreName?: string
    ): Promise<IDBValidKey[] | undefined> {
        const readonlyStore = this.getTransactionStore(db, "readonly", dbStoreName);
        const request = readonlyStore.getAllKeys(query);
        return new Promise((resolve, reject) => {
            request.onsuccess = (event) => {
                const result = (event.target as any)?.result;
                resolve(result);
            };
            request.onerror = (event) => {
                console.warn("This browser doesn't support IndexedDB.", event);
                this.logger.warning("This browser doesn't support IndexedDB.", event);
                reject("Error writing data to IndexedDB.");
            };
        });
    }

    getTransactionStore(db: IDBDatabase, mode?: IDBTransactionMode, dbStoreName?: string): IDBObjectStore {
        const transaction = db.transaction(dbStoreName ?? this.defaultDbStoreName, mode);
        return transaction.objectStore(dbStoreName ?? this.defaultDbStoreName);
    }

    putData(db: IDBDatabase | IDBObjectStore, value: any, dbStoreName?: string): Promise<void> {
        const store = db instanceof IDBObjectStore ? db : this.getTransactionStore(db, "readwrite", dbStoreName);
        const request = store.put(value);
        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                resolve();
            };
            request.onerror = (event) => {
                console.warn("This browser doesn't support IndexedDB.", event);
                this.logger.warning("This browser doesn't support IndexedDB.", event);
                reject("Error writing data to IndexedDB.");
            };
        });
    }

    removeData(
        db: IDBDatabase | IDBObjectStore,
        query: IDBValidKey | IDBKeyRange,
        dbStoreName?: string
    ): Promise<void> {
        const store = db instanceof IDBObjectStore ? db : this.getTransactionStore(db, "readwrite", dbStoreName);
        const request = store.delete(query);
        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                resolve();
            };
            request.onerror = (event) => {
                console.warn("This browser doesn't support IndexedDB.", event);
                this.logger.warning("This browser doesn't support IndexedDB.", event);
                reject("Error writing data to IndexedDB.");
            };
        });
    }
}

import * as React from "react";
import "./EulaComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../services/AnalyticsManager";
import {
    DefaultButton,
    Panel,
    PanelType,
    PrimaryButton,
    Checkbox,
    Spinner,
    SpinnerSize,
    Icon,
} from "office-ui-fabric-react";
import MessageDialogComponent from "../shared/MessageDialogComponent";
import { Logger } from "../../services/Logger";
import { EulaApiService } from "../../services/NewformaApi/EulaApiService";
import { Eula, GetEulaResponse } from "../../models/EulaModels";
import InnerHtmlComponent from "../shared/innerHtml/InnerHtmlComponent";

export interface EulaComponentProps extends LocalizeContextProps {
    analyticsManager: AnalyticsManager;
    logger: Logger;
    isEulaPreviouslyAccepted: boolean;
    eulaService: EulaApiService;
    onEulaResponse: (response: Eula | undefined) => void;
    theme: string;
}

export interface EulaComponentState {
    showPanel: boolean;
    isReadBoxChecked: boolean;
    isLoadingEula: boolean;
    showDeclineWarning: boolean;
    eulaNrn: string | null;
    eulaContents: string;
    eulaVersion: string;
    isErrorLoadingEula: boolean;
}

class EulaComponent extends React.Component<EulaComponentProps, EulaComponentState> {
    constructor(props: EulaComponentProps, context: EulaComponentState) {
        super(props, context);

        this.state = {
            showPanel: true,
            isReadBoxChecked: false,
            isLoadingEula: true,
            showDeclineWarning: false,
            eulaNrn: null,
            eulaContents: "",
            eulaVersion: "",
            isErrorLoadingEula: false,
        };
    }

    async componentDidMount() {
        this.props.logger.info("EulaComponent mounted");
        await this.getEula();
    }

    private async getEula(): Promise<void> {
        this.setState({ isLoadingEula: true });
        try {
            const eula: GetEulaResponse = await this.props.eulaService.getEula();
            this.setState({
                eulaVersion: eula.version,
                eulaContents: eula.content,
                eulaNrn: eula.nrn,
                isLoadingEula: false,
                isErrorLoadingEula: false,
            });
        } catch {
            this.setState({ isErrorLoadingEula: true, isLoadingEula: false });
        }
    }

    private onConfirm(): void {
        this.props.analyticsManager.recordEvent(AnalyticsCategoryType.UserActions, AnalyticsActionType.EulaAccept);
        this.props.onEulaResponse({ isAccepted: true, nrn: this.state.eulaNrn ?? "" });
        this.setState({ showPanel: false, showDeclineWarning: false });
        window.localStorage.setItem("inProgressToLogin", true.toString());
    }

    private onDecline(): void {
        this.setState({ showDeclineWarning: true });
    }

    private onClose(): void {
        this.props.onEulaResponse(undefined);
        this.setState({ showPanel: false });
    }

    private onReadBoxChanged(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        if (checked === undefined || checked === null) {
            return;
        }
        this.setState({ isReadBoxChecked: checked });
    }

    private onDeclineWarningConfirm(): void {
        this.props.analyticsManager.recordEvent(AnalyticsCategoryType.UserActions, AnalyticsActionType.EulaDecline);
        this.props.onEulaResponse({ isAccepted: false, nrn: this.state.eulaNrn ?? "" });
        this.setState({ showPanel: false, showDeclineWarning: false });
    }

    private onDeclineWarningDismiss(): void {
        this.setState({ showDeclineWarning: false });
    }

    onRenderFooterContent(): JSX.Element {
        return (
            <div className="newforma-settingsFooter">
                {this.state.isErrorLoadingEula ? (
                    <PrimaryButton className="newforma-eulaClose" onClick={this.onClose.bind(this)}>
                        {this.props.translate("EULA.CLOSE") as string}
                    </PrimaryButton>
                ) : (
                    <>
                        <div>
                            <Checkbox
                                className="newforma-eulaReadCheckbox"
                                label={this.props.translate("EULA.READ_CHECKBOX") as string}
                                checked={this.state.isReadBoxChecked}
                                onChange={this.onReadBoxChanged.bind(this)}
                            />
                        </div>
                        <div className="newforma-eulaFooterButtons">
                            <DefaultButton className="newforma-eulaDecline" onClick={this.onDecline.bind(this)}>
                                {this.props.translate("EULA.REJECT") as string}
                            </DefaultButton>
                            <PrimaryButton
                                className="newforma-eulaConfirm"
                                onClick={this.onConfirm.bind(this)}
                                disabled={!this.state.isReadBoxChecked || this.state.isLoadingEula}
                            >
                                {this.props.translate("EULA.ACCEPT") as string}
                            </PrimaryButton>
                        </div>
                    </>
                )}
            </div>
        );
    }

    private getEulaContents(): JSX.Element {
        return (
            <>
                {this.state.isErrorLoadingEula ? (
                    <div className="newforma-eulaError">
                        <div className="newforma-eulaErrorIcon">
                            <Icon iconName="Error" />
                        </div>
                        <div className="newforma-eulaErrorText">{this.props.translate("EULA.ERROR_LOADING_EULA")}</div>
                    </div>
                ) : (
                    <div className="newforma-eulaText">
                        <p>{`${this.props.translate("EULA.VERSION") as string} ${this.state.eulaVersion}`}</p>
                        <InnerHtmlComponent htmlToRender={this.state.eulaContents} />
                    </div>
                )}
            </>
        );
    }

    render(): JSX.Element {
        const headerText = this.props.isEulaPreviouslyAccepted
            ? (this.props.translate("EULA.HEADER_UPDATED") as string)
            : (this.props.translate("EULA.HEADER") as string);

        return (
            <>
                <Panel
                    isOpen={this.state.showPanel}
                    type={PanelType.smallFluid}
                    headerText={headerText}
                    onRenderFooterContent={this.onRenderFooterContent.bind(this)}
                    className="newforma-eulaPanel"
                    hasCloseButton={false}
                    headerClassName="newforma-eulaHeader"
                    data-theme={this.props.theme}
                >
                    <div className="newforma-eulaContents">
                        {this.state.isLoadingEula ? <Spinner size={SpinnerSize.large} /> : this.getEulaContents()}
                    </div>
                </Panel>
                <MessageDialogComponent
                    title={this.props.translate("EULA.DECLINE_WARNING.HEADER") as string}
                    subText={this.props.translate("EULA.DECLINE_WARNING.BODY") as string}
                    hidden={!this.state.showDeclineWarning}
                    onDismiss={this.onDeclineWarningDismiss.bind(this)}
                    onConfirmButtonClicked={this.onDeclineWarningConfirm.bind(this)}
                />
            </>
        );
    }
}

export default withLocalize(EulaComponent);

import { HttpRequestWrapper } from "./HttpRequestWrapper";
import { MsGraphTokenService } from "./MsGraphTokenService";

export class GraphRequestWrapper {
    constructor(private httpRequestWrapper: HttpRequestWrapper, private msGraphTokenService: MsGraphTokenService) {}

    async get(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: any,
        options?: any
    ) {
        try {
            const accessToken = await this.msGraphTokenService.getGraphToken();
            const headersWithAuthorization = {
                ...headers,
                Authorization: `Bearer ${accessToken}`,
            };
            return await this.httpRequestWrapper.get(url, dataType, headersWithAuthorization, data, options);
        } catch (error) {
            if ((error as any).status && (error as any).status === 401) {
                const newToken = await this.msGraphTokenService.getNewGraphTokenFromAPI();
                const headersWithAuthorization = {
                    ...headers,
                    Authorization: `Bearer ${newToken}`,
                };
                return this.httpRequestWrapper.get(url, dataType, headersWithAuthorization, data, options);
            }
            throw error;
        }
    }

    async post(
        url: string,
        dataType?: string,
        headers?: JQuery.PlainObject<string | null | undefined>,
        data?: string,
        options?: any
    ) {
        try {
            const accessToken = await this.msGraphTokenService.getGraphToken();
            const headersWithAuthorization = {
                ...headers,
                Authorization: `Bearer ${accessToken}`,
            };
            return await this.httpRequestWrapper.post(url, dataType, headersWithAuthorization, data, options);
        } catch (error) {
            if ((error as any).status && (error as any).status === 401) {
                const newToken = await this.msGraphTokenService.getNewGraphTokenFromAPI();
                const headersWithAuthorization = {
                    ...headers,
                    Authorization: `Bearer ${newToken}`,
                };
                return this.httpRequestWrapper.post(url, dataType, headersWithAuthorization, data, options);
            }
            throw error;
        }
    }
}

export enum SessionStorageKeys {
    urlLocation = "location",
}

export enum LocalStorageKeys {
    awsCredentials = "awsCredentials",
    loginUrl = "loginUrl",
    idToken = "idToken",
    graphToken = "graphToken",
    currentLoggedEmail = "currentLoggedEmail",
    currentTheme = "theme",
    reminderDate = "reminderDate",
    reminderCalendarDate = "reminderCalendarDate",
    reminderCheckedValue = "reminderCheckedValue",
    transferExpiresCheckedValue = "transferExpiresCheckedValue",
    expireDate = "expireDate",
    expireCalendarDate = "expireCalendarDate",
    maxProjectPageSize = "maxProjectPageSize",
    lastReminder = "lastReminder",
    lastExpired = "lastExpired",
    npcProjectExpiredDate = "npcProjectExpiredDate",
}

export enum ProjectsCacheKeys {
    /**
     * @deprecated The defaultCacheName is not used. Leave only to clear leftovers.
     */
    defaultCacheName = "email-projectsCache",
    actionItemsCacheName = "actionitems-projectsCache",
    submittalsCacheName = "submittals-projectsCache",
    rfisCacheName = "rfis-projectsCache",
    sendAndFileCacheName = "sendAndFile-projectsCache",
    fileTransferCacheName = "fileTransfer-projectsCache",
    myProjectsCacheName = "my-projectsCache",
    globalProjectsCacheName = "global-projectsCache",
}

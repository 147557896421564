import { ProviderConfiguration } from "./ProviderConfiguration";

export class IdpConfigurationParameters {
    response_type: string | undefined;
    nonce: string | undefined;
    client_id: string | undefined;
    redirect_uri: string | undefined;
    prompt: string | undefined;
    response_mode: string | undefined;
    access_type: string | undefined;
    token_response_type: string | undefined;
    token_resource: string | undefined;
    tenant: string | undefined;
    scope: string | undefined;
    token_scope: string | undefined;

    static azureProvider = "3e2fa735-9bb4-4c47-b8e8-ecdfa986ad64";
    // This array must match the fields defined in this class, so that the values can be populated from the array of
    // key value pairs returned from the IdpConfiguration endpoint
    private static keys: Array<keyof IdpConfigurationParameters> = [
        "response_type",
        "nonce",
        "client_id",
        "redirect_uri",
        "prompt",
        "response_mode",
        "access_type",
        "token_response_type",
        "token_resource",
        "tenant",
        "scope",
        "token_scope",
    ];

    static fromProviderConfiguration(
        providerConfiguration: ProviderConfiguration,
        tenantId: string | null
    ): IdpConfigurationParameters {
        const parameters = new IdpConfigurationParameters();

        for (const key of IdpConfigurationParameters.keys) {
            if (key === "tenant" && tenantId && providerConfiguration.id !== this.azureProvider) {
                parameters[key] = tenantId;
            } else {
                parameters[key] = providerConfiguration.parameters.find((p: any) => p.key === key)?.value;
            }
        }

        return parameters;
    }
}

import * as React from "react";
import "./MoreOptionsComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import {
    Icon,
    Checkbox,
    IDropdownOption,
    Dropdown,
    ResponsiveMode,
    IDropdownStyles,
    ITag,
} from "office-ui-fabric-react";
import { TransferSettingsProps } from "../../../models/shared/FileTransferSettingsProps";
import TranslatedDatePickerComponent from "../translatedDatePicker/TranslatedDatePickerComponent";
import { ElementVisibility } from "../../../models/shared/ElementVisibility";
import { StorageWrapper } from "../../../services/StorageWrapper";
import { LocalStorageKeys } from "../../../models/StorageKeys";
import { DateHelpers, DateHelper } from "../../../helpers/DateHelpers";
import { MoreOptionsDetails } from "../../../models/shared/FileTransferMoreOptionsDetails";
import { Log } from "../../../services/Logger";
import { MailboxItem } from "../../../services/OfficeWrapper";
export interface IDropdownOptionEx extends IDropdownOption {
    /**
     * used to hold the days in a datepicker component
     */
    days?: any;
}

export interface MoreOptionsComponentProps extends LocalizeContextProps {
    selectedProject?: ITag | null;
    onExpand: Function;
    projectSettings: TransferSettingsProps;
    isProjectChanged?: boolean;
    onReminderClicked?: () => void;
    disabled: boolean;
    storageWrapper: StorageWrapper;
    dateHelpers: DateHelpers;
    isTransferFailed?: boolean;
    moreOptionsDetails: MoreOptionsDetails;
    mailboxItem?: MailboxItem | null;
    collapseMoreOptions?: boolean;
}

export interface MoreOptionsComponentState {
    isCollapsed: boolean;
    isCheckedTransfer: boolean;
    isCheckedReminder: boolean;
    isCheckedAllowRecipient: boolean;
    isCheckedExchangeInfo: boolean;
    options: IDropdownOptionEx[];
    reminderOptions: IDropdownOptionEx[];
    baseReminderOptions: IDropdownOptionEx[];
    isClickedOnDate: boolean;
    expiredDate: Date | undefined;
    isClickedOnDateReminder: boolean;
    reminderDate: Date | undefined;
    selectedOption: number | undefined;
    selectedReminderOption: number | undefined;
    maxDateRange: Date | undefined;
    requireNixLoginForDownload: boolean;
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdownItems: { maxHeight: "185px", overflowY: "auto" },
    callout: { marginRight: "12.5%" },
};
const TWOWEEKPICK = 23;
const TENBUSINESSBEFOREPICK = 10;
export const ONDATEPICK = 99;
export const isOnDatePick = (value: number | undefined) => value === ONDATEPICK;

class MoreOptionsComponent extends React.Component<MoreOptionsComponentProps, MoreOptionsComponentState> {
    datePickerCalloutRef: React.RefObject<unknown>;

    strDay = this.props.translate("SHARED.MOREOPTIONS.REMINDER_DROPDOWN.DAY") as string;
    strDays = this.props.translate("SHARED.MOREOPTIONS.REMINDER_DROPDOWN.DAYS") as string;
    strIn = this.props.translate("SHARED.MOREOPTIONS.REMINDER_DROPDOWN.IN") as string;
    strBusiness = this.props.translate("SHARED.MOREOPTIONS.REMINDER_DROPDOWN.BUSINESS") as string;
    strBefore = this.props.translate("SHARED.MOREOPTIONS.REMINDER_DROPDOWN.BEFORE_EXPIRATION") as string;

    constructor(props: MoreOptionsComponentProps, context: MoreOptionsComponentState) {
        super(props, context);
        this.datePickerCalloutRef = React.createRef();
        this.state = {
            isCollapsed: false,
            isCheckedTransfer: false,
            isCheckedReminder: false,
            isCheckedAllowRecipient: false,
            isCheckedExchangeInfo: false,
            isClickedOnDate: false,
            options: [
                {
                    key: 0,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.1_DAY") as string,
                    days: 1,
                },
                {
                    key: 1,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.2_DAYS") as string,
                    days: 2,
                },
                {
                    key: 2,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.3_DAYS") as string,
                    days: 3,
                },
                {
                    key: 3,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.4_DAYS") as string,
                    days: 4,
                },
                {
                    key: 6,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.5_DAYS") as string,
                    days: 5,
                },
                {
                    key: 7,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.6_DAYS") as string,
                    days: 6,
                },
                {
                    key: 8,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.1_WEEK") as string,
                    days: 7,
                },
                {
                    key: 9,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.2_WEEKS") as string,
                    days: 14,
                },
                {
                    key: 10,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.3_WEEKS") as string,
                    days: 21,
                },
                {
                    key: 11,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.1_MONTH") as string,
                    days: 28,
                },
                {
                    key: ONDATEPICK,
                    text: this.props.translate("SHARED.MOREOPTIONS.EXPIRATION_DROPDOWN.ON_DATE") as string,
                    days: 0,
                },
            ],
            reminderOptions: [],
            baseReminderOptions: [
                {
                    key: 23,
                    text: this.props.translate("SHARED.MOREOPTIONS.REMINDER_DROPDOWN.1_WEEK") as string,
                    days: 7,
                },
                {
                    key: 24,
                    text: this.props.translate("SHARED.MOREOPTIONS.REMINDER_DROPDOWN.2_WEEKS") as string,
                    days: 14,
                },
                {
                    key: 25,
                    text: this.props.translate("SHARED.MOREOPTIONS.REMINDER_DROPDOWN.3_WEEKS") as string,
                    days: 21,
                },
                {
                    key: 26,
                    text: this.props.translate("SHARED.MOREOPTIONS.REMINDER_DROPDOWN.1_MONTH") as string,
                    days: 28,
                },
                {
                    key: ONDATEPICK,
                    text: this.props.translate("SHARED.MOREOPTIONS.REMINDER_DROPDOWN.ON_DATE") as string,
                    days: 0,
                },
            ],
            expiredDate: undefined,
            reminderDate: DateHelper.TodayDate,
            isClickedOnDateReminder: false,
            selectedOption: undefined,
            selectedReminderOption: undefined,
            maxDateRange: undefined,
            requireNixLoginForDownload: false,
        };
    }

    componentDidMount() {
        Log.info("componentDidMount MoreOptionsComponent");

        this.reloadLocalStorageData();

        if (this.props.isTransferFailed) {
            this.setState({
                isCheckedTransfer: this.props.moreOptionsDetails.expiresOn ? true : false,
                isCheckedReminder: this.props.moreOptionsDetails.reminderOn ? true : false,
                isCheckedAllowRecipient: this.props.moreOptionsDetails.allowPartialDownload,
                isCheckedExchangeInfo: this.props.moreOptionsDetails.requireNixLoginForDownload,
                selectedOption: undefined,
            });
        }
    }

    async componentDidUpdate(
        prevProps: Readonly<MoreOptionsComponentProps>,
        prevState: Readonly<MoreOptionsComponentState>,
        snapshot?: any
    ) {
        const { projectSettings: Settings } = this.props;
        // collapse moreOptions if there is an inline error in attachment component
        if (
            this.props.collapseMoreOptions &&
            this.props.collapseMoreOptions !== prevProps.collapseMoreOptions &&
            this.state.isCollapsed
        ) {
            this.toggleCollapse();
        }
        if (this.props.mailboxItem && prevProps.mailboxItem !== this.props.mailboxItem) {
            this.updateReminderValues();
        }

        if (prevState.isCollapsed !== this.state.isCollapsed && this.state.isCollapsed && !this.state.selectedOption) {
            this.populateDefault();
        }
        if (this.props.isProjectChanged && this.state.selectedOption) {
            const isExpiresChecked = this.loadLocalStorage(LocalStorageKeys.transferExpiresCheckedValue);
            this.setState({
                selectedOption: undefined,
                expiredDate: isExpiresChecked ? this.state.expiredDate : undefined,
            });

            if (this.state.isCollapsed) {
                this.toggleCollapse();
            }
        }
        if (
            prevProps.projectSettings.hasDefaultZeroValue !== Settings.hasDefaultZeroValue &&
            Settings.hasDefaultZeroValue &&
            this.props.isProjectChanged &&
            !this.state.expiredDate
        ) {
            // if the project changed, and there isn't an expirationdate
            this.setState(
                {
                    selectedOption: undefined,
                    expiredDate: undefined,
                    isClickedOnDateReminder: false,
                    isCheckedTransfer: false,
                    isCheckedReminder: false,
                    isClickedOnDate: false,
                },
                () => {
                    Settings.shouldClearReminderDate = true;
                    Settings.shouldClearExpiredDate = true;
                    this.populateDefault();
                }
            );
        }
        if (this.props.disabled !== prevProps.disabled) {
            Settings.shouldClearReminderDate = false;
            if (this.state.isCollapsed) {
                this.toggleCollapse();
            }
            if (this.state.reminderDate?.toString() === "Invalid Date") {
                // if an invalid Date (for some reason), AND there is an expiration Date
                // then set the ReminderDate to 1 day prior...?  should it check if its After today?
                this.setState({
                    reminderDate: this.state.expiredDate
                        ? DateHelper.getDateWithOffset(this.state.expiredDate, -1)
                        : DateHelper.TodayDate,
                });
            }
        }

        if (
            this.props.isProjectChanged &&
            prevProps.projectSettings.defaultExpireDays !== this.props.projectSettings.defaultExpireDays
        ) {
            this.removeLocalStorage(LocalStorageKeys.npcProjectExpiredDate);
        }
    }

    private removeLocalStorage(key: LocalStorageKeys) {
        this.props.storageWrapper.removeLocalStorageItem(key);
    }

    private saveLocalStorage(key: LocalStorageKeys, value: any) {
        if (value === undefined) {
            return;
        }
        this.props.storageWrapper.saveLocalStorage(key, JSON.stringify(value));
    }
    private loadLocalStorage(key: LocalStorageKeys): any {
        const value = this.props.storageWrapper.loadLocalStorage(key);
        if (!value || value === "") {
            return undefined;
        }

        try {
            return JSON.parse(value);
        } catch {
            return undefined;
        }
    }
    private retrieveValueFromLocalStorageIfExists(key: LocalStorageKeys) {
        const localStorageValue = this.loadLocalStorage(key);
        if (localStorageValue) {
            const retrievedValue = localStorageValue;
            switch (key) {
                case "reminderDate":
                    this.setState({ selectedReminderOption: retrievedValue.key, isClickedOnDateReminder: false });
                    break;
                case "reminderCalendarDate":
                    this.setState({
                        reminderDate: DateHelper.getDateWithOffset(DateHelper.TodayDate, Math.ceil(retrievedValue)),
                    });
                    break;
                case "reminderCheckedValue":
                    this.setState({ isCheckedReminder: retrievedValue });
                    this.props.projectSettings.shouldClearReminderDate = retrievedValue ? false : true;
                    break;
                case "expireDate":
                    this.setState({ selectedOption: retrievedValue.key });
                    break;
                case "expireCalendarDate":
                    const theExpireDate = DateHelper.getDateWithOffset(DateHelper.TodayDate, Math.ceil(retrievedValue));
                    this.setState({ expiredDate: theExpireDate });
                    this.props.projectSettings.expireDate = theExpireDate;
                    break;
                case "transferExpiresCheckedValue":
                    this.setState({ isCheckedTransfer: retrievedValue });
                    this.props.projectSettings.shouldClearExpiredDate = retrievedValue ? false : true;
                    break;
            }
        }
    }

    private reloadLocalStorageData() {
        const moreOptionsLocalStorageKeys = [
            LocalStorageKeys.transferExpiresCheckedValue,
            LocalStorageKeys.expireDate,
            LocalStorageKeys.expireCalendarDate,
            LocalStorageKeys.reminderDate,
            LocalStorageKeys.reminderCalendarDate,
            LocalStorageKeys.reminderCheckedValue,
        ];
        moreOptionsLocalStorageKeys.map((key) => {
            this.retrieveValueFromLocalStorageIfExists(key);
        });
    }

    updateReminderValues(): void {
        const { projectSettings: Settings } = this.props;
        const savedReminderDate = this.loadLocalStorage(LocalStorageKeys.reminderDate);
        const savedReminderCheckedValue = this.loadLocalStorage(LocalStorageKeys.reminderCheckedValue);
        if (savedReminderDate && savedReminderCheckedValue) {
            const savedReminderValue = savedReminderDate;
            if (isOnDatePick(savedReminderValue.key)) {
                this.setState({
                    isClickedOnDateReminder: true,
                    selectedReminderOption: savedReminderValue.key,
                    isCheckedReminder: savedReminderCheckedValue,
                });
                Settings.reminderDate = this.state.reminderDate;
            } else {
                this.setState({
                    isClickedOnDateReminder: false,
                    selectedReminderOption: savedReminderValue.key,
                    isCheckedReminder: savedReminderCheckedValue,
                });
                Settings.reminderDate = undefined;
                Settings.reminderObject = {
                    key: savedReminderValue?.key,
                    text: savedReminderValue?.text,
                    days: savedReminderValue?.days,
                };
            }
        } else {
            const shouldReminderBeChecked =
                this.props.projectSettings.transferExpiresByDefault && savedReminderCheckedValue === undefined
                    ? Settings.transferExpiresByDefault
                    : this.state.isCheckedReminder;
            this.setState({
                selectedReminderOption: 1,
                isClickedOnDateReminder: false,
                isCheckedReminder: shouldReminderBeChecked,
            });
            Settings.reminderObject = {
                text: `${this.props.translate(
                    "SHARED.MOREOPTIONS.REMINDER_DROPDOWN.IN"
                ) as string} 1 ${this.props.translate(
                    "SHARED.MOREOPTIONS.REMINDER_DROPDOWN.BUSINESS"
                ) as string} ${this.props.translate(
                    "SHARED.MOREOPTIONS.REMINDER_DROPDOWN.DAY"
                ) as string} ${this.props.translate(
                    "SHARED.MOREOPTIONS.REMINDER_DROPDOWN.BEFORE_EXPIRATION"
                ) as string}`,
                days: 1,
                key: 1,
            };
            this.saveLocalStorage(LocalStorageKeys.reminderDate, shouldReminderBeChecked);
            if (Settings.hasDefaultZeroValue) {
                this.saveLocalStorage(LocalStorageKeys.reminderDate, Settings.reminderObject);
            }
        }
    }

    private populateDefault() {
        const { projectSettings: Settings } = this.props;
        const expiredDate =
            Settings.defaultExpireDays !== 0 && Settings.defaultExpireDays
                ? this.loadLocalStorage(LocalStorageKeys.npcProjectExpiredDate)
                    ? this.loadLocalStorage(LocalStorageKeys.npcProjectExpiredDate)
                    : this.getExpireDate(Settings.defaultExpireDays)
                : this.state.expiredDate;
        this.updateReminderValues();
        const savedExpiredDate = this.loadLocalStorage(LocalStorageKeys.expireDate);
        if (savedExpiredDate && Settings.hasDefaultZeroValue) {
            const savedExpiredValue = savedExpiredDate;
            if (isOnDatePick(savedExpiredValue.key)) {
                this.setState({ selectedOption: savedExpiredValue.key, isClickedOnDate: true });
                Settings.expireDate = expiredDate;
            } else {
                this.setState({ selectedOption: savedExpiredValue.key, isClickedOnDate: false });

                Settings.expireDate = expiredDate;
                Settings.defaultExpireDays = savedExpiredValue.days;
            }
        } else {
            const selectedKey = expiredDate ? ONDATEPICK : 9;
            this.setState({ selectedOption: selectedKey, isClickedOnDate: false });
            Settings.expireDate = expiredDate;

            if (Settings.hasDefaultZeroValue) {
                const twoWeekOption = this.state.options.find((item) => item.key === 9);
                this.saveLocalStorage(LocalStorageKeys.expireDate, twoWeekOption);
            }
        }
        this.props.onExpand();
        if (!this.state.isCheckedTransfer) {
            this.isTransferExpired();
        }

        if (Settings.hasDefaultZeroValue) {
            Settings.shouldClearExpiredDate = !this.state.isCheckedTransfer;
            Settings.shouldClearReminderDate = !this.state.isCheckedReminder;
        }
        const isCheckedTransfer = Settings.transferExpiresByDefault
            ? Settings.transferExpiresByDefault
            : this.state.isCheckedTransfer;
        this.setState({
            isCheckedTransfer,
            isCheckedAllowRecipient: Settings.allowPartialDownload,
            isCheckedExchangeInfo: Settings.requireNixLoginForDownload,
            expiredDate: expiredDate,
            reminderDate: this.state.reminderDate
                ? this.state.reminderDate
                : DateHelper.addDays(DateHelper.TodayDate, 1),
            requireNixLoginForDownload: Settings.isNixLoginForDownload ?? false,
        });
        this.updateReminderOptions(30, isCheckedTransfer);
    }

    toggleCollapse = () => {
        if (!this.props.disabled) {
            this.setState({ isCollapsed: !this.state.isCollapsed });
        }
    };

    handleCheckedBox = () => {
        const { projectSettings: settings } = this.props;
        // flip the state of the checkbox... but.. this is an asynch task ..
        // it may not actually set the state before the next couple of lines are hit
        const newIsCheckedTransfer = !this.state.isCheckedTransfer;
        this.setState({ isCheckedTransfer: newIsCheckedTransfer });

        this.saveLocalStorage(LocalStorageKeys.transferExpiresCheckedValue, newIsCheckedTransfer);

        settings.transferExpiresByDefault = newIsCheckedTransfer;
        // if expiresTransfer is not checked, set this flag so that the expired date becomes undefined...during checks
        settings.shouldClearExpiredDate = !newIsCheckedTransfer;
        if (!this.loadLocalStorage(LocalStorageKeys.expireCalendarDate)) {
            const defaultExpirationDate = DateHelper.addDays(DateHelper.TodayDate, 14);
            this.setState({ expiredDate: defaultExpirationDate });
            this.saveLocalStorage(LocalStorageKeys.expireCalendarDate, 14);
            this.props.projectSettings.expireDate = defaultExpirationDate;
        }

        // setting defaultDays to be the number to be used if expiredDate in props does not exist
        const defaultDays = DateHelper.subDate(DateHelper.TodayDate, this.state.expiredDate);
        this.updateReminderOptions(defaultDays, this.state.isCheckedTransfer);
    };

    handleCheckedBoxReminder = () => {
        const newIsCheckedReminder = !this.state.isCheckedReminder;
        this.saveLocalStorage(LocalStorageKeys.reminderCheckedValue, newIsCheckedReminder);
        this.setState({ isCheckedReminder: newIsCheckedReminder });
        this.props.projectSettings.shouldClearReminderDate = !newIsCheckedReminder;
        if (this.props.onReminderClicked) {
            this.props.onReminderClicked();
        }
    };

    handleCheckedBoxRecipient = () => {
        this.setState({
            isCheckedAllowRecipient: !this.state.isCheckedAllowRecipient,
        });
        this.props.projectSettings.allowPartialDownload = !this.state.isCheckedAllowRecipient;
    };
    handleCheckedBoxExchange = () => {
        this.setState({
            isCheckedExchangeInfo: !this.state.isCheckedExchangeInfo,
        });
        this.props.projectSettings.requireNixLoginForDownload = !this.state.isCheckedExchangeInfo;
    };

    validateReminderAndPush(reminderOptions: IDropdownOptionEx[], theItem: IDropdownOptionEx): boolean {
        const { projectSettings: settings } = this.props;
        const theKey = theItem?.key ?? 0;
        const theDays = theItem?.days ?? 0;

        const expiredDate = settings.shouldClearExpiredDate ? undefined : settings.expireDate;
        const todayDate = DateHelper.TodayDate;
        const isBusiness = theKey > 0 && theKey < TWOWEEKPICK;
        const isBefore = theKey > 0 && theKey <= TENBUSINESSBEFOREPICK;

        let calcDate;
        let diffDaysStart;
        let diffDaysEnd;
        // if no expiration only 'reminder in' days are accepted
        if (!expiredDate) {
            if (isBefore) {
                return false;
            }
        } else {
            // There is an expiredDate
            if (isBefore) {
                // using the expiration date, work backwards  , adjust if business days
                calcDate = DateHelper.addDays(expiredDate, -theDays, isBusiness);
                // then calculate the number of days to today
                diffDaysEnd = DateHelper.subDate(todayDate, calcDate);
            } else {
                // using the start date, work forwards, adjust if business days
                calcDate = DateHelper.addDays(todayDate, theDays, isBusiness);
                diffDaysStart = DateHelper.subDate(calcDate, expiredDate);
            }
            const isAfterExpired = !(diffDaysEnd === undefined) && diffDaysEnd <= 0;
            const isBeforeToday = !(diffDaysStart === undefined) && diffDaysStart <= 0;

            if (isAfterExpired || isBeforeToday) {
                return false;
            }
        }
        reminderOptions.push(theItem);
        return true;
    }

    private getReminderOptionsBefore(reminderOptions: IDropdownOptionEx[]) {
        for (let i = 1; i <= 10; i++) {
            this.validateReminderAndPush(reminderOptions, {
                key: i,
                text: `${this.strIn} ${i} ${this.strBusiness} ${i === 1 ? this.strDay : this.strDays} ${
                    this.strBefore
                }`,
                days: i,
            });
        }
    }

    private getReminderOptionsAfter(reminderOptions: IDropdownOptionEx[]) {
        for (let i = 1; i <= 10; i++) {
            this.validateReminderAndPush(reminderOptions, {
                key: i + 12,
                text: `${this.strIn} ${i} ${this.strBusiness} ${i === 1 ? this.strDay : this.strDays}`,
                days: i,
            });
        }
    }

    private getReminderOptionsWeeksAndOnDate(reminderOptions: IDropdownOptionEx[]) {
        for (const item of this.state.baseReminderOptions) {
            this.validateReminderAndPush(reminderOptions, item);
        }
    }

    updateReminderOptions(selectedExpireDays: number, isCheckedTransfer?: Boolean | undefined) {
        Log.info("updateReminderOptions-----");
        Log.info(`selecteddays ${selectedExpireDays}`);
        const prevExpireDate = this.props.projectSettings.expireDate ?? this.getExpireDate(selectedExpireDays);

        const expirationDate: Date | undefined =
            this.props.projectSettings.defaultExpireDays !== 0 ? new Date(prevExpireDate) : this.state.expiredDate;

        if (selectedExpireDays === 0) {
            this.setState({ isClickedOnDateReminder: true });
        }
        this.props.projectSettings.expireDate = expirationDate ? new Date(expirationDate) : undefined;

        this.setState({ expiredDate: expirationDate });

        const reminderOptions: IDropdownOptionEx[] = [];

        this.getReminderOptionsBefore(reminderOptions);
        this.getReminderOptionsAfter(reminderOptions);
        this.getReminderOptionsWeeksAndOnDate(reminderOptions);
        const defaultReminderOption = reminderOptions[0];

        if (
            this.state.selectedReminderOption &&
            !this.isSelectedReminderOptionExists(reminderOptions, expirationDate)
        ) {
            this.props.projectSettings.reminderObject = {
                text: reminderOptions[0]?.text,
                days: reminderOptions[0]?.days,
                key: reminderOptions[0]?.key as number,
            };
        }
        const dateRange = expirationDate ? DateHelper.addDays(expirationDate, -1) : undefined;

        this.setState({
            reminderOptions,
            maxDateRange: dateRange,
            selectedReminderOption: this.state.selectedReminderOption
                ? this.isSelectedReminderOptionExists(reminderOptions, expirationDate) &&
                  !this.isExpiredBeforeOrEqualToReminderOn(expirationDate, isCheckedTransfer)
                    ? this.state.selectedReminderOption
                    : (reminderOptions[0]?.key as number)
                : (defaultReminderOption?.key as number),
        });
        // if out of range , give it a default reminder
        const resetToOneBusDayBeforeExp =
            this.isExpiredBeforeOrEqualToReminderOn(expirationDate, isCheckedTransfer) ||
            (!this.isSelectedReminderOptionExists(reminderOptions, expirationDate) &&
                this.state.selectedReminderOption &&
                this.props.projectSettings.hasDefaultZeroValue) ||
            this.state.selectedReminderOption === 1;
        if (resetToOneBusDayBeforeExp) {
            this.saveLocalStorage(LocalStorageKeys.reminderDate, reminderOptions[0]);
            // updates the reminder date correctly when there are no business days between today and the expiration date
            if (expirationDate) {
                const areThereBusinessDays = DateHelper.areThereBusinessDaysBetweenDateAndToday(expirationDate);
                this.setState({
                    reminderDate: DateHelper.addDays(expirationDate, -1, areThereBusinessDays),
                    isClickedOnDateReminder: true,
                });
                this.props.projectSettings.reminderDate = DateHelper.addDays(expirationDate, -1, areThereBusinessDays);
            }
        }

        if (isOnDatePick(this.state.selectedReminderOption)) {
            this.setState({ isClickedOnDateReminder: true });
        }
    }

    private isSelectedReminderOptionExists(options: IDropdownOptionEx[], expirationDate: Date | undefined): boolean {
        if (
            isOnDatePick(this.state.selectedReminderOption) &&
            this.isExpiredBeforeOrEqualToReminderOn(expirationDate, this.state.isCheckedTransfer)
        ) {
            this.setState({ isClickedOnDateReminder: false });
            this.props.projectSettings.reminderDate = undefined;
            if (this.state.expiredDate) {
                this.props.projectSettings.reminderObject = {
                    text: `${this.strIn} 1 ${this.strBusiness} ${this.strDay} ${this.strBefore}`,
                    days: 1,
                    key: 0,
                };
                this.setState({ reminderDate: DateHelper.getDateWithOffset(this.state.expiredDate, -1) });
            }
            this.saveLocalStorage(LocalStorageKeys.reminderDate, options[0]);
            const daysBetweenReminderAndCurrentDate = DateHelper.calculateNumberOfDaysBetweenTwoDates(
                this.state.reminderDate,
                DateHelper.TodayDate
            );
            this.saveLocalStorage(LocalStorageKeys.reminderCalendarDate, daysBetweenReminderAndCurrentDate);
        }
        return options.some((item) => item.key === this.state.selectedReminderOption);
    }

    private onExpireItemChange(event: React.FormEvent<HTMLDivElement>, item?: IDropdownOptionEx): void {
        const { projectSettings: settings } = this.props;
        if (item) {
            let theDays = item?.days ?? 0;
            const theKey = item?.key as number;

            if (theDays) {
                settings.defaultExpireDays = theDays;
            }

            if (theDays === 0) {
                theDays++;
                // changes to adjust reminder date to not be set to today
                settings.reminderDate = DateHelper.addDays(DateHelper.TodayDate, theDays);
                this.setState({ reminderDate: DateHelper.addDays(DateHelper.TodayDate, theDays) });
            }

            if (this.state.reminderDate && DateHelper.isSameDay(this.state.reminderDate, DateHelper.TodayDate)) {
                settings.reminderDate = DateHelper.addDays(DateHelper.TodayDate, 1);
                this.setState({ reminderDate: settings.reminderDate });
            }

            const newExpiredDate = DateHelper.addDays(DateHelper.TodayDate, theDays);
            settings.expireDate = newExpiredDate;

            if (
                newExpiredDate &&
                this.isExpiredBeforeOrEqualToReminderOn(newExpiredDate, this.state.isCheckedTransfer)
            ) {
                settings.reminderDate = undefined;
            }

            this.setState({
                isClickedOnDate: isOnDatePick(theKey),
                selectedOption: theKey,
                expiredDate: newExpiredDate,
            });

            const daysBetween = DateHelper.subDate(DateHelper.TodayDate, newExpiredDate);

            this.saveLocalStorage(LocalStorageKeys.expireDate, item);
            this.saveLocalStorage(LocalStorageKeys.expireCalendarDate, daysBetween);
            this.saveLocalStorage(LocalStorageKeys.lastExpired, newExpiredDate);
        } else {
            this.setState({ isClickedOnDate: true });
        }
        this.updateReminderOptions(30, this.state.isCheckedTransfer);
    }

    private onReminderItemChange(event: React.FormEvent<HTMLDivElement>, item?: IDropdownOptionEx): void {
        const { projectSettings: settings } = this.props;
        if (!item) {
            this.setState({ isClickedOnDateReminder: true });
            return;
        }

        let theDays = item?.days;
        const theKey = item?.key as number;

        settings.reminderObject = { text: item?.text, days: theDays, key: theKey };
        settings.reminderDate = undefined;
        settings.shouldClearReminderDate = isOnDatePick(theKey);

        const expireDate = settings.expireDate;

        const isBusiness = theKey > 0 && theKey < TWOWEEKPICK;
        const isBefore = theKey > 0 && theKey <= TENBUSINESSBEFOREPICK;
        let newReminderDate;
        if (expireDate && isBefore) {
            newReminderDate = DateHelper.addDays(expireDate, -theDays, isBusiness);
        } else {
            if (theDays === 0) {
                theDays++;
            }
            newReminderDate = DateHelper.addDays(DateHelper.TodayDate, theDays, isBusiness);
        }
        settings.reminderDate = newReminderDate;
        this.setState({
            isClickedOnDateReminder: isOnDatePick(theKey),
            selectedReminderOption: theKey,
            reminderDate: newReminderDate,
        });

        const daysBetween = DateHelper.subDate(DateHelper.TodayDate, newReminderDate);
        // When coming from unit tests, data is set to 1, to avoid writing to local storage
        if (item?.data === 1) {
            return;
        }
        this.saveLocalStorage(LocalStorageKeys.reminderDate, item);
        this.saveLocalStorage(LocalStorageKeys.reminderCalendarDate, daysBetween);
        this.saveLocalStorage(LocalStorageKeys.lastReminder, newReminderDate);
    }

    private isExpiredDateTodayOrTomorrow(date: Date | undefined): boolean {
        const today = DateHelper.TodayDate;
        const tomorrow = DateHelper.TodayDate;
        tomorrow.setDate(today.getDate() + 1);
        const selectedDate = date ?? this.state.expiredDate;
        if (selectedDate instanceof Date && this.state.isCheckedTransfer) {
            const isToday = DateHelper.isSameDay(selectedDate, today);

            const isTomorrow = DateHelper.isSameDay(selectedDate, tomorrow);

            this.props.projectSettings.shouldClearReminderDate =
                isToday || isTomorrow || !this.state.isCheckedReminder ? true : false;
            return isToday || isTomorrow;
        }
        return false;
    }

    private isExpiredBeforeOrEqualToReminderOn(
        date: Date | undefined,
        isExpiredChecked?: Boolean | undefined
    ): boolean {
        const reminderDate = this.state.reminderDate;
        if (
            date &&
            reminderDate &&
            DateHelper.isValidDate(date) &&
            DateHelper.isValidDate(reminderDate) &&
            isExpiredChecked
        ) {
            return date.getTime() < reminderDate.getTime() || DateHelper.isSameDay(reminderDate, date);
        }
        return false;
    }

    private onExpiredDateChanged(date: Date | undefined): void {
        const { projectSettings: settings } = this.props;

        const expireDate = date ? DateHelper.adjustTime(date) : undefined;

        let reminderDate =
            date && !this.isExpiredDateTodayOrTomorrow(expireDate)
                ? DateHelper.addDays(DateHelper.TodayDate, 1)
                : undefined;
        this.setState({ reminderDate: reminderDate });

        if (
            expireDate &&
            reminderDate &&
            this.isExpiredBeforeOrEqualToReminderOn(expireDate, this.state.isCheckedTransfer)
        ) {
            const areThereBusinessDays = DateHelper.areThereBusinessDaysBetweenDateAndToday(expireDate);
            reminderDate = DateHelper.addDays(expireDate, -1, areThereBusinessDays);
            settings.reminderDate = reminderDate;
            const dayBeforeExpire = DateHelper.subDate(DateHelper.TodayDate, reminderDate);
            this.saveLocalStorage(LocalStorageKeys.reminderCalendarDate, dayBeforeExpire);
        }

        settings.expireDate = expireDate;
        settings.shouldClearReminderDate = reminderDate ? false : true;

        const maxDateRange = expireDate ? DateHelper.TodayDate : undefined;
        if (maxDateRange) {
            maxDateRange.setDate(maxDateRange.getDate() - 1);
        }

        let validReminderDate =
            reminderDate && this.isExpiredBeforeOrEqualToReminderOn(expireDate, this.state.isCheckedTransfer)
                ? reminderDate
                : this.state.reminderDate;

        const daysBetween = DateHelper.subDate(DateHelper.TodayDate, expireDate);
        // changes to adjust reminder date to not be set to today
        if (validReminderDate && DateHelper.isSameDay(DateHelper.TodayDate, validReminderDate)) {
            validReminderDate = DateHelper.addDays(validReminderDate, 1);
            settings.reminderDate = validReminderDate;
        }

        this.setState({ expiredDate: settings.expireDate, reminderDate: validReminderDate, maxDateRange }, () => {
            if (!this.state.isCheckedTransfer) {
                this.isTransferExpired();
            } else {
                this.updateReminderOptions(30, this.state.isCheckedTransfer);
            }
        });
        // Future proof: if coming from a unit test...we shouldn't save to LocalStorage
        // however, we need to create a get/set wrapper for these sticky params and that is a future technical task
        this.saveLocalStorage(LocalStorageKeys.expireCalendarDate, daysBetween);
        this.saveLocalStorage(LocalStorageKeys.lastExpired, expireDate);
    }

    private onReminderDateChanged(date: Date | undefined): void {
        const reminderDate = date ? DateHelper.adjustTime(date) : undefined;
        this.props.projectSettings.shouldClearReminderDate = false;
        this.props.projectSettings.reminderDate = reminderDate;
        const daysBetween = DateHelper.subDate(DateHelper.TodayDate, reminderDate);
        this.saveLocalStorage(LocalStorageKeys.reminderCalendarDate, daysBetween);
        this.saveLocalStorage(LocalStorageKeys.lastReminder, reminderDate);
        this.setState({ reminderDate: reminderDate });
    }
    private getExpireDate(numberOfDays: number): Date {
        const expiredDate = DateHelper.TodayDate;
        expiredDate.setDate(expiredDate.getDate() + numberOfDays);
        return expiredDate;
    }

    private isTransferExpired(): void {
        const savedExpiredDate = this.loadLocalStorage(LocalStorageKeys.expireDate);
        const savedExpiredCalendarDate = this.loadLocalStorage(LocalStorageKeys.expireCalendarDate);
        if (savedExpiredDate && this.props.projectSettings.hasDefaultZeroValue) {
            const savedExpiredValue = savedExpiredDate;
            const differenceInDays = DateHelper.subDate(DateHelper.TodayDate, this.state.expiredDate);
            if (isOnDatePick(savedExpiredValue.key)) {
                this.props.projectSettings.defaultExpireDays = differenceInDays ? differenceInDays : 28;
                this.props.projectSettings.expireDate = differenceInDays
                    ? DateHelper.addDays(DateHelper.TodayDate, differenceInDays)
                    : undefined;
                this.setState({ isClickedOnDate: true });
            }
            this.updateReminderOptions(differenceInDays || savedExpiredCalendarDate, this.state.isCheckedTransfer);
            return;
        }
        this.updateReminderOptions(30, this.state.isCheckedTransfer);
    }

    render(): JSX.Element {
        return (
            <div>
                <div onClick={this.toggleCollapse} className="newforma-moreOptionsParent">
                    <Icon
                        iconName={this.state.isCollapsed ? "ChevronDown" : "ChevronUp"}
                        className="newforma-moreoptionsicon"
                    />
                    <span
                        className={
                            !this.props.disabled ? "newforma-moreoptionstext" : "newforma-moreoptionstext-disabled"
                        }
                        title={this.props.disabled ? "Select a Project" : "Transfer expiration and reminder options"}
                    >
                        {this.props.translate("SHARED.MOREOPTIONS.MOREOPTIONS_LABEL") as string}
                    </span>
                </div>
                {this.state.isCollapsed ? (
                    <div className="newforma-moreoptionsLabelContainer">
                        <div className={`newforma-MoreOptionsItemComponent`}>
                            <Checkbox
                                className="newforma-checkboxMoreSmallLabel"
                                checked={this.state.isCheckedTransfer}
                                onChange={this.handleCheckedBox}
                                disabled={this.props.disabled}
                            />
                            <span className="newforma-moreoptionsLabel">
                                {this.props.translate("SHARED.MOREOPTIONS.CHECKBOX_TRANSFER") as string}
                            </span>
                            <Dropdown
                                className="newforma-dropdown-containerMore"
                                options={this.state.options}
                                label={""}
                                onChange={this.onExpireItemChange.bind(this)}
                                selectedKey={this.state.selectedOption}
                                placeholder={
                                    this.props.translate("SHARED.MOREOPTIONS.DROPDOWN_PLACEHOLDER_EXPIRATION") as string
                                }
                                responsiveMode={ResponsiveMode.large}
                                styles={dropdownStyles}
                                disabled={this.props.disabled || !this.state.isCheckedTransfer}
                            />
                        </div>
                        {isOnDatePick(this.state.selectedOption) && (
                            <div className="newforma-onDatepicker">
                                <TranslatedDatePickerComponent
                                    date={this.state.expiredDate}
                                    label={""}
                                    onDateChange={this.onExpiredDateChanged.bind(this)}
                                    required={false}
                                    clearDateButtonVisibility={ElementVisibility.None}
                                    minDate={DateHelper.addDays(DateHelper.TodayDate, 1)}
                                    disabled={this.props.disabled || !this.state.isCheckedTransfer}
                                />
                            </div>
                        )}
                        <div className="newforma-moreOptionsDivider" />

                        <div className={`newforma-MoreOptionsItemComponent`}>
                            <Checkbox
                                className="newforma-checkboxMoreSmallLabel"
                                checked={this.state.isCheckedReminder && !this.isExpiredDateTodayOrTomorrow(undefined)}
                                onChange={this.handleCheckedBoxReminder}
                                disabled={this.props.disabled || this.isExpiredDateTodayOrTomorrow(undefined)}
                            />

                            <span className="newforma-moreoptionsLabel">
                                {this.props.translate("SHARED.MOREOPTIONS.CHECKBOX_REMINDER") as string}
                            </span>
                            <Dropdown
                                className="newforma-dropdown-containerMore"
                                options={this.state.reminderOptions}
                                label={""}
                                selectedKey={this.state.selectedReminderOption}
                                onChange={this.onReminderItemChange.bind(this)}
                                responsiveMode={ResponsiveMode.large}
                                styles={dropdownStyles}
                                disabled={
                                    this.props.disabled ||
                                    !this.state.isCheckedReminder ||
                                    this.isExpiredDateTodayOrTomorrow(undefined)
                                }
                            />
                        </div>
                        {this.state.isClickedOnDateReminder && isOnDatePick(this.state.selectedReminderOption) && (
                            <div className="newforma-onDatepicker">
                                <TranslatedDatePickerComponent
                                    date={this.state.reminderDate}
                                    label={""}
                                    onDateChange={this.onReminderDateChanged.bind(this)}
                                    disabled={
                                        !this.state.isCheckedReminder || this.isExpiredDateTodayOrTomorrow(undefined)
                                    }
                                    required={false}
                                    clearDateButtonVisibility={ElementVisibility.None}
                                    minDate={DateHelper.addDays(DateHelper.TodayDate, 1)}
                                    maxDate={!this.state.isCheckedTransfer ? undefined : this.state.maxDateRange}
                                    isReminderComponent={true}
                                />
                            </div>
                        )}
                        <div className="newforma-moreOptionsDivider" />
                        <div className={`newforma-MoreOptionsItemComponent`}>
                            <Checkbox
                                className="newforma-checkboxMore"
                                checked={this.state.isCheckedAllowRecipient}
                                onChange={this.handleCheckedBoxRecipient}
                                disabled={this.props.disabled}
                            />

                            <span className="newforma-moreoptionsLabel">
                                {this.props.translate("SHARED.MOREOPTIONS.CHECKBOX_RECIPIENTS") as string}
                            </span>
                        </div>
                        <div className="newforma-moreOptionsDivider" />
                        <div className={`newforma-MoreOptionsItemComponent`}>
                            <Checkbox
                                className="newforma-checkboxMoreLargeLabel"
                                checked={this.state.isCheckedExchangeInfo}
                                onChange={this.handleCheckedBoxExchange}
                                disabled={this.props.disabled || this.state.requireNixLoginForDownload}
                            />
                            <span className="newforma-moreoptionsLabel">
                                {this.props.translate("SHARED.MOREOPTIONS.CHECKBOX_EXCHANGE") as string}
                            </span>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withLocalize(MoreOptionsComponent);

import "./KeywordsDropdown.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Dropdown, getTheme, IDropdownOption, ResponsiveMode } from "office-ui-fabric-react";
import { DetailedKeyword } from "../../../models/ProjectKeywordsResponse";

export interface KeywordsDropdownProps extends LocalizeContextProps {
    options: DetailedKeyword[];
    onSelectionChange: (option: DetailedKeyword | null) => void;
    label: string;
    isLoading: boolean;
    isProjectSelected: boolean;
    placeholder: string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    id?: string;
    selectFirstOption?: boolean;
    theme: string;
    selectedPurposeKey?: number | null;
    isActionItem?: boolean;
}

export interface KeywordsDropdownState {
    selectedKey: number | null;
    options: IDropdownOption[];
}

export const defaultKeywordsDropdownState: KeywordsDropdownState = {
    selectedKey: null,
    options: [],
};

class KeywordsDropdown extends React.Component<KeywordsDropdownProps, KeywordsDropdownState> {
    constructor(props: KeywordsDropdownProps, context: KeywordsDropdownState) {
        super(props, context);

        this.state = defaultKeywordsDropdownState;
        this.setDefaults();
    }

    componentDidMount(): void {
        this.setState({ options: this.state.options, selectedKey: this.state.selectedKey });
    }

    componentDidUpdate(
        prevProps: Readonly<KeywordsDropdownProps>,
        prevState: Readonly<KeywordsDropdownState>,
        snapshot?: any
    ) {
        if (this.props.options !== prevProps.options || this.state.options.length !== this.props.options.length) {
            this.setDefaults();
        }
    }

    private setDefaults(): void {
        const options = this.props.options;
        if (!this.props.options.length) {
            this.setState({ options: [], selectedKey: null });
            return;
        }

        // if selection not required need an option to "clear" the selection
        if (!this.props.required) {
            options.unshift({
                displayOrder: 0,
                name: this.props.translate("SHARED.KEYWORD_DROPDOWN.NONE") as string,
                type: "generated",
            });
        }

        const sortedOptions: IDropdownOption[] = options.map((keyword, index) => {
            keyword.displayOrder = index;
            return {
                text: keyword.name,
                key: index,
                data: keyword,
            };
        });

        let selectedKey = null;

        if (!!this.props.selectFirstOption && !this.props.required) {
            selectedKey = 1;
            this.props.onSelectionChange(this.props.options[selectedKey]);
        } else if (!!this.props.selectFirstOption && this.props.required) {
            selectedKey = 0;
            this.props.onSelectionChange(this.props.options[selectedKey]);
        }

        this.setState({ options: sortedOptions, selectedKey: selectedKey });
    }

    private onSelectionChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void {
        let selectedKey = index === undefined ? null : index;

        // if the "none" option is selected and field not required set the field selection to null
        if (selectedKey === 0 && !this.props.required) {
            selectedKey = null;
        }

        this.setState({ selectedKey });

        selectedKey !== null
            ? this.props.onSelectionChange(this.state.options[selectedKey].data)
            : this.props.onSelectionChange(null);
    }

    private getPlaceholder(): string {
        if (this.props.isLoading) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.LOADING") as string;
        }

        if (!this.props.isProjectSelected) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.NO_PROJECT_SELECTED") as string;
        }

        if (!this.props.options.length) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.NO_ITEMS") as string;
        }

        return this.props.placeholder;
    }

    render(): JSX.Element {
        const theme = getTheme();
        return (
            <Dropdown
                className={`newforma-keywordsDropdown ${this.props.className || ""}`}
                id={this.props.id}
                options={this.state.options}
                placeholder={this.getPlaceholder()}
                label={this.props.label}
                responsiveMode={ResponsiveMode.large}
                disabled={!!this.props.disabled}
                onChange={this.onSelectionChange.bind(this)}
                selectedKey={this.props.isActionItem ? this.state.selectedKey : this.props.selectedPurposeKey}
                required={!!this.props.required}
                styles={{
                    dropdown: {
                        selectors: {
                            "& :hover": {
                                borderColor: `${theme.palette.themeTertiary} !important`,
                            },
                        },
                    },
                }}
            />
        );
    }
}

export default withLocalize(KeywordsDropdown);

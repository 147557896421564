import * as React from "react";
import {
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    Label,
    SelectionMode,
    TextField,
    Separator,
} from "office-ui-fabric-react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { FormEvent } from "react";
import ProgressComponent from "../shared/progress/ProgressComponent";
import { ProjectListItemSelector } from "../../services/ProjectListItemSelector";
import { FilingLocation } from "../../models/FilingLocationsResponse";
import "./AppProjectListComponent.less";
import InputLinkComponent from "../shared/refreshProject/RefreshProjectComponent";

const projectListColumns: IColumn[] = [
    {
        key: "projectNameColumn",
        name: "Project",
        fieldName: "name",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isMultiline: true,
    },
];

export interface AppProjectListProps extends LocalizeContextProps {
    id?: string;
    projects: FilingLocation[];
    suggestedProjects: FilingLocation[];
    onProjectSelectionChanged: (project: FilingLocation | null) => void; // event to capture in parent comp
    isLoadingProjects: boolean;
    projectIdToSelect: string | null;
    autoSelectSuggestedProject: boolean;
    onRefresh: () => void;
}

export interface AppProjectListState {
    projects: ProjectWithKey[];
    selectedProject: FilingLocation | null;
    closedLoggedOutMessageBar: boolean;
}

interface ProjectWithKey extends FilingLocation {
    key: number;
}

class AppProjectListComponent extends React.Component<AppProjectListProps, AppProjectListState> {
    private projectsWithKey: ProjectWithKey[] = [];
    private listItemSelector: ProjectListItemSelector;

    constructor(props: AppProjectListProps, context: AppProjectListState) {
        super(props, context);

        this.state = {
            projects: [],
            selectedProject: null,
            closedLoggedOutMessageBar: false,
        };

        this.listItemSelector = new ProjectListItemSelector(this.onSelectionChanged.bind(this));
        projectListColumns[0].name = this.props.translate("PROJECT.PROJECT") as string;
    }

    async componentDidUpdate(
        prevProps: Readonly<AppProjectListProps>,
        prevState: Readonly<AppProjectListState>
    ): Promise<void> {
        if (prevProps === this.props) {
            return;
        }

        if (prevProps.projects !== this.props.projects) {
            this.getProjectsWithKeyMapping();
            await this.setState({ projects: this.projectsWithKey });
        }

        if (
            prevProps.isLoadingProjects !== this.props.isLoadingProjects ||
            prevProps.projectIdToSelect !== this.props.projectIdToSelect ||
            prevProps.autoSelectSuggestedProject !== this.props.autoSelectSuggestedProject
        ) {
            await this.selectProjectById(
                this.props.projectIdToSelect,
                this.props.projects,
                this.props.suggestedProjects
            );
        }
    }

    private async selectProjectById(
        projectId: string | null,
        allProjects: FilingLocation[],
        suggestedProjects: FilingLocation[]
    ): Promise<void> {
        if (!this.props.autoSelectSuggestedProject) {
            return;
        }
        await this.listItemSelector.projectById(projectId, allProjects, suggestedProjects);
    }

    private getProjectsWithKeyMapping(): void {
        // this key mapping is a workaround for the office-ui-fabric-react bug described here https://github.com/OfficeDev/office-ui-fabric-react/issues/5128
        this.projectsWithKey = this.props.projects.map((project, index) => ({ key: index, ...project }));
    }

    private onSelectionChanged(selectedProject: FilingLocation): void {
        this.setState({
            selectedProject: selectedProject,
        });
        this.props.onProjectSelectionChanged(selectedProject);
    }

    private async onRefresh(): Promise<void> {
        this.props.onRefresh();
    }

    private searchTextChanged(
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newSearchText: string = ""
    ): void {
        const filteredListOfProjects = newSearchText
            ? this.projectsWithKey.filter(
                  (project) => project.name.toLocaleLowerCase().indexOf(newSearchText.toLocaleLowerCase()) > -1
              )
            : this.projectsWithKey;
        this.setState({
            projects: filteredListOfProjects,
        });
    }

    private getSelectedProjectName(): string {
        return this.state.selectedProject && this.state.selectedProject.name
            ? `${this.props.translate("PROJECT.SELECTED_PROJECT")}: ${this.state.selectedProject.name}`
            : (this.props.translate("PROJECT.NO_SELECTION") as string);
    }

    private getSelectionKey(item: any, index?: number): string {
        return item.key;
    }

    render() {
        if (this.props.isLoadingProjects) {
            return (
                <div className="newforma-AppProjectListComponent">
                    <ProgressComponent message={this.props.translate("APP.LOADING_PROJECTS") as string} />
                </div>
            );
        } else {
            return (
                <div className="newforma-AppProjectListComponent">
                    <Label id="selectedProject" className="newforma-projectListSubheader">
                        {this.getSelectedProjectName()}
                    </Label>
                    <Separator />
                    <div className="newforma-suggestedProjectsContainer">
                        <Label className="newforma-projectListSubheader">
                            {this.props.translate("PROJECT.SEARCH.SUGGESTED") as string}
                        </Label>
                        <div className="newforma-suggestedProjectsList">
                            <DetailsList
                                componentRef={this.listItemSelector.suggestedProjectsRef}
                                className="newforma-projectListDetails"
                                items={this.props.suggestedProjects}
                                columns={projectListColumns}
                                getKey={this.getSelectionKey.bind(this)}
                                setKey="suggested"
                                layoutMode={DetailsListLayoutMode.justified}
                                selectionMode={SelectionMode.single}
                                isHeaderVisible={false}
                                selection={this.listItemSelector.suggestedProjectsSelection}
                                selectionPreservedOnEmptyClick={true}
                                enterModalSelectionOnTouch={true}
                                compact={true}
                                constrainMode={ConstrainMode.horizontalConstrained}
                                onShouldVirtualize={() => false}
                            />
                        </div>
                    </div>
                    <Separator />
                    <InputLinkComponent
                        labelText={this.props.translate("PROJECT.SEARCH.PLACEHOLDER") as string}
                        required={false}
                        className={"newforma-refreshButton"}
                        onRefresh={this.onRefresh.bind(this)}
                    />
                    <div className="newforma-projectListSearchBox">
                        <TextField
                            id="AppProjectListSearchBox"
                            className="newforma-AppProjectListSearchTextField"
                            onChange={this.searchTextChanged.bind(this)}
                        />
                    </div>
                    <div className="newforma-ProjectListContainer">
                        <DetailsList
                            componentRef={this.listItemSelector.allProjectsRef}
                            className="newforma-projectListDetails"
                            items={this.state.projects}
                            columns={projectListColumns}
                            getKey={this.getSelectionKey.bind(this)}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.single}
                            isHeaderVisible={false}
                            selection={this.listItemSelector.allProjectsSelection}
                            selectionPreservedOnEmptyClick={true}
                            enterModalSelectionOnTouch={true}
                            compact={true}
                            constrainMode={ConstrainMode.horizontalConstrained}
                            onShouldVirtualize={() => false}
                        />
                    </div>
                </div>
            );
        }
    }
}

export default withLocalize(AppProjectListComponent);

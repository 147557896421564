import * as React from "react";
import {
    CommandBar,
    ContextualMenuItemType,
    ICommandBarItemProps,
    IContextualMenuItem,
    MessageBarType,
    IButtonProps,
} from "office-ui-fabric-react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { OfficeRoamingSettings } from "../../../services/OfficeRoamingSettings";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../../services/AnalyticsManager";
import SettingsComponent from "../../shared/settings/SettingsComponent";
import { ConfigurationService } from "../../../services/ConfigurationService";
import { MailboxItem, OfficeWrapper } from "../../../services/OfficeWrapper";
import { Authenticator } from "../../../services/Authenticator";
import "./AppHeaderComponent.less";
import { Logger } from "../../../services/Logger";
import { CustomerSupportEmailService } from "../../../services/CustomerSupportEmailService";
import { WindowWrapper } from "../../../services/WindowWrapper";
import SearchComponent from "../../search/SearchComponent";
import { SmartFilingManager } from "../../../services/SmartFiling/SmartFilingManager";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import { EmailApiService } from "../../../services/NewformaApi/EmailApiService";
import AsyncResult = Office.AsyncResult;
import Dialog = Office.Dialog;
import ProjectTeamComponent from "../../projectTeam/ProjectTeamComponent";
import { SendAndFileHelpers } from "../../../helpers/SendAndFile/SendAndFileHelpers";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import FileTransferComponent from "../../fileTransfer/FileTransferComponent";
import { MsGraphApiService } from "../../../services/MsGraphApiService";
import { NrnServiceWrapper } from "../../../services/NrnServiceWrapper";
import { SubmittalsApiService } from "../../../services/NewformaApi/SubmittalsApiService";
import { ConfigurationsApiService } from "../../../services/NewformaApi/ConfigurationsApiService";
import { StorageWrapper } from "../../../services/StorageWrapper";
import { DateHelpers } from "../../../helpers/DateHelpers";
import { RfiApiService } from "../../../services/NewformaApi/RfiApiService";
import { InvalidateCacheService } from "../../../services/NewformaApi/InvalidateCacheService";
import { FileTransferApiService } from "../../../services/NewformaApi/FileTransferApiService";
import { TeachingBubble, ITeachingBubbleStyles, DirectionalHint } from "office-ui-fabric-react";
import { OutlookApiService } from "../../../services/OutlookApiService";

export interface AppHeaderProps extends LocalizeContextProps {
    loggedIn: boolean;
    onLogOut: () => void;
    analyticsManager: AnalyticsManager;
    configService: ConfigurationService;
    officeRoamingSettings: OfficeRoamingSettings;
    officeWrapper: OfficeWrapper;
    authenticator: Authenticator;
    logger: Logger;
    customerSupportEmailService: CustomerSupportEmailService;
    windowWrapper: WindowWrapper;
    onSettingsChanged: () => void;
    isEulaSupported: boolean;
    smartFilingManager: SmartFilingManager;
    mailboxItem: MailboxItem | null;
    projectsService: IProjectsService;
    emailApiService: EmailApiService;
    onExpiredSession: () => void;
    sendAndFileHelpers: SendAndFileHelpers;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    onDismissToast: () => void;
    formValidationHelpers: FormValidationHelpers;
    nrnServiceWrapper: NrnServiceWrapper;
    msGraphApiService: MsGraphApiService;
    configurationService: ConfigurationsApiService;
    submittalsApiService: SubmittalsApiService;
    storageWrapper: StorageWrapper;
    theme: string;
    dateHelpers: DateHelpers;
    rfiApiService: RfiApiService;
    invalidateCacheService: InvalidateCacheService;
    fileTransferApiService: FileTransferApiService;
    outlookApiService: OutlookApiService;
    showFilesTransferTeachingBubble: boolean;
    shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache: boolean;
    onDismissTeachingBubble: () => void;
    showFileTransferButton: boolean;
    isFileTransferAndEditorSupported: boolean;
    isFilePathInAttachmentsSupported: boolean;
    toastMessageFromParent?: string | null;
    hideNoProjectsToast: () => void;
    shouldShowFileTransferPanel: (shouldShow: boolean) => void;
    shouldShowFileTransferView: boolean;
}

export interface AppHeaderState {
    hideApplicationSettingsDialog: boolean;
    showSettingsPanel: boolean;
    showSearchPanel: boolean;
    showProjectTeamPanel: boolean;
    showFileTransferPanel: boolean;
    teachingBubbleDismissed: boolean;
    showTeachingBubble: boolean;
    isFileTransferAndEditorSupported: boolean;
    toastText?: string;
    toastType?: MessageBarType;
}

class AppHeaderComponent extends React.Component<AppHeaderProps, AppHeaderState> {
    constructor(props: Readonly<AppHeaderProps>, context: any) {
        super(props, context);

        this.state = {
            hideApplicationSettingsDialog: true,
            showSettingsPanel: false,
            showSearchPanel: false,
            showProjectTeamPanel: false,
            showFileTransferPanel: false,
            teachingBubbleDismissed: false,
            showTeachingBubble: false,
            isFileTransferAndEditorSupported: false,
        };

        props.officeWrapper.addHandlerAsync(Office.EventType.ItemChanged, this.itemChanged.bind(this));
    }

    async componentDidUpdate(prevProps: Readonly<AppHeaderProps>, prevState: Readonly<AppHeaderState>) {
        if (
            this.props.toastMessageFromParent === null &&
            this.props.toastMessageFromParent !== prevProps.toastMessageFromParent
        ) {
            this.setState({ toastText: "", toastType: undefined });
        }
    }

    private async itemChanged(_eventArgs: any): Promise<void> {
        // Used to trap email selection changes if the Task Pane is pinned
        this.props.logger.info("Selected mail item changed");
        const mailboxItem = this.props.officeWrapper.currentContextItem;

        if (_eventArgs.type !== "olkItemSelectedChanged" || !mailboxItem) {
            return;
        }

        this.setState({ showSettingsPanel: false, showSearchPanel: false });
    }

    openSettingsClicked() {
        this.props.logger.info("Settings panel opened");
        this.recordAnalyticsForUserAction(AnalyticsActionType.UserMenuSettingsOpened);
        this.setState({ showSettingsPanel: true });
    }

    private closeSettingsClicked() {
        this.props.logger.info("Settings panel closed");
        this.setState({ showSettingsPanel: false });
    }

    openSearchClicked() {
        this.props.logger.info("Search panel opened");
        this.recordAnalyticsForUserAction(AnalyticsActionType.UserMenuSearch);
        this.setState({ showSearchPanel: true });
    }

    private closeSearchClicked() {
        this.props.logger.info("Search panel closed");
        this.setState({ showSearchPanel: false });
    }

    private openFileTransfersClicked() {
        this.props.logger.info("File Transfer panel opened");
        this.recordAnalyticsForUserAction(AnalyticsActionType.UserMenuSearch);
        this.setState({ showFileTransferPanel: true });
        this.props.shouldShowFileTransferPanel(true);
        this.props.hideNoProjectsToast();
    }

    private closeFileTransferClicked() {
        this.props.logger.info("File Transfer panel closed");
        this.setState({ showFileTransferPanel: false });
        this.props.shouldShowFileTransferPanel(false);
    }

    openProjectTeamMembersClicked() {
        this.props.logger.info("Project Team panel opened");
        this.recordAnalyticsForUserAction(AnalyticsActionType.ProjectTeamViewed);
        this.setState({ showProjectTeamPanel: true });
    }

    private closeProjectTeamMembersClicked() {
        this.props.logger.info("Project Team panel closed");
        this.setState({ showProjectTeamPanel: false });
    }

    async signOutClicked(): Promise<void> {
        this.recordAnalyticsForUserAction(AnalyticsActionType.Logout);
        await this.props.officeRoamingSettings.logoutFromApp();
        this.props.authenticator.logoutFromApp();
        this.setState({ hideApplicationSettingsDialog: true });
        this.props.onLogOut();
    }

    onCustomerSupportClicked(): void {
        this.recordAnalyticsForUserAction(AnalyticsActionType.UserMenuCustomerSupport);
        const body = this.props.translate("APP.MISCELLANEOUS.CUSTOMER_SUPPORT_EMAIL_BODY") as string;
        const subject = this.props.customerSupportEmailService.getSubject();
        const url = `mailto:${this.props.customerSupportEmailService.getSupportEmailAddress()}?subject=${subject}&body=${encodeURIComponent(
            body
        )}`;
        this.props.windowWrapper.copyTextToClipboard(this.props.customerSupportEmailService.getDiagnostics());
        this.props.officeWrapper.displayDialogAsync(url, {}, (asyncResult: AsyncResult<Dialog>) => {
            this.props.windowWrapper.open(url);
            const dialog = asyncResult.value;
            dialog.close();
        });
    }

    private openFeedbackMenuItem(): ICommandBarItemProps {
        return {
            key: "feedback",
            iconProps: {
                iconName: "Feedback",
            },
            iconOnly: true,
            ["data-automation-id"]: "feedbackButton",
            name: this.props.translate("APP.COMMANDBAR.FEEDBACK") as string,
            href: this.props.translate("URLS.FEEDBACK") as string,
            target: "_blank",
            onClick: this.recordAnalyticsForUserAction.bind(this, AnalyticsActionType.UserMenuFeedback),
        };
    }

    private openProjectTeamMenuItem(): ICommandBarItemProps {
        return {
            key: "projectTeam",
            name: this.props.translate("APP.COMMANDBAR.PROJECT_TEAM") as string,
            iconProps: {
                iconName: "ContactList",
            },
            iconOnly: true,
            onClick: this.openProjectTeamMembersClicked.bind(this),
        };
    }

    private getOverflowMenuItem(): ICommandBarItemProps {
        return {
            key: "menuOptions",
            iconProps: {
                iconName: "More",
            },
            name: this.props.translate("APP.COMMANDBAR.OPTIONS") as string,
            iconOnly: true,
            ["data-automation-id"]: "overflowMenuButton",
            subMenuProps: {
                items: this.props.loggedIn
                    ? this.getOptionsMenuItemsAuthenticated()
                    : this.getOptionsMenuItemsUnauthenticated(),
            },
        };
    }

    private openSearchItem(): ICommandBarItemProps {
        return {
            key: "search",
            iconOnly: true,
            iconProps: {
                iconName: "Search",
            },
            className: "searchItem",
            name: this.props.translate("APP.COMMANDBAR.SEARCH") as string,
            onClick: this.openSearchClicked.bind(this),
            ["data-automation-id"]: "searchButton",
        };
    }

    private openFileTransfersItem(): ICommandBarItemProps {
        return {
            key: "fileTransfers",
            iconOnly: true,
            iconProps: {
                iconName: "Switch",
            },
            className: "fileTransfersItem",
            name: this.props.translate("APP.COMMANDBAR.FILE_TRANSFER") as string,
            onClick: this.openFileTransfersClicked.bind(this),
            ["data-automation-id"]: "fileTransfersButton",
        };
    }

    private openNewformaItem(): IContextualMenuItem {
        return {
            key: "openNewforma",
            name: this.props.translate("APP.COMMANDBAR.OPEN_NEWFORMA_WEB_APP") as string,
            iconProps: {
                iconName: "OpenInNewWindow",
            },
            ["data-automation-id"]: "openNewFormaButton",
            title: this.props.translate("APP.COMMANDBAR.OPEN_NEWFORMA_WEB_APP_TOOLTIP") as string,
            href: this.props.configService.webAppURL,
            target: "_blank",
        };
    }

    private customerSupportItem(): IContextualMenuItem {
        return {
            key: "customerSupport",
            name: this.props.translate("APP.COMMANDBAR.CUSTOMER_SUPPORT") as string,
            iconProps: {
                iconName: "NewMail",
            },
            onClick: this.onCustomerSupportClicked.bind(this),
            target: "_blank",
        };
    }

    private eulaMenuItem(): IContextualMenuItem {
        return {
            key: "eula",
            name: this.props.translate("APP.COMMANDBAR.EULA") as string,
            iconProps: {
                iconName: "EntitlementRedemption",
            },
            href: this.props.translate("URLS.EULA") as string,
            target: "_blank",
            onClick: this.recordAnalyticsForUserAction.bind(this, AnalyticsActionType.UserMenuEula),
        };
    }

    private dividerMenuItem(): IContextualMenuItem {
        return {
            key: "divider",
            itemType: ContextualMenuItemType.Divider,
        };
    }

    private settingsMenuItem(): IContextualMenuItem {
        return {
            key: "settings",
            name: this.props.translate("APP.COMMANDBAR.SETTINGS") as string,
            iconProps: {
                iconName: "Settings",
            },
            onClick: this.openSettingsClicked.bind(this),
        };
    }

    private revisionMenuItem(): IContextualMenuItem {
        return {
            key: "revision",
            name: `${this.props.translate("APP.COMMANDBAR.REVISION")}: ${__COMMIT_HASH__}`,
        };
    }

    private branchMenuItem(): IContextualMenuItem {
        return {
            key: "branch",
            name: `${this.props.translate("APP.COMMANDBAR.BRANCH")}: ${__BRANCH__}`,
        };
    }

    private versionMenuItem(): IContextualMenuItem {
        return {
            key: "version",
            name: `${this.props.translate("APP.COMMANDBAR.VERSION")}: ${this.props.configService.version}`,
        };
    }

    private logOutMenuItem(): IContextualMenuItem {
        return {
            key: "logOut",
            name: this.props.translate("APP.COMMANDBAR.SIGN_OUT") as string,
            iconProps: {
                iconName: "Unlock",
            },
            onClick: this.signOutClicked.bind(this) as () => void,
        };
    }

    private helpMenuItem(): IContextualMenuItem {
        return {
            key: "help",
            name: this.props.translate("APP.COMMANDBAR.HELP") as string,
            iconProps: {
                iconName: "Help",
            },
            href: this.props.translate("URLS.HELP") as string,
            target: "_blank",
            onClick: this.recordAnalyticsForUserAction.bind(this, AnalyticsActionType.UserMenuHelp),
        };
    }

    private getOptionsMenuItemsUnauthenticated(): IContextualMenuItem[] {
        const menuItems: IContextualMenuItem[] = [];

        menuItems.push(this.openNewformaItem());
        menuItems.push(this.customerSupportItem());
        if (this.props.isEulaSupported) {
            menuItems.push(this.eulaMenuItem());
        }
        menuItems.push(this.dividerMenuItem());
        menuItems.push(this.versionMenuItem());

        if (__ENV__ !== "prod") {
            menuItems.push(this.branchMenuItem());
            menuItems.push(this.revisionMenuItem());
        }

        return menuItems;
    }

    private getOptionsMenuItemsAuthenticated(): IContextualMenuItem[] {
        const menuItems: IContextualMenuItem[] = [];

        menuItems.push(this.openNewformaItem());
        menuItems.push(this.logOutMenuItem());
        menuItems.push(this.dividerMenuItem());
        menuItems.push(this.settingsMenuItem());
        menuItems.push(this.openFeedbackMenuItem());
        menuItems.push(this.customerSupportItem());
        if (this.props.isEulaSupported) {
            menuItems.push(this.eulaMenuItem());
        }
        menuItems.push(this.helpMenuItem());
        menuItems.push(this.dividerMenuItem());
        menuItems.push(this.versionMenuItem());

        if (__ENV__ !== "prod") {
            menuItems.push(this.branchMenuItem());
            menuItems.push(this.revisionMenuItem());
        }

        return menuItems;
    }

    private recordAnalyticsForUserAction(action: AnalyticsActionType): void {
        this.props.analyticsManager.recordEvent(AnalyticsCategoryType.UserActions, action);
    }

    private getFarItems(): ICommandBarItemProps[] {
        const commandBarProps = [];
        if (this.props.loggedIn) {
            commandBarProps.push(this.openSearchItem());
            commandBarProps.push(this.openProjectTeamMenuItem());
            this.props.showFileTransferButton && this.props.isFileTransferAndEditorSupported
                ? commandBarProps.push(this.openFileTransfersItem())
                : null;
        } else {
            commandBarProps.push(this.openFeedbackMenuItem());
        }
        commandBarProps.push(this.getOverflowMenuItem());
        return commandBarProps;
    }

    private styles: Partial<ITeachingBubbleStyles> = {
        footer: {
            display: "flex",
            flexDirection: "row-reverse",
            [`& > .ms-StackItem > span`]: {
                marginRight: "75px",
            },
            color: this.props.theme === "default" ? "white" : "black",
        },
        content: {
            color: this.props.theme === "default" ? "white" : "black",
        },
        subText: {
            color: this.props.theme === "default" ? "white" : "black",
        },
        headline: {
            color: this.props.theme === "default" ? "white" : "black",
        },
        closeButton: {
            paddingBottom: "2.5px",
            [`.ms-Icon`]: {
                color: this.props.theme === "default" ? "white" : "black",
            },
        },
        root: {
            marginRight: "15px",
        },
        subComponentStyles: {
            callout: {
                beak: {
                    marginRight: "-15px",
                },
            },
        },
    };

    private teachingBubbleButtonProps(buttonText: string): IButtonProps {
        return {
            children: buttonText,
            onClick: () => this.props.onDismissTeachingBubble(),
        };
    }

    private renderFilesTransferTeachingBubble(): JSX.Element | undefined {
        return (
            <TeachingBubble
                calloutProps={{ directionalHint: DirectionalHint.bottomLeftEdge }}
                target=".fileTransfersItem"
                isWide={false}
                hasCloseButton={true}
                onDismiss={() => this.props.onDismissTeachingBubble()}
                primaryButtonProps={this.teachingBubbleButtonProps(
                    this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_3") as string
                )}
                closeButtonAriaLabel={this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_3") as string}
                headline={this.props.translate("TEACHING_BUBBLES_v1.FILE_TRANSFER_BUBBLE.PART_1") as string}
                styles={this.styles}
                ignoreExternalFocusing={true}
            >
                <span className="TeachingBubble-h-2">
                    {this.props.translate("TEACHING_BUBBLES_v1.FILE_TRANSFER_BUBBLE.PART_2") as string}
                </span>
            </TeachingBubble>
        );
    }

    renderTextFromFileTransferComp(toastText: string, toastType: MessageBarType) {
        this.setState({ toastText: toastText, toastType: toastType });
        this.props.onShowToast(toastText, toastType);
    }

    onDismissAndClearToastMessageAndType() {
        this.setState({ toastText: "", toastType: undefined });
        this.props.onDismissToast();
    }

    render(): JSX.Element {
        return (
            <div id="AppHeader" className="newforma-appHeader">
                <CommandBar items={[]} farItems={this.getFarItems()} className="newforma-commandBar" />
                <SettingsComponent
                    onDismiss={this.closeSettingsClicked.bind(this)}
                    showSettingsPanel={this.state.showSettingsPanel}
                    officeRoamingSettings={this.props.officeRoamingSettings}
                    officeWrapper={this.props.officeWrapper}
                    logger={this.props.logger}
                    onSettingsChanged={this.props.onSettingsChanged}
                    analyticsManager={this.props.analyticsManager}
                    currentTheme={this.props.theme}
                    storageWrapper={this.props.storageWrapper}
                    invalidateCacheService={this.props.invalidateCacheService}
                    onShowToast={this.props.onShowToast}
                    onDismissToastMessage={this.onDismissAndClearToastMessageAndType.bind(this)}
                    toastMessage={this.state.toastText}
                    toastType={this.state.toastType}
                />
                <SearchComponent
                    onDismiss={this.closeSearchClicked.bind(this)}
                    showSearchPanel={this.state.showSearchPanel}
                    logger={this.props.logger}
                    smartFilingManager={this.props.smartFilingManager}
                    mailboxItem={this.props.mailboxItem}
                    projectsService={this.props.projectsService}
                    configService={this.props.configService}
                    isLoggedIn={this.props.loggedIn}
                    onExpiredSession={this.props.onExpiredSession}
                    analyticsManager={this.props.analyticsManager}
                    theme={this.props.theme}
                    onShowToast={this.props.onShowToast}
                    onDismissToastMessage={this.onDismissAndClearToastMessageAndType.bind(this)}
                    toastMessage={this.state.toastText}
                    toastType={this.state.toastType}
                />
                <ProjectTeamComponent
                    logger={this.props.logger}
                    showPanel={this.state.showProjectTeamPanel}
                    onDismiss={this.closeProjectTeamMembersClicked.bind(this)}
                    projectsService={this.props.projectsService}
                    smartFilingManager={this.props.smartFilingManager}
                    officeWrapper={this.props.officeWrapper}
                    mailBoxItem={this.props.mailboxItem}
                    onExpiredSession={this.props.onExpiredSession}
                    sendAndFileHelpers={this.props.sendAndFileHelpers}
                    analyticsManager={this.props.analyticsManager}
                    onShowToast={this.props.onShowToast}
                    theme={this.props.theme}
                />

                <FileTransferComponent
                    logger={this.props.logger}
                    onDismiss={this.closeFileTransferClicked.bind(this)}
                    showPanel={this.state.showFileTransferPanel && this.props.shouldShowFileTransferView}
                    onExpiredSession={this.props.onExpiredSession}
                    smartFilingManager={this.props.smartFilingManager}
                    officeWrapper={this.props.officeWrapper}
                    formValidationHelpers={this.props.formValidationHelpers}
                    projectsService={this.props.projectsService}
                    mailboxItem={this.props.officeWrapper.currentContextItem}
                    analyticsManager={this.props.analyticsManager}
                    nrnServiceWrapper={this.props.nrnServiceWrapper}
                    msGraphApiService={this.props.msGraphApiService}
                    theme={this.props.theme}
                    dateHelpers={this.props.dateHelpers}
                    fileTransferApiService={this.props.fileTransferApiService}
                    invalidateCacheService={this.props.invalidateCacheService}
                    fileTransferService={this.props.fileTransferApiService}
                    outlookApiService={this.props.outlookApiService}
                    storageWrapper={this.props.storageWrapper}
                    isFilePathInAttachmentsSupported={this.props.isFilePathInAttachmentsSupported}
                    changeAppHeaderText={this.renderTextFromFileTransferComp.bind(this)}
                    onDismissToastMessage={this.onDismissAndClearToastMessageAndType.bind(this)}
                    appToastMessage={this.state.toastText}
                    appToastType={this.state.toastType}
                    isFileTransferAndEditorSupported={this.props.isFileTransferAndEditorSupported}
                    configService={this.props.configService}
                />

                {this.props.showFilesTransferTeachingBubble &&
                    !this.props.shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache &&
                    this.props.isFileTransferAndEditorSupported &&
                    this.renderFilesTransferTeachingBubble()}
            </div>
        );
    }
}

export default withLocalize(AppHeaderComponent);

import "./TeamMemberRowComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { TeamMemberNormalized } from "../../../models/projectTeam/TeamViewResponse";
import { Checkbox } from "office-ui-fabric-react";

export interface TeamMemberRowComponentProps extends LocalizeContextProps {
    className?: string;
    isSelected: boolean;
    teamMember: TeamMemberNormalized;
    onSelectionChange: (id: string, checked: boolean) => void;
    disabled?: boolean;
}

export interface TeamMemberRowComponentState {
    isSelected: boolean;
}

export const defaultTeamMemberRowComponentState: TeamMemberRowComponentState = {
    isSelected: false,
};

class TeamMemberRowComponent extends React.Component<TeamMemberRowComponentProps, TeamMemberRowComponentState> {
    constructor(props: TeamMemberRowComponentProps, context: TeamMemberRowComponentState) {
        super(props, context);

        this.state = { ...defaultTeamMemberRowComponentState, isSelected: this.props.isSelected };
    }

    componentDidUpdate(
        prevProps: Readonly<TeamMemberRowComponentProps>,
        prevState: Readonly<TeamMemberRowComponentState>,
        snapshot?: any
    ) {
        if (prevProps.isSelected !== this.props.isSelected) {
            this.setState({ isSelected: this.props.isSelected });
        }
    }

    private onSelectionChange(event?: React.FormEvent<HTMLElement>, checked?: boolean) {
        if (checked === undefined || checked === null) {
            return;
        }
        this.setState({ isSelected: checked });
        this.props.onSelectionChange(this.props.teamMember.nrn, checked);
    }

    private displayName(): string {
        const discipline = this.props.teamMember.discipline ? ` (${this.props.teamMember.discipline})` : "";
        return `${this.props.teamMember.displayName}${discipline}`;
    }

    render(): JSX.Element {
        return (
            <div
                className={`newforma-teamMemberRowComponent ${
                    this.state.isSelected ? "newforma-attachmentSelected" : ""
                } ${this.props.className || ""}`}
            >
                <div className="newforma-checkboxContainer">
                    <Checkbox
                        className="newforma-check"
                        checked={this.state.isSelected}
                        onChange={this.onSelectionChange.bind(this)}
                        disabled={this.props.disabled}
                    />
                </div>
                <div className="newforma-teamMemberRowDetails">
                    <div className="newforma-projectTeamDisplayName">{this.displayName()}</div>
                    <div className="newforma-projectTeamEmail">{this.props.teamMember.email}</div>
                </div>
            </div>
        );
    }
}

export default withLocalize(TeamMemberRowComponent);

import LoginDialog from "../components/login/LoginDialog";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "./AnalyticsManager";
import { AwsCredentials } from "../models/AwsCredentials";
import { RegionsResponse, UnauthenticatedNewformaApiClient } from "./UnauthenticatedNewformaApiClient";
import { AuthUrlProvider } from "./AuthUrlProvider";
import { OfficeWrapper } from "./OfficeWrapper";
import { IdpConfiguration } from "../models/IdpConfiguration";
import { StorageWrapper } from "./StorageWrapper";
import { LocalStorageKeys } from "../models/StorageKeys";
import { Eula } from "../models/EulaModels";
import { OpenIDCredentials } from "../models/OpenIDCredentials";
import { Logger } from "./Logger";
import { ExpiredSessionError } from "../models/ExpiredSessionError";

export class Authenticator {
    onEulaRequired: ((isUpdate: boolean) => Promise<Eula | undefined>) | undefined;
    onAuthenticationError: ((errorMessage: string) => Promise<void>) | undefined;
    private refreshCredentialsPromise: Promise<AwsCredentials | null> | null = null;

    constructor(
        private loginDialog: LoginDialog,
        private storageWrapper: StorageWrapper,
        private apiClient: UnauthenticatedNewformaApiClient,
        private authUrlProvider: AuthUrlProvider,
        private officeWrapper: OfficeWrapper,
        private analyticsManager: AnalyticsManager,
        private logger: Logger
    ) {}

    getRegions(twoLettersLanguage: string): Promise<RegionsResponse> {
        return this.apiClient.getRegions(twoLettersLanguage);
    }

    getStoredCredentials(): AwsCredentials | null {
        const credentials = this.storageWrapper.loadLocalStorage(LocalStorageKeys.awsCredentials);
        if (credentials) {
            return JSON.parse(credentials);
        }
        return null;
    }

    isUserLoggedIn(): boolean {
        return !!this.getStoredCredentials();
    }

    isValidUser(): boolean {
        const savedUser = this.storageWrapper.loadLocalStorage(LocalStorageKeys.currentLoggedEmail);
        if (savedUser !== this.officeWrapper.userProfileEmailAddress) {
            this.logger.warning(
                `User is invalid. Current user ${this.officeWrapper.userProfileEmailAddress}. Saved user ${savedUser}`
            );
            return false;
        }
        return true;
    }

    logoutFromApp(): void {
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.awsCredentials);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.idToken);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.currentLoggedEmail);
        this.storageWrapper.removeLocalStorageItem(LocalStorageKeys.graphToken);
    }

    async getOpenIdCredentials(config: IdpConfiguration): Promise<OpenIDCredentials> {
        const authorizeUrl = await this.authUrlProvider.getAuthorizeUrl(config);
        const openIdCredentials = await this.loginDialog.openAuthDialog(authorizeUrl, false);
        this.storageWrapper.saveLocalStorage(LocalStorageKeys.idToken, openIdCredentials.idToken);
        return openIdCredentials;
    }

    async handleLogin(
        openIdCredentials: OpenIDCredentials,
        config: IdpConfiguration,
        eula: Eula | undefined,
        region?: string
    ): Promise<AwsCredentials> {
        const credentials = await this.apiClient.login(openIdCredentials, eula, region);
        this.storageWrapper.saveLocalStorage(LocalStorageKeys.awsCredentials, JSON.stringify(credentials));
        this.storageWrapper.saveLocalStorage(
            LocalStorageKeys.currentLoggedEmail,
            this.officeWrapper.userProfileEmailAddress
        );

        const token = this.storageWrapper.loadLocalStorage(LocalStorageKeys.idToken);
        if (!token) {
            if (this.onAuthenticationError) {
                await this.onAuthenticationError("AUTHENTICATION.COOKIE_ERROR");
            }
            this.logger.error(
                "Authenticator could not access local storage. Cookies MUST be enabled for this application to work."
            );
            console.error("Could not access local storage. Cookies MUST be enabled for this application to work.");
        }

        const loggedInUsersCompany = this.officeWrapper.userProfileEmailDomain;
        this.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserData,
            AnalyticsActionType.Company,
            loggedInUsersCompany
        );
        this.analyticsManager.recordEvent(AnalyticsCategoryType.UserActions, AnalyticsActionType.Login);
        return credentials;
    }

    async refreshCredentials(): Promise<AwsCredentials | null> {
        if (this.refreshCredentialsPromise) {
            return this.refreshCredentialsPromise;
        }
        this.logger.info(`Authenticator: In refreshCredentials`);

        try {
            this.refreshCredentialsPromise = this.createRefreshCredentialsPromise();
            return await this.refreshCredentialsPromise;
        } catch (error) {
            this.logoutFromApp();
            this.logger.error(`Authenticator: In refreshCredentials: ${error}`);
            throw new ExpiredSessionError();
        } finally {
            this.refreshCredentialsPromise = null;
        }
    }

    private async createRefreshCredentialsPromise(): Promise<AwsCredentials | null> {
        this.logger.info(`Authenticator: In createRefreshCredentialsPromise`);
        const refreshUrl = await this.authUrlProvider.getRefreshUrl();
        this.logger.info(`Authenticator: In createRefreshCredentialsPromise, refreshUrl: ${refreshUrl}`);

        let authDialogResult;
        let credentials = null;
        try {
            authDialogResult = await this.loginDialog.refreshToken(refreshUrl);
            this.logger.info(`Authenticator: authDialogResult: ${authDialogResult}`);
            this.storageWrapper.saveLocalStorage(LocalStorageKeys.idToken, authDialogResult.idToken);
            try {
                credentials = await this.apiClient.login(authDialogResult, undefined);
            } catch (error) {
                const errorType = (error as any).responseJSON?.type;
                if (this.onEulaRequired && (error as any).status === 400 && errorType?.includes("bad_request#eula_")) {
                    const isEulaUpdate = errorType.includes("bad_request#eula_update_confirmation_required");
                    const eula = await this.onEulaRequired(isEulaUpdate);
                    const afterEulaAuthDialogResult = await this.loginDialog.refreshToken(refreshUrl);
                    credentials = await this.apiClient.login(afterEulaAuthDialogResult, eula);
                } else {
                    throw error;
                }
            }
        } catch (error) {
            this.logger.error(`Authenticator: errored when receiving authDialogResult`);
            throw error;
        }

        this.storageWrapper.saveLocalStorage(LocalStorageKeys.awsCredentials, JSON.stringify(credentials));

        return credentials;
    }
}

import { LocalStorageKeys } from "../models/StorageKeys";
import { StorageWrapper } from "./StorageWrapper";
import { MsGraphToken } from "../models/MsGraphToken";
import { NewformaApiClient } from "./NewformaApi/NewformaApiClient";
import { HttpRequestWrapper } from "./HttpRequestWrapper";
import { Request } from "aws-sign-web";
import { Logger } from "./Logger";

export class MsGraphTokenService {
    constructor(
        private storageWrapper: StorageWrapper,
        private newformaApiClient: NewformaApiClient,
        private httpRequestWrapper: HttpRequestWrapper,
        private logger: Logger
    ) {}

    async getGraphToken(): Promise<string> {
        let graphToken = this.getStoredGraphToken();
        if (!graphToken) {
            graphToken = await this.getNewGraphTokenFromAPI();
        }
        return graphToken;
    }

    private getStoredGraphToken(): string | null {
        return this.storageWrapper.loadLocalStorage(LocalStorageKeys.graphToken);
    }

    async getNewGraphTokenFromAPI(): Promise<string> {
        this.logger.info("retrieving new MS Graph API access token");
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/me/token`;

        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        const result: MsGraphToken = await this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.httpRequestWrapper.get(signedOptions.url, undefined, signedOptions.headers, undefined)
        );

        this.storageWrapper.saveLocalStorage(LocalStorageKeys.graphToken, result.accessToken);
        return result.accessToken;
    }
}

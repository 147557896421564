import * as React from "react";
import "./AttachmentsComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Label, Icon, MessageBar, MessageBarType } from "office-ui-fabric-react";
import AttachmentListComponent from "./attachmentList/AttachmentListComponent";
import Dropzone, { DropEvent } from "react-dropzone";
import { AttachmentItem } from "../../../models/shared/AttachmentList";
import AttachmentDetails = Office.AttachmentDetails;
import { Logger } from "../../../services/Logger";

export interface AttachmentsComponentProps extends LocalizeContextProps {
    attachments: AttachmentItem[];
    logger: Logger;
    disabled: boolean;
    onAttachmentsUpdated: (attachments: AttachmentItem[]) => void;
    allowFileUploads?: boolean;
    isCloudProject?: boolean;
    required?: boolean;
    isFilePathInAttachmentsSupported?: boolean;
    isFileTransferAndEditorSupported?: boolean;
    classNameForScroll?: string;
    updateMoreOptions?: () => void;
    expandMoreOptions?: () => void;
}

export interface AttachmentsComponentState {
    uploadError: string | null;
    blockedError: string | null;
    shouldDisplayDropzone: boolean;
}

export const pathSeparator: string = "\\";

class AttachmentsComponent extends React.Component<AttachmentsComponentProps, AttachmentsComponentState> {
    constructor(props: AttachmentsComponentProps, context: AttachmentsComponentState) {
        super(props, context);

        this.state = {
            uploadError: null,
            blockedError: null,
            shouldDisplayDropzone: true,
        };
    }

    async componentDidUpdate(
        prevProps: Readonly<AttachmentsComponentProps>,
        prevState: Readonly<AttachmentsComponentState>,
        snapshot?: any
    ) {
        if (
            ((this.state.blockedError && prevState.blockedError !== this.state.blockedError) ||
                (this.state.uploadError && prevState.uploadError !== this.state.uploadError)) &&
            this.props.classNameForScroll
        ) {
            // this is for collapsing moreOptions if there is an inline error in attachment component
            if (this.props.updateMoreOptions) {
                this.props.updateMoreOptions();
            }
            document.querySelector(this.props.classNameForScroll)?.scrollTo({
                top: document.querySelector(this.props.classNameForScroll)?.scrollHeight,
                behavior: "auto",
            });
        }
    }

    private filterExcludedFiles<T extends { path: string }>(files: T[]): T[] {
        const excludedFilePatterns = [
            // # npm
            "^npm-debug\\.log$",
            "^\\..*\\.swp$",

            // # macOS
            "^\\.DS_Store$",
            "^\\.AppleDouble$",
            "^\\.LSOverride$",
            "^Icon\\r$",
            "^\\._.*",
            "^\\.Spotlight-V100(?:$|\\/)",
            "\\.Trashes",
            "^__MACOSX$",

            // # Linux
            "~$",

            // # Windows
            "^Thumbs\\.db$",
            "^ehthumbs\\.db$",
            "^[Dd]esktop\\.ini$",
            "@eaDir$",
        ];

        const isExcludedFile = (file: { path: string }): boolean =>
            excludedFilePatterns.some((pattern) => new RegExp(pattern).test(file.path));

        return files.filter((file) => !isExcludedFile(file));
    }

    convertFilePathToCorrectFormat(filePathInWrongFormat: string): string {
        // remove first \ if it exists and convert file path to correct format for NewformaLink request replacing \ with //
        return filePathInWrongFormat.startsWith("/")
            ? filePathInWrongFormat.slice(1).replace(/\//g, pathSeparator)
            : filePathInWrongFormat.replace(/\//g, pathSeparator);
    }

    isAtParentLevelOfHierarchy(): void {
        this.setState({ shouldDisplayDropzone: false });
    }

    isAtRootLevel(): void {
        this.setState({ shouldDisplayDropzone: true });
    }

    onDropAccepted(files: (File & { path?: string })[], event: DropEvent): void {
        // this is for collapsing moreOptions if there is an inline error in attachment component - need to reset state value in FileTransferComponent
        if (this.props.expandMoreOptions) {
            this.props.expandMoreOptions();
        }

        if (!files.length) {
            return;
        }
        try {
            const filesWithConvertedPaths = files.map((file) => ({
                file: file,
                path: this.convertFilePathToCorrectFormat(file.path ?? file.name),
            }));
            const existingPaths = this.props.attachments
                .map((x) => x.id.toLowerCase())
                .concat(
                    this.props.attachments
                        .filter(
                            (att: AttachmentItem): att is AttachmentDetails =>
                                !!(att as AttachmentDetails).attachmentType
                        )
                        .map((x) => x.name.toLowerCase())
                );
            if (!this.props.isFilePathInAttachmentsSupported && this.isFolderAdded(filesWithConvertedPaths)) {
                this.setState({
                    uploadError: this.props.translate(
                        "SHARED.ATTACHMENTS.ERROR_MESSAGE_FOR_FOLDERS_AND_OLD_NL"
                    ) as string,
                });
                return;
            }
            if (this.isAttachmentDuplicated(filesWithConvertedPaths, existingPaths)) {
                this.setState({ uploadError: this.props.translate("SHARED.ATTACHMENTS.DUPLICATE_NAME") as string });
            } else {
                const validFiles = this.filterExcludedFiles(filesWithConvertedPaths);
                const updatingFiles = validFiles.map((item) =>
                    this.props.isFileTransferAndEditorSupported
                        ? {
                              file: item.file,
                              id: item.path,
                              name: item.file.name,
                              lastModifiedDate: new Date(item.file.lastModified),
                          }
                        : {
                              file: item.file,
                              id: item.path,
                              name: item.file.name,
                          }
                );
                const totalCountOfAttachments = this.props.attachments.length + updatingFiles.length;
                if (this.isTooManyFiles(totalCountOfAttachments)) {
                    this.setState({ uploadError: this.props.translate("SHARED.ATTACHMENTS.MAXIMUM_FILES") as string });
                } else {
                    this.props.onAttachmentsUpdated(this.props.attachments.concat(updatingFiles));
                }
            }
        } catch {
            this.setState({ uploadError: this.props.translate("SHARED.ATTACHMENTS.ERROR") as string });
        }
    }

    private isTooManyFiles(fileNumber: number) {
        return fileNumber > 25;
    }

    private isAttachmentDuplicated(paths: { path: string }[], existingPaths: string[]): boolean {
        if (paths.some((x) => existingPaths.includes(x.path.toLowerCase()))) {
            return true;
        }
        const pathsWithoutFilenames = paths.map((x) => this.getPathWithoutFilename(x.path));
        const existingPathsWithoutFilenames = existingPaths.map((x) => this.getPathWithoutFilename(x));
        if (pathsWithoutFilenames.some((x) => existingPathsWithoutFilenames.includes(x.toLowerCase()))) {
            return true;
        }

        return false;
    }

    private isFolderAdded(paths: { path: string }[]): boolean {
        return paths.some((file) => file.path.includes("\\"));
    }

    private getPathWithoutFilename(path: string): string {
        const lastPathSeparatorIndex = path.lastIndexOf(pathSeparator);
        return lastPathSeparatorIndex === -1 ? path : path.slice(0, lastPathSeparatorIndex);
    }

    private onAttachmentRemove(files: AttachmentItem[]): void {
        this.setState({ uploadError: null, blockedError: null });
        if (files.length === 0) {
            return;
        }
        const filteredAttachments = this.props.attachments.filter(
            (attachment) => files.map((item) => item.id).indexOf(attachment?.id) === -1
        );
        this.props.onAttachmentsUpdated(filteredAttachments);
    }

    onAttachmentsClear(): void {
        this.props.onAttachmentsUpdated([]);
        this.setState({ uploadError: null, blockedError: null, shouldDisplayDropzone: true });
    }
    private onMessageDismissed(): void {
        this.setState({ uploadError: null, blockedError: null });
    }

    private onDrop = (acceptedFiles: File[], fileRejections: any[]): void => {
        if (acceptedFiles.length > 0 && fileRejections.length === 0) {
            this.setState({ uploadError: null, blockedError: null });
        }
    };

    render(): JSX.Element {
        return (
            <>
                {this.props.allowFileUploads ? (
                    <>
                        <div className="newforma-filesContainer newforma-aiSpacing">
                            <Label>
                                {this.props.translate("SHARED.ATTACHMENTS.FILES") as string}
                                {this.props.required ? <span className="newforma-required"> *</span> : null}
                            </Label>
                        </div>
                        {this.state.shouldDisplayDropzone || this.props.attachments.length === 0 ? (
                            <Dropzone
                                onDrop={this.onDrop}
                                onDropAccepted={this.onDropAccepted.bind(this)}
                                onDropRejected={() => {
                                    this.setState({
                                        blockedError: this.props.translate("SHARED.ATTACHMENTS.BLOCKED") as string,
                                    });
                                }}
                                multiple={true}
                                noClick={false}
                                noDrag={false}
                                noKeyboard={true}
                                minSize={1}
                                preventDropOnDocument={true}
                                disabled={this.props.disabled}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "newforma-dropzone" })}>
                                        <input {...getInputProps()} />
                                        <p className="newforma-dropzoneHint">
                                            <>
                                                <span className="dragAndDropHint hintOne">
                                                    {
                                                        this.props.translate(
                                                            "SHARED.ATTACHMENTS.DRAG_AND_DROP_HINT_1"
                                                        ) as string
                                                    }
                                                </span>
                                                <span className="dragAndDropHint hintTwo">
                                                    {
                                                        this.props.translate(
                                                            "SHARED.ATTACHMENTS.DRAG_AND_DROP_HINT_2"
                                                        ) as string
                                                    }
                                                </span>
                                                <span className="dragAndDropHint icon">
                                                    <Icon iconName="Download" className="fabric-UI-logo-icon" />
                                                </span>
                                                <span className="dragAndDropHint hintThree">
                                                    {this.props.isFilePathInAttachmentsSupported
                                                        ? (this.props.translate(
                                                              "SHARED.ATTACHMENTS.DRAG_AND_DROP_HINT_3"
                                                          ) as string)
                                                        : (this.props.translate(
                                                              "SHARED.ATTACHMENTS.DRAG_AND_DROP_HINT_3_OLDER_NL"
                                                          ) as string)}
                                                </span>
                                            </>
                                        </p>
                                    </div>
                                )}
                            </Dropzone>
                        ) : null}
                        {this.state.uploadError ? (
                            <MessageBar
                                messageBarType={MessageBarType.severeWarning}
                                className="errorMessageBar"
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                onDismiss={this.onMessageDismissed.bind(this)}
                            >
                                {this.state.uploadError}
                            </MessageBar>
                        ) : null}
                        {this.state.blockedError ? (
                            <MessageBar
                                messageBarType={MessageBarType.blocked}
                                className="blockedMessageBar"
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                onDismiss={this.onMessageDismissed.bind(this)}
                            >
                                {this.state.blockedError}
                            </MessageBar>
                        ) : null}
                    </>
                ) : null}
                {this.props.attachments.length ? (
                    <div className="newforma-attachmentsContainer newforma-aiSpacing">
                        <AttachmentListComponent
                            attachmentsItems={this.props.attachments}
                            disabled={this.props.disabled}
                            onItemRemove={this.onAttachmentRemove.bind(this)}
                            onClear={this.onAttachmentsClear.bind(this)}
                            logger={this.props.logger}
                            onMoveIntoSubFolder={this.isAtParentLevelOfHierarchy.bind(this)}
                            onReturnToRoot={this.isAtRootLevel.bind(this)}
                        />
                    </div>
                ) : null}
            </>
        );
    }
}

export default withLocalize(AttachmentsComponent);

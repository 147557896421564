import { Nrns } from "@newforma/npm-nrn";

export class NrnServiceWrapper {
    private readonly actionItemsSpecialFolder = "fieldnotes";

    // destination nrn for action item supporting document uploads
    getActionItemDestinationFolderNrn(actionItemNrn: string): string {
        const nrnParts: Map<string, any> = Nrns.parseNrn(actionItemNrn).arguments;
        nrnParts.delete(Nrns.defaultSchemaArguments.actionItemId);
        nrnParts.set(Nrns.defaultSchemaArguments.specialFolderType, this.actionItemsSpecialFolder);
        return Nrns.create(Nrns.defaultSchemaPrefixes.folderSpecialNPC, nrnParts);
    }

    isCloudProject(projectNrn: string): boolean {
        return Nrns.isValidNrnWithPrefix(projectNrn, Nrns.defaultSchemaPrefixes.projectCloud);
    }
}

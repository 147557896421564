import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { DefaultButton, Dropdown, IDropdownOption, ResponsiveMode } from "office-ui-fabric-react";
import { Region } from "../../services/UnauthenticatedNewformaApiClient";
import "./RegionSelectorComponent.less";

export interface RegionSelectorComponentProps extends LocalizeContextProps {
    regions: Region[];
    onRegionSelected: (region: string) => void;
}

export interface RegionSelectorComponentState {
    selectedRegion: IDropdownOption | null;
}

class RegionSelectorComponent extends React.Component<RegionSelectorComponentProps, RegionSelectorComponentState> {
    constructor(props: RegionSelectorComponentProps, context: RegionSelectorComponentState) {
        super(props, context);

        this.state = { selectedRegion: null };
    }

    private loadRegions(): IDropdownOption[] {
        return this.props.regions.map((x) => ({ key: x.newforma_region, text: x.name }));
    }

    private onRegionChange(event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void {
        this.setState({ selectedRegion: item || null });
    }

    private onContinue(): void {
        if (this.state.selectedRegion) {
            this.props.onRegionSelected(this.state.selectedRegion.key as string);
        }
    }

    render(): JSX.Element {
        return (
            <div className="newforma-regionSelectorComponent">
                <Dropdown
                    className="newforma-regionSelectDropdown"
                    label={this.props.translate("AUTHENTICATION.SELECT_REGION_DROPDOWN_LABEL") as string}
                    placeholder={this.props.translate("AUTHENTICATION.SELECT_REGION_DROPDOWN_PLACEHOLDER") as string}
                    options={this.loadRegions()}
                    onChange={this.onRegionChange.bind(this)}
                    responsiveMode={ResponsiveMode.large}
                />
                <DefaultButton
                    className="newforma-importantActionButton"
                    disabled={!this.state.selectedRegion}
                    onClick={this.onContinue.bind(this)}
                    text={this.props.translate("AUTHENTICATION.SELECT_REGION_BUTTON_OK") as string}
                />
            </div>
        );
    }
}

export default withLocalize(RegionSelectorComponent);

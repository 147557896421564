import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import AppProjectListComponent from "../projectList/AppProjectListComponent";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import { ITag, Label, MessageBarType } from "office-ui-fabric-react";
import { SuggestedProject } from "../../services/SmartFiling/SuggestedProject";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import { OfficeNotificationService } from "../../services/officeUi/OfficeNotificationService";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import * as uuid from "uuid";
import { MailboxItem, OfficeWrapper } from "../../services/OfficeWrapper";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../services/AnalyticsManager";
import InfoComponent from "../shared/info/InfoComponent";
import { InternetHeader } from "../../models/InternetHeader";
import "./SendAndFileComponent.less";
import { Logger } from "../../services/Logger";
import { SendAndFileHelpers } from "../../helpers/SendAndFile/SendAndFileHelpers";
import { AppPage } from "../shared/navigationHeader/NavigationHeaderComponent";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { FilingLocation } from "../../models/FilingLocationsResponse";

export interface SendAndFileComponentProps extends LocalizeContextProps {
    officeRoamingSettings: OfficeRoamingSettings;
    smartFilingManager: SmartFilingManager;
    onExpiredSession: () => void;
    officeWrapper: OfficeWrapper;
    officeNotificationService: OfficeNotificationService;
    analyticsManager: AnalyticsManager;
    logger: Logger;
    sendAndFileHelpers: SendAndFileHelpers;
    onSetNavigationPage: (page: AppPage) => void;
    onShowToast: (toastMessage: string | null, toastType: MessageBarType) => void;
    mailboxItem: MailboxItem | null;
    emailApiService: EmailApiService;
    onComposeEmail: () => void;
}

export interface SendAndFileComponentState {
    isLoadingProjects: boolean;
    selectedProject: FilingLocation | null;
    projects: FilingLocation[];
    suggestedProjects: SuggestedProject[];
    lastSelectedProjectId: string | null;
    selectedProjectEmailAddress: string | null;
    emailSubject: string;
}

class SendAndFileComponent extends React.Component<SendAndFileComponentProps, SendAndFileComponentState> {
    private readonly requiredVersion = "1.8";
    private readonly featureIsSupported: boolean;

    constructor(props: SendAndFileComponentProps, context: SendAndFileComponentState) {
        super(props, context);
        this.state = {
            isLoadingProjects: true,
            selectedProject: null,
            projects: [],
            suggestedProjects: [],
            lastSelectedProjectId: null,
            emailSubject: "",
            selectedProjectEmailAddress: null,
        };
        this.featureIsSupported = props.officeWrapper.isMailboxVersionSupported(this.requiredVersion);
    }

    async componentDidMount(): Promise<void> {
        this.props.logger.info("SendAndFileComponent mounted");
        this.props.onSetNavigationPage(AppPage.SendAndFile);
        this.props.onComposeEmail();
        await Promise.all([this.loadSelectedProjectId(), this.loadProjects()]);
        const subject = await this.props.officeWrapper.getCurrentEmailSubject();
        this.setState({
            emailSubject: subject,
        });
    }

    private async loadProjects() {
        try {
            this.setState({ isLoadingProjects: true });

            const filingLocations = await this.props.emailApiService.getFilingLocations();
            this.setState({ projects: filingLocations.items });

            const tags: ITag[] = this.state.projects.map((x) => ({ key: x.nrn, name: x.name }));

            const suggestedProjects = await this.props.smartFilingManager.getSuggestedProjects(tags);
            this.setState({ suggestedProjects });
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
            } else {
                console.error(error);
                this.props.onShowToast(
                    this.props.translate("SEND_AND_FILE.ERROR") as string,
                    MessageBarType.severeWarning
                );
            }
        } finally {
            this.setState({ isLoadingProjects: false });
        }
    }

    private async loadSelectedProjectId(): Promise<void> {
        try {
            const lastSelectedProjectId = await this.props.officeWrapper.getInternetHeader(
                InternetHeader.FilingLocationNrn
            );
            this.setState({ lastSelectedProjectId: lastSelectedProjectId });
        } catch {
            this.setState({ lastSelectedProjectId: null });
        }
    }

    private async onProjectSelectionChanged(project: FilingLocation | null): Promise<void> {
        // check necessary as view reselects project when filtering
        if (project?.nrn === this.state.selectedProject?.nrn) {
            return;
        }
        this.setState({ selectedProject: project });
        if (!project) {
            this.props.onShowToast(null, MessageBarType.info);
        }

        try {
            await this.props.sendAndFileHelpers.updateRecipientsWithProjectEmailAddress(project);
            await this.markFile(project);
        } catch (error) {
            this.props.logger.error("SendAndFileComponent error", error);
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
            } else {
                console.error(error);
                this.props.onShowToast(
                    this.props.translate("SEND_AND_FILE.ERROR") as string,
                    MessageBarType.severeWarning
                );
            }
        }
    }

    private async markFile(selectedProject: FilingLocation | null): Promise<void> {
        const mailboxItem = this.props.mailboxItem;

        if (!mailboxItem) {
            return;
        }

        if (!selectedProject) {
            await this.props.officeWrapper.removeInternetHeaders([
                InternetHeader.EmailId,
                InternetHeader.FilingLocationNrn,
            ]);
            this.props.onShowToast(null, MessageBarType.info);
            return;
        }

        let emailId = await this.props.officeWrapper.getInternetHeader(InternetHeader.EmailId);

        const filingLocationNrn = selectedProject.nrn;
        const filingLocationName = selectedProject.name;
        const preExistingEmailId = !!emailId;

        if (!preExistingEmailId) {
            emailId = uuid();
        }

        const headers = {
            [InternetHeader.EmailId]: emailId,
            [InternetHeader.FilingLocationNrn]: filingLocationNrn,
        };

        await this.props.officeWrapper.setInternetHeaders(headers);

        if (!preExistingEmailId) {
            await this.props.emailApiService.markEmailForFiling(emailId as string);
            this.props.analyticsManager.recordEvent(
                AnalyticsCategoryType.UserActions,
                AnalyticsActionType.EmailMarkedForFiling
            );
        }

        const suggestedProject = this.state.suggestedProjects.find(
            (candidate: FilingLocation) => candidate.nrn === selectedProject.nrn
        );
        const suggestedProjectIndex = suggestedProject
            ? this.state.suggestedProjects.indexOf(suggestedProject)
            : undefined;

        this.props.analyticsManager.recordSmartFilingEvents(suggestedProject, suggestedProjectIndex);

        await this.props.smartFilingManager.addToFiledHistory(
            { key: selectedProject.nrn, name: selectedProject.name },
            mailboxItem.conversationId,
            this.props.officeWrapper.userProfileEmailAddress
        );

        const successMessage = (this.props.translate("SEND_AND_FILE.MARKED_FOR_FILE_TOAST") as string).replace(
            /\[\[project-name]]/gi,
            filingLocationName
        );
        this.props.onShowToast(successMessage, MessageBarType.info);
    }

    private async onRefreshProjects(): Promise<void> {
        await Promise.all([this.loadSelectedProjectId(), this.loadProjects()]);
    }

    private renderContent(): JSX.Element {
        if (!this.featureIsSupported) {
            return (
                <InfoComponent
                    icon={"update-needed.svg"}
                    primaryMessage={this.props.translate("SEND_AND_FILE.VERSION_ERROR_PRIMARY") as string}
                    secondaryMessage={this.props.translate("SEND_AND_FILE.VERSION_ERROR_SECONDARY") as string}
                />
            );
        }
        const emailSubject = this.state.emailSubject ? (
            <Label className="newforma-SelectedEmailLabel">{this.state.emailSubject}</Label>
        ) : null;
        return (
            <>
                {emailSubject}
                <AppProjectListComponent
                    key="projectPickerSendAndFile"
                    id="projectPickerSendAndFile"
                    projects={this.state.projects}
                    suggestedProjects={this.state.suggestedProjects}
                    onProjectSelectionChanged={this.onProjectSelectionChanged.bind(this)}
                    isLoadingProjects={this.state.isLoadingProjects}
                    projectIdToSelect={this.state.lastSelectedProjectId}
                    autoSelectSuggestedProject={!!this.state.lastSelectedProjectId}
                    onRefresh={this.onRefreshProjects.bind(this)}
                />
            </>
        );
    }

    render(): JSX.Element {
        return (
            <div className="newforma-sendAndFileComponent">
                <div className="newforma-sendAndFileContainer">
                    <Label className="newforma-headerHint">
                        {this.props.translate("SEND_AND_FILE.HINT_TEXT") as string}
                    </Label>
                    {this.renderContent()}
                </div>
            </div>
        );
    }
}

export default withLocalize(SendAndFileComponent);

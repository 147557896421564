import { createTheme, ITheme } from "office-ui-fabric-react";

export const darkTheme: Partial<ITheme> = createTheme({
    palette: {
        themePrimary: "#75b6e7", // day selected to primary
        neutralPrimary: "#ffffff", // font/text color,
        themeSecondary: "#626262", // hover color
        white: "#ffffff", // text hover color
        neutralSecondary: "#ffffff", // changes the secondary texts
        neutralLighter: "rgba(255, 255, 255, 0.15)", // hover and selected date color in calender
        neutralDark: "#ffffff", // hover text color in calender
        black: "#000000",
        themeTertiary: "#ffffff", // hover for dropdowns
        themeLighterAlt: "", // spec section subtext
    },
    semanticColors: {
        bodyBackground: "#2d2d2d",
        bodyText: "#ffffff",
        disabledBackground: "#f4f4f4",
        disabledText: "#ffffff",
        focusBorder: "#1e1d1e",
        inputBackground: "#f8f8f8",
        inputBorder: "#c8c8c8",
        inputText: "#ffffff",
        menuBackground: "#2d2a2d",
        menuIcon: "#75b6e7", // change the menu icon color to blue
        menuItemBackgroundHovered: "#626262",
        menuItemTextHovered: "#ffffff",
        menuDivider: "#626262", // selected items hover color
        menuItemBackgroundPressed: "#626262",
        bodyDivider: "#626262", // border line color in setting menu
        bodySubtext: "#ffffff", // changes secondary text in detail lists
        inputPlaceholderText: "#a3a3a3",
        inputBackgroundCheckedHovered: "#5495c7",
    },
});

export const lightTheme = createTheme({
    palette: {
        themeSecondary: "",
        white: "",
        themeDarker: "",
        black: "",
        themeTertiary: "#000000", // hover for dropdowns
        themeLighterAlt: "#8a898a", // subtext gray color in white theme
    },
});

export const customThemes = {
    boxShadowDark:
        "inset 0 2px 1px rgba(255,255,255,0.75), 0 2.1px 2.2px -2px rgba(0, 0, 0, 0.13), 0 5.8px 6.1px -2px rgba(0, 0, 0, 0.139), 0 13.9px 14.8px -2px rgba(0, 0, 0, 0.142), 0 46px 49px -2px rgba(0, 0, 0, 0.15)",
    boxShadowLight: "rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px",
    borderDark: "1px solid #434243",
    borderLight: "none",
};

import { IDetailsList, Selection } from "office-ui-fabric-react";
import { RefObject } from "react";
import * as React from "react";
import { FilingLocation } from "../models/FilingLocationsResponse";

export class ProjectListItemSelector {
    private shouldNotToggleSelection: boolean = false;
    private _allProjectsSelection: Selection;
    private _suggestedProjectsSelection: Selection;

    private readonly _suggestedProjectsRef: RefObject<IDetailsList>;
    private readonly _allProjectsRef: RefObject<IDetailsList>;

    constructor(private callback: (selectedProject: FilingLocation) => void) {
        this._allProjectsSelection = this.initAllProjectsSelection();
        this._suggestedProjectsSelection = this.initSuggestedProjectsSelection();

        this._suggestedProjectsRef = React.createRef<IDetailsList>();
        this._allProjectsRef = React.createRef<IDetailsList>();
    }

    get suggestedProjectsSelection(): Selection {
        return this._suggestedProjectsSelection;
    }

    get allProjectsSelection(): Selection {
        return this._allProjectsSelection;
    }

    get allProjectsRef(): RefObject<IDetailsList> {
        return this._allProjectsRef;
    }

    get suggestedProjectsRef(): RefObject<IDetailsList> {
        return this._suggestedProjectsRef;
    }

    async handleSelectionChanged(currentSelection: Selection, previousSelection: Selection): Promise<void> {
        if (this.shouldNotToggleSelection) {
            return;
        }

        await this.clearIndices(previousSelection);
        const selection = currentSelection.getSelection();
        const selectedProject = (selection[0] as FilingLocation) || null;
        this.callback(selectedProject);
    }

    async projectById(
        projectId: string | null,
        allProjects: FilingLocation[],
        suggestedProjects: FilingLocation[]
    ): Promise<void> {
        let scrollIndex = 0;
        let projectsRef = this._suggestedProjectsRef;
        if (this._suggestedProjectsSelection.isIndexSelected(scrollIndex) || !projectId) {
            await this.clearIndices(this._allProjectsSelection);
            await this.clearIndices(this._suggestedProjectsSelection);
            this._suggestedProjectsSelection.setIndexSelected(scrollIndex, true, false);
            this.scrollListElementIntoView(scrollIndex, projectsRef);
            return;
        }

        const suggestedProjectListIndex = suggestedProjects.findIndex((project) => project.nrn === projectId);
        const projectListIndex = allProjects.findIndex((project) => project.nrn === projectId);

        if (suggestedProjectListIndex > -1) {
            this._suggestedProjectsSelection.setIndexSelected(suggestedProjectListIndex, true, false);
            scrollIndex = suggestedProjectListIndex;
        } else if (projectListIndex > -1) {
            this._allProjectsSelection.setIndexSelected(projectListIndex, true, false);
            scrollIndex = projectListIndex;
            projectsRef = this._allProjectsRef;
        } else {
            this._suggestedProjectsSelection.setIndexSelected(scrollIndex, true, false);
        }

        this.scrollListElementIntoView(scrollIndex, projectsRef);
    }

    private scrollListElementIntoView(index: number, componentRef: RefObject<IDetailsList> | null) {
        if (componentRef?.current) {
            componentRef.current.focusIndex(index, true);
        }
    }

    private initSuggestedProjectsSelection() {
        return new Selection({
            onSelectionChanged: async () =>
                this.handleSelectionChanged(this.suggestedProjectsSelection, this.allProjectsSelection),
        });
    }

    private initAllProjectsSelection() {
        return new Selection({
            onSelectionChanged: async () =>
                this.handleSelectionChanged(this.allProjectsSelection, this.suggestedProjectsSelection),
        });
    }

    private async clearIndices(selection: Selection): Promise<void> {
        return new Promise((resolve) => {
            this.shouldNotToggleSelection = true;
            selection.getSelectedIndices().forEach((index) => {
                selection.setIndexSelected(index, false, false);
            });
            this.shouldNotToggleSelection = false;
            resolve();
        });
    }
}

import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import {
    DefaultButton,
    Dropdown,
    IDropdownOption,
    IStackItemStyles,
    IStackTokens,
    Label,
    Panel,
    PanelType,
    PrimaryButton,
    ResponsiveMode,
    Stack,
    Toggle,
    Icon,
    getTheme,
    MessageBar,
    MessageBarType,
    Separator,
} from "office-ui-fabric-react";
import MessageDialogComponent from "../MessageDialogComponent";
import { OfficeRoamingSettings } from "../../../services/OfficeRoamingSettings";
import { PostFilingAction } from "../../../models/PostFilingAction";
import "./SettingsComponent.less";
import { OfficeWrapper } from "../../../services/OfficeWrapper";
import { Logger } from "../../../services/Logger";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../../services/AnalyticsManager";
import { FormEvent } from "react";
import { StorageWrapper } from "../../../services/StorageWrapper";
import { LocalStorageKeys } from "../../../models/StorageKeys";
import { InvalidateCacheService } from "../../../services/NewformaApi/InvalidateCacheService";
import { ProjectsCacheKeys } from "../../../models/StorageKeys";
import { SearchProps, SearchState } from "../../search/SearchComponent";
import { getClassForToast } from "../../../helpers/ToasterHelpers";

const stackItemStyles: IStackItemStyles = {
    root: {
        padding: 5,
    },
};
const containerStackTokens: IStackTokens = { childrenGap: 5 };
const verticalGapStackTokens: IStackTokens = {
    childrenGap: 10,
};

export interface SettingsProps extends LocalizeContextProps {
    showSettingsPanel: boolean;
    onDismiss: () => void;
    officeRoamingSettings: OfficeRoamingSettings;
    officeWrapper: OfficeWrapper;
    logger: Logger;
    onSettingsChanged: () => void;
    analyticsManager: AnalyticsManager;
    currentTheme: string;
    storageWrapper: StorageWrapper;
    invalidateCacheService: InvalidateCacheService;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    onDismissToastMessage: () => void;
    toastType?: MessageBarType;
    toastMessage?: string;
}

export interface SettingsState {
    hideSmartFilingHistoryDialog: boolean;
    deleteEmailAfterFiling: boolean;
    fileEmailConversation: boolean;
    shouldFileTransferToastShow: boolean;
    ShowAllGlobalProjects: boolean;
}

class SettingsComponent extends React.Component<SettingsProps, SettingsState> {
    private highestOfficeApiVersionSupported: string;

    constructor(props: Readonly<SettingsProps>, context: any) {
        super(props, context);

        this.state = {
            hideSmartFilingHistoryDialog: true,
            shouldFileTransferToastShow: false,
            deleteEmailAfterFiling: this.props.officeRoamingSettings.getDeleteEmailAfterFiling(),
            fileEmailConversation: this.props.officeRoamingSettings.getFileEmailConversation(),
            ShowAllGlobalProjects: this.props.officeRoamingSettings.getShowAllGlobalProjects(),
        };
        this.highestOfficeApiVersionSupported = this.getLatestSupportedOfficeApiVersion();
    }

    async componentDidUpdate(
        prevProps: Readonly<SettingsProps>,
        prevState: Readonly<SettingsState>,
        snapshot?: any
    ): Promise<void> {
        if (this.props.toastMessage !== prevProps.toastMessage && this.props.toastMessage) {
            this.setState({ shouldFileTransferToastShow: true });
        }
    }

    private onDismissToastMessage() {
        this.setState({ shouldFileTransferToastShow: false });
        this.props.onDismissToastMessage();
    }

    private getLatestSupportedOfficeApiVersion(): string {
        const minorVersion = this.props.officeWrapper.getLatestSupportedOfficeApiVersion();
        return minorVersion || (this.props.translate("APP.SETTINGS.UNKNOWN") as string);
    }

    private showSmartFilingHistoryDialog(): void {
        this.setState({ hideSmartFilingHistoryDialog: false });
    }

    private closeSmartFilingHistoryDialog(): void {
        this.setState({ hideSmartFilingHistoryDialog: true });
    }

    onRenderFooterContent(): JSX.Element {
        return (
            <div className="newforma-settingsFooter">
                <PrimaryButton className="newforma-settingsButton" onClick={this.closePanelClicked.bind(this)}>
                    {this.props.translate("APP.SETTINGS.CLOSE") as string}
                </PrimaryButton>
            </div>
        );
    }

    private closePanelClicked() {
        this.props.onDismiss();
    }

    private async resetHistoryConfirmed(): Promise<void> {
        this.props.logger.info("Add-in filing history reset");
        await this.props.officeRoamingSettings.resetFilingHistory();
        this.setState({ hideSmartFilingHistoryDialog: true });
    }

    private async onPostFileActionChanged(event: React.MouseEvent<HTMLElement>, checked?: boolean): Promise<void> {
        this.props.logger.info("Post-filing setting changed");
        if (checked === undefined) {
            return;
        }
        this.props.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserActions,
            AnalyticsActionType.SettingsDeleteEmailChanged,
            this.booleanAsString(checked)
        );
        const postFilingAction = checked ? PostFilingAction.DELETE : PostFilingAction.NONE;
        await this.props.officeRoamingSettings.saveWithRetry({ postFilingAction: postFilingAction });
        this.setState({ deleteEmailAfterFiling: checked });
        this.props.onSettingsChanged();
    }

    private async onFileEmailConversationChanged(
        event: React.MouseEvent<HTMLElement>,
        checked?: boolean
    ): Promise<void> {
        this.props.logger.info("File email conversation setting changed");
        if (checked === undefined) {
            return;
        }
        this.props.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserActions,
            AnalyticsActionType.SettingsFileConversationChanged,
            this.booleanAsString(checked)
        );
        await this.props.officeRoamingSettings.saveWithRetry({ fileEmailConversation: checked });
        this.setState({ fileEmailConversation: checked });
        this.props.onSettingsChanged();
        await this.props.invalidateCacheService.invalidateCache(ProjectsCacheKeys.globalProjectsCacheName);
    }

    private async onShowAllGlobalProjectsChanged(
        event: React.MouseEvent<HTMLElement>,
        checked?: boolean
    ): Promise<void> {
        this.props.logger.info("File email show all global projects setting changed");
        if (checked === undefined) {
            return;
        }
        this.props.analyticsManager.recordEvent(
            AnalyticsCategoryType.UserActions,
            AnalyticsActionType.SettingsShowAllGlobalProjectsChanged,
            this.booleanAsString(checked)
        );
        await this.props.officeRoamingSettings.saveWithRetry({ showAllGlobalProjects: checked });
        this.setState({ ShowAllGlobalProjects: checked });
        this.props.onSettingsChanged();
        await this.props.invalidateCacheService.invalidateCache(ProjectsCacheKeys.globalProjectsCacheName);
    }

    private booleanAsString(bool: boolean): string {
        return bool ? "enabled" : "disabled";
    }

    private onThemeChange(event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined): void {
        const newValue = option;
        if (newValue?.key && this.props.currentTheme !== newValue.key) {
            this.props.storageWrapper.saveLocalStorage(LocalStorageKeys.currentTheme, newValue.key as string);
            this.props.onSettingsChanged();
        }
    }

    onOuterClick(): void {}

    render(): JSX.Element {
        const theme = getTheme();

        const themeList = [
            { text: this.props.translate("APP.SETTINGS.LIGHT_AND_DARK_MODE.LIGHT") as string, key: "default" },
            { text: this.props.translate("APP.SETTINGS.LIGHT_AND_DARK_MODE.DARK") as string, key: "dark" },
        ];
        return (
            <div>
                <Panel
                    className="newforma-settingsPanel"
                    isOpen={this.props.showSettingsPanel}
                    type={PanelType.smallFixedFar}
                    headerText={this.props.translate("APP.COMMANDBAR.SETTINGS") as string}
                    closeButtonAriaLabel={this.props.translate("APP.SETTINGS.CLOSE") as string}
                    onRenderFooterContent={this.onRenderFooterContent.bind(this)}
                    onOuterClick={() => this.onOuterClick()}
                    onDismiss={() => this.props.onDismiss()}
                    data-theme={this.props.currentTheme}
                    allowTouchBodyScroll={true}
                >
                    <div
                        className="newforma-messageBarContainer newforma-messageBar-AppComponent"
                        hidden={!this.props.toastMessage || !this.state.shouldFileTransferToastShow}
                    >
                        <MessageBar
                            className={getClassForToast(
                                this.props.toastType,
                                this.props.toastMessage ? this.props.toastMessage.length : 0
                            )}
                            messageBarType={this.props.toastType}
                            isMultiline={false}
                            onDismiss={() => this.onDismissToastMessage()}
                        >
                            {this.props.toastMessage}
                        </MessageBar>
                    </div>
                    <div className="newforma-settingsComponent">
                        <Stack className="headerText">
                            <Separator className="custom-separator-bg" alignContent="start">
                                {this.props.translate("FILE_MULTIPLE_EMAIL.SUBMIT_BUTTON")}
                            </Separator>
                        </Stack>
                        <Stack tokens={containerStackTokens}>
                            <Stack tokens={verticalGapStackTokens}>
                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <Toggle
                                        id="deleteEmailAfterFilingControl"
                                        checked={this.state.deleteEmailAfterFiling}
                                        label={this.props.translate("APP.SETTINGS.FILE_DELETE") as string}
                                        onChange={this.onPostFileActionChanged.bind(this)}
                                        inlineLabel
                                    />
                                    <Toggle
                                        id="fileEmailConversationControl"
                                        checked={this.state.fileEmailConversation}
                                        label={this.props.translate("APP.SETTINGS.FILE_CONVERSATION") as string}
                                        onChange={this.onFileEmailConversationChanged.bind(this)}
                                        inlineLabel
                                    />
                                    <Toggle
                                        id="fileEmailConversationControl1"
                                        checked={this.state.ShowAllGlobalProjects}
                                        label={
                                            this.props.translate("APP.SETTINGS.FILE_SHOW_ALL_GLOBAL_PROJECTS") as string
                                        }
                                        onChange={this.onShowAllGlobalProjectsChanged.bind(this)}
                                        inlineLabel
                                    />
                                    <div className="newforma-conversation-warning">
                                        {
                                            this.props.translate(
                                                "APP.SETTINGS.FILE_SHOW_ALL_GLOBAL_PROJECTS_WARNING_TEXT"
                                            ) as string
                                        }
                                    </div>
                                </Stack.Item>

                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <DefaultButton
                                        id="resetFilingHistoryButton"
                                        className="newforma-settingsButton"
                                        onClick={this.showSmartFilingHistoryDialog.bind(this)}
                                    >
                                        {this.props.translate("APP.SETTINGS.RESET_FILING_HISTORY.BUTTON") as string}
                                    </DefaultButton>
                                </Stack.Item>
                                <Stack className="headerText">
                                    <Separator className="custom-separator-bg" alignContent="start">
                                        {this.props.translate("APP.SETTINGS.APPEARANCE")}
                                    </Separator>
                                </Stack>
                                <Stack.Item
                                    align="auto"
                                    styles={stackItemStyles}
                                    className="newforma-themePickerContainer"
                                >
                                    <Label id="newforma-themePickerLabel">
                                        {this.props.translate("APP.SETTINGS.THEME") as string}
                                    </Label>
                                    <Dropdown
                                        className="newforma-themePicker"
                                        options={themeList}
                                        responsiveMode={ResponsiveMode.large}
                                        disabled={false}
                                        onChange={this.onThemeChange.bind(this)}
                                        selectedKey={this.props.currentTheme}
                                        required={false}
                                        styles={{
                                            dropdown: {
                                                selectors: {
                                                    "& :hover": {
                                                        borderColor: `${theme.palette.themeTertiary} !important`,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </Stack.Item>
                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <Label id="officeApiVersionLabel" className="newforma-extraSpace">
                                        {`${this.props.translate("APP.SETTINGS.OFFICE_API_VERSION") as string} ${
                                            this.highestOfficeApiVersionSupported
                                        }`}
                                    </Label>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </div>
                </Panel>
                <MessageDialogComponent
                    title={this.props.translate("APP.SETTINGS.RESET_FILING_HISTORY.TITLE") as string}
                    subText={this.props.translate("APP.SETTINGS.RESET_FILING_HISTORY.TEXT") as string}
                    hidden={this.state.hideSmartFilingHistoryDialog}
                    onDismiss={this.closeSmartFilingHistoryDialog.bind(this)}
                    onConfirmButtonClicked={this.resetHistoryConfirmed.bind(this)}
                    theme={this.props.currentTheme}
                />
            </div>
        );
    }
}

export default withLocalize(SettingsComponent);

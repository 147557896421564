import "./ReminderComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { SpinButton, Toggle } from "office-ui-fabric-react";
import LabelComponent from "../label/LabelComponent";

export interface ReminderComponentProps extends LocalizeContextProps {
    disabled?: boolean;
    className?: string;
    max?: number;
    onChange: (value: number | undefined) => void;
    value?: number | undefined;
    isFiling?: boolean;
}

export interface ReminderComponentState {
    showReminderDays: boolean;
    days: string | undefined;
}

export const defaultReminderComponentState: ReminderComponentState = {
    showReminderDays: false,
    days: "2",
};

class ReminderComponent extends React.Component<ReminderComponentProps, ReminderComponentState> {
    constructor(props: ReminderComponentProps, context: ReminderComponentState) {
        super(props, context);

        this.state = defaultReminderComponentState;
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({ showReminderDays: true, days: JSON.stringify(this.props.value) });
        }
    }

    componentDidUpdate(
        prevProps: Readonly<ReminderComponentProps>,
        prevState: Readonly<ReminderComponentState>,
        snapshot?: any
    ) {
        if (prevProps.disabled !== this.props.disabled) {
            if (this.props.disabled) {
                this.setState({ showReminderDays: false });
            } else {
                this.setState({ showReminderDays: true });
            }
            return;
        }
    }

    private onShowReminderDaysChange(event: React.MouseEvent<HTMLElement>, checked?: boolean): void {
        if (this.props.isFiling) {
            return;
        }
        if (checked === undefined || !checked) {
            this.props.onChange(undefined);
        } else {
            this.props.onChange(Number(this.state.days) || undefined);
        }
        this.setState({ showReminderDays: !!checked });
    }

    private onValidateDays(value: string, event?: React.SyntheticEvent<HTMLElement>): void | string {
        let num = this.parseNumber(value);
        num = this.validValue(num);
        this.setState({ days: `${num}` });
        this.props.onChange(num);
        return `${num}`;
    }

    private onIncrement = (value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
        let num = this.parseNumber(value) + 1;
        num = this.validValue(num);
        this.setState({ days: `${num}` });
        this.props.onChange(num);
    };

    private onDecrement = (value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
        let num = this.parseNumber(value) - 1;
        num = this.validValue(num);
        this.setState({ days: `${num}` });
        this.props.onChange(num);
    };

    private validValue(num: number) {
        let validatedNum = num;
        if (num < 0) {
            validatedNum = 0;
        } else if (!!this.props.max && num > this.props.max) {
            validatedNum = this.props.max;
        }
        return validatedNum;
    }

    private parseNumber(value: string | undefined): number {
        return Number(value) || 0;
    }

    render(): JSX.Element {
        return (
            <div className={`newforma-reminderComponent ${this.props.className}`}>
                <Toggle
                    className={this.props.isFiling ? "newforma-reminderIsFiling" : "newforma-reminderToggle"}
                    onChange={this.onShowReminderDaysChange.bind(this)}
                    onText={this.props.translate("SHARED.REMINDER_COMPONENT.TOGGLE") as string}
                    offText={this.props.translate("SHARED.REMINDER_COMPONENT.TOGGLE") as string}
                    disabled={this.props.disabled}
                    checked={this.state.showReminderDays}
                />
                {this.state.showReminderDays ? (
                    <div className="newforma-reminderDayContainer">
                        <SpinButton
                            className={this.props.isFiling ? "newforma-reminderDayFiling" : "newforma-reminderDay"}
                            value={this.state.days}
                            onValidate={this.onValidateDays.bind(this)}
                            min={0}
                            step={1}
                            max={this.props.max}
                            onIncrement={this.onIncrement.bind(this)}
                            onDecrement={this.onDecrement.bind(this)}
                            disabled={this.props.isFiling}
                        />
                        <LabelComponent
                            className={
                                this.props.isFiling ? "newforma-reminderDayLabelFiling" : "newforma-reminderDayLabel"
                            }
                            text={this.props.translate("SHARED.REMINDER_COMPONENT.DAYS_LABEL") as string}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withLocalize(ReminderComponent);

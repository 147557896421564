import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { DefaultButton, Label, MessageBar, MessageBarType, Icon } from "office-ui-fabric-react";
import { Authenticator } from "../../services/Authenticator";
import { Redirect } from "react-router-dom";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { UnauthenticatedNewformaApiClient, Region } from "../../services/UnauthenticatedNewformaApiClient";
import RegionSelectorComponent from "./RegionSelectorComponent";
import { IdpConfiguration } from "../../models/IdpConfiguration";
import "./LoginComponent.less";
import { Logger } from "../../services/Logger";
import EulaComponent from "../eula/EulaComponent";
import { EulaApiService } from "../../services/NewformaApi/EulaApiService";
import { AnalyticsManager } from "../../services/AnalyticsManager";
import { Eula } from "../../models/EulaModels";
import { OpenIDCredentials } from "../../models/OpenIDCredentials";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";

export interface LoginComponentProps extends LocalizeContextProps {
    onApiLogin: () => void;
    authenticator: Authenticator;
    redirectUrl: string;
    isLoggedIn: boolean;
    officeWrapper: OfficeWrapper;
    apiClient: UnauthenticatedNewformaApiClient;
    logger: Logger;
    eulaService: EulaApiService;
    analyticsManager: AnalyticsManager;
    onEulaDecline: () => Promise<void>;
    isEulaSupported: boolean;
    theme: string;
}

export interface LoginComponentState {
    // If this is set, it means that when the user clicked Login, they didn't have a region set so we had
    // to show the region chooser UI before doing the login.
    idpConfig: IdpConfiguration | null;
    lastError: string | null;
    isError: boolean;
    regions: Region[] | null;
    showEula: boolean;
    selectedRegion: string | undefined;
    openIdCredentials: OpenIDCredentials | null;
    inProgressToLogin: boolean;
}

class LoginComponent extends React.Component<LoginComponentProps, LoginComponentState> {
    constructor(props: LoginComponentProps, context: LoginComponentState) {
        super(props, context);

        this.state = {
            idpConfig: null,
            lastError: null,
            isError: false,
            regions: null,
            showEula: false,
            selectedRegion: undefined,
            openIdCredentials: null,
            inProgressToLogin: false,
        };
    }

    componentDidMount(): void {
        this.props.logger.info("LoginComponent mounted");
    }

    private async handleLogin(): Promise<void> {
        this.setState({ inProgressToLogin: true, isError: false, lastError: null });
        // No idp config is saved which means they're just clicking the login button for the first time this session
        const currentUserEmail = this.props.officeWrapper.userProfileEmailAddress;
        const config = await this.props.apiClient.getIdpConfigForUser(currentUserEmail);
        this.setState({ idpConfig: config });

        if (config.isRegionRequired) {
            try {
                this.setState({ inProgressToLogin: false });
                const regionsResponse = await this.props.authenticator.getRegions(this.props.activeLanguage.code);
                this.setState({ regions: regionsResponse.region_list });
            } catch (error) {
                console.error(error);
                this.onError(this.props.translate("PROJECT.ERROR") as string);
            }
        } else {
            // The user already has a region, we can just login normally
            try {
                await this.completeLogin();
            } catch (error) {
                this.handleCredentialsError(error as any);
            }
        }
    }

    private async regionSelected(region: string): Promise<void> {
        try {
            this.setState({ selectedRegion: region });
            await this.completeLogin();
        } catch (error) {
            this.handleCredentialsError(error as any);
        }
    }

    private handleCredentialsError(error: Error) {
        if (error.message.includes("Missing required setting")) {
            this.onError(this.props.translate("PROJECT.CREDENTIALS_ERROR") as string);
        }
        this.setState({ inProgressToLogin: false });
    }

    private async handleEulaResponse(eula: Eula | undefined): Promise<void> {
        this.setState({ showEula: false });
        this.determineLoginProgress();
        if (!this.state.openIdCredentials || !this.state.idpConfig) {
            throw new Error("Invalid state, no Id credentials is available");
        }
        try {
            await this.props.authenticator.handleLogin(
                this.state.openIdCredentials,
                this.state.idpConfig,
                eula,
                this.state.selectedRegion
            );
            if (eula && !eula.isAccepted) {
                this.setState({ inProgressToLogin: false });
                await this.props.onEulaDecline();
            } else {
                this.setState({ inProgressToLogin: true });
                this.props.onApiLogin();
            }
        } catch {
            this.setState({ inProgressToLogin: false });
            await this.props.onEulaDecline();
        }
    }

    private async completeLogin(): Promise<void> {
        this.setState({ inProgressToLogin: true });
        window.addEventListener("storage", () => {
            this.determineLoginProgress();
        });
        if (!this.state.idpConfig) {
            throw new Error("Invalid state, no IDP configuration is available");
        }

        const openIDCredentials = await this.props.authenticator.getOpenIdCredentials(this.state.idpConfig);
        this.setState({ inProgressToLogin: true, openIdCredentials: openIDCredentials });

        if (this.state.idpConfig.isEulaRequired && this.props.isEulaSupported) {
            this.setState({ showEula: true, inProgressToLogin: false });
            return;
        }
        await this.props.authenticator.handleLogin(
            openIDCredentials,
            this.state.idpConfig,
            undefined,
            this.state.selectedRegion
        );

        this.props.onApiLogin();
    }

    private determineLoginProgress(): void {
        const loginProgress = window.localStorage.getItem("inProgressToLogin");
        loginProgress === "false"
            ? this.setState({ inProgressToLogin: false })
            : this.setState({ inProgressToLogin: true });
    }

    private getErrorBarComponent(): JSX.Element {
        return (
            <div className="newforma-messageBar-container">
                <MessageBar
                    className="newforma-messageBar"
                    messageBarType={MessageBarType.severeWarning}
                    isMultiline={false}
                    onDismiss={this.onErrorBarComponentDismissed.bind(this)}
                >
                    {this.state.lastError}
                </MessageBar>
            </div>
        );
    }

    private onError(errorText: string): void {
        this.setState({ lastError: errorText, isError: true });
    }

    private onErrorBarComponentDismissed(): void {
        this.setState({ lastError: null });
    }

    render(): JSX.Element {
        return (
            <div className="newforma-loginPage">
                {this.props.isLoggedIn ? <Redirect to={this.props.redirectUrl} /> : null}
                <img
                    src={this.props.theme === "dark" ? "assets/NF_Logo_login-white.svg" : "assets/NF_Logo_login.svg"}
                    className="newforma-welcomeLogo"
                    alt="Newforma logo"
                />
                {this.state.idpConfig?.isRegionRequired &&
                this.state.inProgressToLogin === false &&
                !this.state.isError ? (
                    <RegionSelectorComponent
                        regions={this.state.regions || []}
                        onRegionSelected={this.regionSelected.bind(this)}
                    />
                ) : (
                    <div className="newforma-loginContent">
                        {this.state.inProgressToLogin ? (
                            <div className="loginSpinner">
                                <Spinner
                                    label={this.props.translate("AUTHENTICATION.LOADING") as string}
                                    ariaLive="assertive"
                                    labelPosition="right"
                                    size={SpinnerSize.large}
                                />
                            </div>
                        ) : (
                            <DefaultButton
                                className="newforma-importantActionButton"
                                onClick={this.handleLogin.bind(this)}
                                text={this.props.translate("AUTHENTICATION.SIGNIN") as string}
                            />
                        )}
                        {this.state.lastError ? this.getErrorBarComponent() : null}
                        <div className="newforma-loginMarketingContainer">
                            <div className="newforma-flexRow marketing-row">
                                <span className="icon-section">
                                    <Icon iconName="FabricFolderConfirm" className="fabric-UI-logo-icon" />
                                </span>
                                <Label className="ms-font-m">
                                    <span className="welcome-bold-text">
                                        {this.props.translate("APP.WELCOME.MARKETING_STATEMENT_1.PART_1") as string}
                                    </span>
                                    <span>
                                        {this.props.translate("APP.WELCOME.MARKETING_STATEMENT_1.PART_2") as string}
                                    </span>
                                </Label>
                            </div>
                            <div className="newforma-flexRow marketing-row">
                                <span className="icon-section">
                                    <Icon iconName="FabricFolderSearch" className="fabric-UI-logo-icon" />
                                </span>
                                <Label className="ms-font-m">
                                    <span className="welcome-bold-text">
                                        {this.props.translate("APP.WELCOME.MARKETING_STATEMENT_2.PART_1") as string}
                                    </span>
                                    <span>
                                        {this.props.translate("APP.WELCOME.MARKETING_STATEMENT_2.PART_2") as string}
                                    </span>
                                </Label>
                            </div>
                            <div className="newforma-flexRow marketing-row">
                                <span className="icon-section">
                                    <Icon iconName="OutlookLogoInverse16" className="fabric-UI-logo-icon" />
                                </span>
                                <Label className="ms-font-m">
                                    <span>
                                        {this.props.translate("APP.WELCOME.MARKETING_STATEMENT_3.PART_1") as string}
                                    </span>
                                    <span className="welcome-bold-text">
                                        {this.props.translate("APP.WELCOME.MARKETING_STATEMENT_3.PART_2") as string}
                                    </span>
                                    <span>
                                        {this.props.translate("APP.WELCOME.MARKETING_STATEMENT_3.PART_3") as string}
                                    </span>
                                    <span className="welcome-bold-text">
                                        {this.props.translate("APP.WELCOME.MARKETING_STATEMENT_3.PART_4") as string}
                                    </span>
                                </Label>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.showEula && this.props.isEulaSupported ? (
                    <EulaComponent
                        onEulaResponse={this.handleEulaResponse.bind(this)}
                        eulaService={this.props.eulaService}
                        logger={this.props.logger}
                        analyticsManager={this.props.analyticsManager}
                        isEulaPreviouslyAccepted={this.state.idpConfig?.isEulaUpdated ?? false}
                        theme={this.props.theme}
                    />
                ) : null}
            </div>
        );
    }
}

export default withLocalize(LoginComponent);

import { DetailedKeyword, ProjectKeywordsResponse } from "../models/ProjectKeywordsResponse";
import { Contract, ContractsResponse } from "../models/projects/ContractsResponse";

export class ProjectHelper {
    static sortKeywords(keywordsResponse: ProjectKeywordsResponse): DetailedKeyword[] {
        return keywordsResponse.items.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    static sortContracts(contractsResponse: ContractsResponse): Contract[] {
        return contractsResponse.items.sort((a, b) => a.subject.localeCompare(b.subject));
    }
}

import { IdpConfigurationParameters } from "../models/IdpConfigurationParameters";
import { UnauthenticatedNewformaApiClient } from "./UnauthenticatedNewformaApiClient";
import { UrlBuilder } from "./UrlBuilder";
import { OfficeWrapper } from "./OfficeWrapper";
import { IdpConfiguration } from "../models/IdpConfiguration";
import { LocalStorageKeys } from "../models/StorageKeys";
import { StorageWrapper } from "./StorageWrapper";
import { WindowWrapper } from "./WindowWrapper";

export class AuthUrlProvider {
    constructor(
        private apiClient: UnauthenticatedNewformaApiClient,
        private urlBuilder: UrlBuilder,
        private officeWrapper: OfficeWrapper,
        private storageWrapper: StorageWrapper,
        private windowWrapper: WindowWrapper
    ) {}

    async getAuthorizeUrl(config: IdpConfiguration): Promise<string> {
        const authorizeUrlTemplate = this.customizeUrl(config.providers[0].authorizeUrl);
        const idpConfigurationParameters = IdpConfigurationParameters.fromProviderConfiguration(
            config.providers[0],
            null
        );
        return this.urlBuilder.loginUrl(authorizeUrlTemplate, idpConfigurationParameters);
    }

    async getRefreshUrl(): Promise<string> {
        const currentUserEmail = this.officeWrapper.userProfileEmailAddress;
        const config = await this.apiClient.getIdpConfigForUser(currentUserEmail);
        const refreshUrlTemplate = this.customizeUrl(config.providers[0].refreshUrl);
        const tenantId = this.getTenantId();
        const idpConfigurationParameters = IdpConfigurationParameters.fromProviderConfiguration(
            config.providers[0],
            tenantId
        );
        return this.urlBuilder.refreshUrl(refreshUrlTemplate, idpConfigurationParameters);
    }

    private customizeUrl(url: string): string {
        return `${url}&login_hint=${encodeURIComponent(this.officeWrapper.userProfileEmailAddress)}`;
    }

    private getTenantId(): string | null {
        const idToken = this.storageWrapper.loadLocalStorage(LocalStorageKeys.idToken);
        const splitToken = idToken ? idToken.split(".")[1] : null;
        const parsedSplitToken = splitToken ? JSON.parse(this.windowWrapper.base64Decode(splitToken)) : {};
        return parsedSplitToken.tid;
    }
}

import * as React from "react";
import { Redirect, Route } from "react-router-dom";

export interface RedirectComponentProps {
    isLoggedIn: boolean;
    location?: string;
}

export class RedirectComponent extends React.Component<RedirectComponentProps, any> {
    constructor(props: any) {
        super(props);
    }

    render(): JSX.Element {
        const { isLoggedIn, children } = this.props;
        return <Route>{isLoggedIn ? children : <Redirect to={{ pathname: "/login" }} />}</Route>;
    }
}

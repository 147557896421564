import { Logger } from "../Logger";
import { NewformaApiClient } from "./NewformaApiClient";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { EmailListResponse } from "../../models/EmailListResponse";
import { Request } from "aws-sign-web";
import { PostEmailFilingResponse } from "../../models/PostEmailFilingResponse";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../AnalyticsManager";
import { OfficeRoamingSettings } from "../OfficeRoamingSettings";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { OfficeWrapper } from "../OfficeWrapper";
import { SmartFilingManager } from "../SmartFiling/SmartFilingManager";
import { ITag } from "office-ui-fabric-react";
import { FilingLocationsResponse } from "../../models/FilingLocationsResponse";
import * as pLimit from "p-limit";

export class EmailApiService {
    private readonly emailListPageSize = 50;
    private readonly maxSimultaneousFillingRequest = 10;
    constructor(
        private logger: Logger,
        private newformaApiClient: NewformaApiClient,
        private requestWrapper: HttpRequestWrapper,
        private analyticsManager: AnalyticsManager,
        private officeRoamingSettings: OfficeRoamingSettings,
        private officeWrapper: OfficeWrapper,
        private smartFilingManager: SmartFilingManager
    ) {}

    async getEmails(offsetToken: string | null): Promise<EmailListResponse> {
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/email/folders/inbox/messages`;

        const params = {
            pageSize: offsetToken ? this.emailListPageSize + 1 : this.emailListPageSize,
            offsetToken: `${offsetToken || ""}`,
        };
        const options: Request = {
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
            params: params,
        };

        this.logger.info("Fetching Emails");
        return this.newformaApiClient.makeRequest(options, (signedOptions) =>
            this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, params)
        );
    }

    async fileMultipleEmails(
        emailIds: string[],
        project: ITag,
        autoFileMailboxFolderName?: string,
        culture?: string
    ): Promise<string[]> {
        const limit = pLimit(this.maxSimultaneousFillingRequest);
        const postFilingAction = this.officeRoamingSettings.getPostFilingAction();
        const fileConversation = this.officeRoamingSettings.getFileEmailConversation();
        const deleteAfterFiling = this.officeRoamingSettings.getDeleteEmailAfterFiling();

        const failedIds: string[] = [];
        const nrnPrefix = "nrn:email:message:ms-graph:";
        const fileEmailPromises = emailIds.map((emailId) => {
            return limit(() => {
                return this.makeFilingRequest(
                    `${nrnPrefix}${emailId}`,
                    project.key,
                    postFilingAction,
                    fileConversation,
                    autoFileMailboxFolderName,
                    culture
                ).catch((error) => {
                    this.logger.error(`EmailApiService email with id ${emailId} failed to file`, error);
                    failedIds.push(emailId);
                    if (ExpiredSessionError.isInstanceOf(error)) {
                        throw error;
                    }
                    return "";
                });
            });
        });

        await Promise.all(fileEmailPromises);

        this.analyticsManager.recordEvent(
            AnalyticsCategoryType.EmailFiling,
            AnalyticsActionType.FileMultipleEmail,
            `number filed: ${emailIds.length.toString()}`
        );

        if (fileConversation) {
            this.analyticsManager.recordEvent(
                AnalyticsCategoryType.EmailFiling,
                AnalyticsActionType.FiledWithConversation
            );
        }
        if (deleteAfterFiling) {
            this.analyticsManager.recordEvent(AnalyticsCategoryType.EmailFiling, AnalyticsActionType.FiledWithDelete);
        }

        try {
            const projectAsAny = project as any;
            this.analyticsManager.recordSmartFilingEvents(projectAsAny.suggestedProject, projectAsAny.suggestionIndex);
            const mailboxItem = this.officeWrapper.currentContextItem;
            await this.smartFilingManager.addToFiledHistory(
                project,
                mailboxItem.conversationId,
                mailboxItem.sender.emailAddress
            );
        } catch (error) {
            this.logger.error("EmailApiService failed to update smart filing history", error);
        }

        return failedIds;
    }

    async fileToProject(projectNrn: string, messageNrn: string): Promise<PostEmailFilingResponse> {
        this.logger.info("Filing single email");
        const postFilingAction = this.officeRoamingSettings.getPostFilingAction();
        const fileConversation = this.officeRoamingSettings.getFileEmailConversation();
        const deleteAfterFiling = this.officeRoamingSettings.getDeleteEmailAfterFiling();

        const result = await this.makeFilingRequest(messageNrn, projectNrn, postFilingAction, fileConversation);
        if (fileConversation) {
            this.analyticsManager.recordEvent(
                AnalyticsCategoryType.EmailFiling,
                AnalyticsActionType.FiledWithConversation
            );
        }
        if (deleteAfterFiling) {
            this.analyticsManager.recordEvent(AnalyticsCategoryType.EmailFiling, AnalyticsActionType.FiledWithDelete);
        }
        return result;
    }

    async getFilingLocations(): Promise<FilingLocationsResponse> {
        this.logger.info("Retrieving filing locations");
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/email/filinglocations`;
        const options = {
            hostname: this.newformaApiClient.getHostName(),
            url: url,
            method: "GET",
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, async (signedOptions: Request) =>
            this.requestWrapper.get(url, undefined, signedOptions.headers, undefined)
        );
    }

    async markEmailForFiling(emailId: string): Promise<any> {
        this.logger.info("Marking email to be filed on send");
        const fileConversation = this.officeRoamingSettings.getFileEmailConversation();
        const payload = JSON.stringify({
            emailId,
            postFiling: {
                fileEntireConversation: fileConversation,
            },
        });
        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/email/filing/async`;

        const options: Request = {
            hostname: this.newformaApiClient.getHostName(),
            url: url,
            method: "POST",
            body: payload,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, async (signedOptions: Request) => {
            const result = await this.requestWrapper.post(url, undefined, signedOptions.headers, payload);
            if (fileConversation) {
                this.analyticsManager.recordEvent(
                    AnalyticsCategoryType.EmailFiling,
                    AnalyticsActionType.FiledWithConversation
                );
            }
            return result;
        });
    }

    private async makeFilingRequest(
        messageNrn: string,
        projectNrn: string,
        postFilingAction: string,
        fileConversation: boolean,
        autoFileMailboxFolderName?: string,
        culture?: string
    ): Promise<PostEmailFilingResponse> {
        const payload = JSON.stringify({
            messageNrn,
            postFiling: {
                action: postFilingAction,
                fileEntireConversation: fileConversation,
            },
            autoFileMailboxFolderName,
            culture,
        });

        const url = `${this.newformaApiClient.getHostNameWithProtocol()}/v1/email/filinglocations/${projectNrn}`;
        const options = {
            hostname: this.newformaApiClient.getHostName(),
            url: url,
            method: "POST",
            body: payload,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };

        return this.newformaApiClient.makeRequest(options, async (signedOptions) =>
            this.requestWrapper.post(url, undefined, signedOptions.headers, payload)
        );
    }
}

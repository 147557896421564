export enum MessageTypes {
    CacheRefreshed = "NewformaProjectsCacheRefreshed",
}
export class Messenger {
    on<T>(eventName: MessageTypes, callback: (data?: T) => void): void {
        document.addEventListener(eventName, (e) => callback((e as CustomEvent).detail));
    }
    dispatch(eventName: MessageTypes, data?: any) {
        const event = new CustomEvent(eventName, {
            detail: data,
            bubbles: true,
            cancelable: true,
        });
        document.dispatchEvent(event);
    }
    remove(eventName: MessageTypes, callback: (data: any) => void) {
        document.removeEventListener(eventName, callback);
    }
}

export enum KeywordListType {
    ActionItemType = "action_item_type",
    SubmittalDiscipline = "submittal_discipline_list",
    SubmittalKeyword = "submittal_keyword_list",
    SubmittalSpecSection = "submittal_specsection_list",
    RfiSpecSection = "rfi_specsection_list",
    RfiDisciplines = "rfi_discipline_list",
    RfiKeywords = "rfi_keyword_list",
    RfiReasons = "rfi_reasons_list",
    RfiImpactSchedule = "impact_schedule_list",
    RfiImpactCost = "impact_cost_list",
    RfiTypes = "rfi_types_list",
    TransferMethods = "transfer_methods_list",
    TransmittalPurposes = "transmittal_purposes",
    TransmittalKeywords = "transmittal_keywords",
}

export interface ProjectKeywordsResponse {
    allowCustom: boolean;
    items: DetailedKeyword[];
    paging?: Paging;
}

export interface Paging {
    offsetToken: string;
}

export interface DetailedKeyword {
    displayOrder: number;
    name: string;
    type: string;
    description?: string;
}

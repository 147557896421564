import { FilingLocation } from "../../models/FilingLocationsResponse";

export enum SuggestionWeight {
    None = 0,
    Recent = 1,
    InternalSender = 2,
    ExternalSender = 4,
    BodyKeywordMatch = 8,
    SubjectKeywordMatch = 16,
    Thread = 32,
}

interface SuggestionTypes {
    None: number;
    Recent: number;
    InternalSender: number;
    ExternalSender: number;
    BodyKeywordMatch: number;
    SubjectKeywordMatch: number;
    Thread: number;
    // this is to suppress key reference errors
    [key: string]: number;
}

export class Suggestion {
    types: SuggestionTypes = {
        None: 0,
        Recent: 0,
        InternalSender: 0,
        ExternalSender: 0,
        BodyKeywordMatch: 0,
        SubjectKeywordMatch: 0,
        Thread: 0,
    };

    isRecent(): SuggestionWeight {
        return (this.types.Recent = SuggestionWeight.Recent);
    }

    isInternalSender(): SuggestionWeight {
        this.types.ExternalSender = 0;
        return (this.types.InternalSender = SuggestionWeight.InternalSender);
    }

    isExternalSender(): SuggestionWeight {
        this.types.InternalSender = 0;
        return (this.types.ExternalSender = SuggestionWeight.ExternalSender);
    }

    bodyKeywordMatch(): SuggestionWeight {
        return (this.types.BodyKeywordMatch = SuggestionWeight.BodyKeywordMatch);
    }

    subjectKeywordMatch(): SuggestionWeight {
        return (this.types.SubjectKeywordMatch = SuggestionWeight.SubjectKeywordMatch);
    }

    isThread(): SuggestionWeight {
        return (this.types.Thread = SuggestionWeight.Thread);
    }

    get rankedTypes(): string[] {
        const resolvedTypes = Object.keys(this.types).filter((type) => !!this.types[type]);
        return resolvedTypes.sort((a, b) => this.types[b] - this.types[a]);
    }

    get strongest(): string {
        const rankedTypes = this.rankedTypes;
        if (!rankedTypes.length) {
            return "None";
        }
        return rankedTypes[0];
    }

    get totalRankedWeight(): number {
        return Object.values(this.types).reduce((a, b) => a + b, 0);
    }
}

export class SuggestedProject implements FilingLocation {
    nrn: string = "";
    name: string = "";
    private _dateFiled: Date = new Date();
    private _filingFrequency = 0;
    private _suggestion: Suggestion;

    constructor(fromProject: any, suggestion: Suggestion) {
        this.nrn = fromProject.key || fromProject.nrn;
        this._filingFrequency = fromProject.c || 0;

        if (fromProject.name) {
            this.name = fromProject.name;
        }
        if (fromProject.d) {
            this._dateFiled = fromProject.d;
        }
        this._suggestion = suggestion;
    }

    set dateFiled(date: Date) {
        this._dateFiled = date;
    }

    get dateFiled(): Date {
        return this._dateFiled;
    }

    set suggestion(suggestion: Suggestion) {
        this._suggestion = suggestion;
    }

    get suggestion() {
        return this._suggestion;
    }

    set filingFrequency(frequency: number) {
        this._filingFrequency = frequency || 0;
    }

    get filingFrequency() {
        return this._filingFrequency;
    }

    get rankedTypes() {
        return this.suggestion.rankedTypes;
    }

    get suggestionWeight() {
        return this.suggestion.totalRankedWeight;
    }

    get suggestionTypeLabel() {
        return this.suggestion.strongest;
    }
}

import "./RfiReviewResponseComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Logger } from "../../../services/Logger";
import SuggestedProjectPickerComponent from "../../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import {
    DefaultButton,
    IPersonaProps,
    ISuggestionItemProps,
    ITag,
    Label,
    MessageBarType,
    ProgressIndicator,
    TagPicker,
    TextField,
} from "office-ui-fabric-react";
import FromAndToComponent from "../../shared/fromAndToComponent/FromAndToComponent";
import AttachmentsComponent from "../../shared/attachments/AttachmentsComponent";
import { MailboxItem, OfficeWrapper } from "../../../services/OfficeWrapper";
import { SmartFilingManager } from "../../../services/SmartFiling/SmartFilingManager";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import { AppPage } from "../../shared/navigationHeader/NavigationHeaderComponent";
import { ExpiredSessionError } from "../../../models/ExpiredSessionError";
import SuggestedItemWithSubtextComponent from "../../shared/pickerSuggestedItems/suggestedItemWithSubtext/SuggestedItemWithSubtextComponent";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import { RfiApiService } from "../../../services/NewformaApi/RfiApiService";
import { ApiRequestErrorLevel } from "../../../models/ApiRequestErrorWithMessage";
import { RfiReviewResponseRequestParams } from "../../../models/workflow/rfi/RfiReviewResponseRequestParams";
import { WorkflowActionType } from "../../../models/workflow/WorkflowActionType";
import LabelComponent from "../../shared/label/LabelComponent";
import { AnalyticsManager } from "../../../services/AnalyticsManager";
import KeywordsDropdown from "../../shared/keywordsDropdown/KeywordsDropdown";
import { DetailedKeyword, KeywordListType } from "../../../models/ProjectKeywordsResponse";
import { MsGraphApiService } from "../../../services/MsGraphApiService";
import { NrnServiceWrapper } from "../../../services/NrnServiceWrapper";
import TranslatedDatePickerComponent from "../../shared/translatedDatePicker/TranslatedDatePickerComponent";
import { ElementVisibility } from "../../../models/shared/ElementVisibility";
import ChipPickerComponent from "../../shared/chipPicker/ChipPickerComponent";
import { DateHelpers } from "../../../helpers/DateHelpers";
import { ProjectHelper } from "../../../helpers/ProjectHelper";
import { ProjectsCacheKeys } from "../../../models/StorageKeys";
import { InvalidateCacheService } from "../../../services/NewformaApi/InvalidateCacheService";
import { AttachmentItem } from "../../../models/shared/AttachmentList";
import HTMLEditor from "../../shared/editor/Editor";
import { ConfigurationService } from "../../../services/ConfigurationService";
import { AttachmentDataHelpers } from "../../../helpers/AttachmentDataHelpers";

export interface RfiReviewResponseComponentProps extends LocalizeContextProps {
    logger: Logger;
    onExpiredSession: () => void;
    onSetNavigationPage: (page: AppPage) => void;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    smartFilingManager: SmartFilingManager;
    officeWrapper: OfficeWrapper;
    formValidationHelpers: FormValidationHelpers;
    rfiApiService: RfiApiService;
    projectsService: IProjectsService;
    mailboxItem: MailboxItem | null;
    analyticsManager: AnalyticsManager;
    nrnServiceWrapper: NrnServiceWrapper;
    msGraphApiService: MsGraphApiService;
    dateHelpers: DateHelpers;
    theme: string;
    invalidateCacheService: InvalidateCacheService;
    isFilePathInAttachmentsSupported?: boolean;
    isFileTransferAndEditorSupported: boolean;
    configService: ConfigurationService;
}

export interface RfiReviewResponseComponentState {
    isLoadingProjects: boolean;
    isLoadingKeywords: boolean;
    isCloudProject: boolean;
    projects: ITag[];
    selectedProject: ITag | null;
    isFiling: boolean;
    fileUploadIsInProgress: boolean;
    rfis: ITag[];
    selectedRfi: ITag | undefined;
    actionKeywords: DetailedKeyword[];
    selectedActionKeyword: DetailedKeyword | null;
    response: string;
    attachments: AttachmentItem[];
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    failedUploadAttachmentNames: string[];
    subject: string;
    receivedDate: Date | undefined;
    keywords: IPersonaProps[];
    selectedKeywords: IPersonaProps[];
    allowCustomKeywords: boolean;
    remarks: string;
}

export const rfiReviewResponseComponentDefaultState: RfiReviewResponseComponentState = {
    isLoadingProjects: true,
    isLoadingKeywords: false,
    isCloudProject: false,
    projects: [],
    selectedProject: null,
    isFiling: false,
    fileUploadIsInProgress: false,
    rfis: [],
    selectedRfi: undefined,
    actionKeywords: [],
    selectedActionKeyword: null,
    response: "",
    attachments: [],
    from: [],
    to: [],
    cc: [],
    failedUploadAttachmentNames: [],
    subject: "",
    receivedDate: undefined,
    keywords: [],
    selectedKeywords: [],
    allowCustomKeywords: false,
    remarks: "",
};

class RfiReviewResponseComponent extends React.Component<
    RfiReviewResponseComponentProps,
    RfiReviewResponseComponentState
> {
    constructor(props: RfiReviewResponseComponentProps, context: RfiReviewResponseComponentState) {
        super(props, context);

        this.state = rfiReviewResponseComponentDefaultState;
    }

    async componentDidMount() {
        this.props.logger.info("File Email as RFI Review Response component mounted");
        this.props.onSetNavigationPage(AppPage.FileAsRfiReviewResponse);
        await Promise.all([this.populateDefaults(), this.loadProjects()]);
    }

    async componentDidUpdate(
        prevProps: Readonly<RfiReviewResponseComponentProps>,
        prevState: Readonly<RfiReviewResponseComponentState>,
        snapshot?: any
    ) {
        if (this.props.mailboxItem && prevProps.mailboxItem !== this.props.mailboxItem) {
            this.setState((state) => ({
                ...rfiReviewResponseComponentDefaultState,
                projects: state.projects,
                selectedProject: state.selectedProject,
                from: state.from,
                to: state.to,
                cc: state.cc,
            }));

            await this.populateDefaults();
            this.setState({
                isLoadingProjects: false,
                attachments: [],
            });
        }
    }

    private async populateDefaults(): Promise<void> {
        const attachments = this.props.officeWrapper.getCurrentEmailAttachmentDetails();
        const attachmentsToUse = attachments.filter((attachment) => !attachment.isInline);
        const attachmentsNoCloud = attachmentsToUse.filter(
            (attachment) => attachment.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud
        );
        const remark = await this.props.msGraphApiService.getCurrentClearMessageBody(
            this.props.isFileTransferAndEditorSupported
        );
        const subject = await this.props.officeWrapper.getCurrentEmailSubject();
        const receivedDate = this.props.officeWrapper.getCurrentEmailDate();
        const attachmentDetails = await this.props.msGraphApiService.getAttachmentDetails();
        const htmlBody = AttachmentDataHelpers.replaceCIDReferences(remark, attachmentDetails);

        this.setState({
            attachments: attachmentsNoCloud,
            remarks: this.props.isFileTransferAndEditorSupported ? htmlBody.trim() : remark.trim(),
            subject: subject.trim(),
            receivedDate,
        });
    }

    private async loadProjects(): Promise<void> {
        this.setState({ isLoadingProjects: true });
        try {
            const projectsResponse = await this.props.projectsService.getProjectsSupportingRfis();
            const projectsITag: ITag[] = projectsResponse.projects.map((project) => {
                const projectDisplay = project.number ? `${project.number} - ${project.name}` : project.name;
                return { key: project.nrn, name: projectDisplay };
            });
            this.setState({ projects: projectsITag });
        } catch (error) {
            this.props.logger.error("RfiReviewResponseComponent - load projects error", error);

            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.onShowToast(
                this.props.translate("SHARED.ERRORS.LOADING_PROJECTS_GENERIC") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingProjects: false });
        }
    }

    private async onProjectSelected(selectedProject: ITag | null): Promise<void> {
        this.clearKeywords();
        const isCloudProject = this.props.nrnServiceWrapper.isCloudProject(selectedProject?.key as string);
        this.setState({ selectedProject, isCloudProject });
        this.toggleProjectTeamVisibility(isCloudProject);

        if (selectedProject) {
            this.setState({ isLoadingKeywords: true });
            await Promise.all([this.loadKeywords(selectedProject, isCloudProject), this.loadRfis(selectedProject)]);
            this.setState({ isLoadingKeywords: false });
        }
    }

    private toggleProjectTeamVisibility(cloudProject: Boolean) {
        const teamMemberDiv = document.querySelector(
            ".ms-CommandBar-secondaryCommand .ms-OverflowSet-item:nth-child(2)"
        );
        cloudProject
            ? teamMemberDiv?.classList.remove("hideProjectTeam")
            : teamMemberDiv?.classList.add("hideProjectTeam");
    }

    private clearKeywords(): void {
        this.setState({
            selectedActionKeyword: null,
            actionKeywords: [],
            selectedKeywords: [],
            keywords: [],
            selectedRfi: undefined,
            rfis: [],
        });
    }

    private async loadRfis(project: ITag): Promise<void> {
        try {
            const rfiResponse = await this.props.rfiApiService.getForwardedRfis(project.key);
            const rfis = rfiResponse.map((rfi) => ({
                name: rfi.number,
                key: rfi.nrn,
                description: rfi.subject,
            }));
            this.setState({ rfis });
        } catch (error) {
            this.props.logger.error("RfiReviewResponseComponent - load RFIs error", error);

            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.onShowToast(
                this.props.translate("RFI.RESPONSE.FAILED_LOADING_RFIS") as string,
                MessageBarType.severeWarning
            );
        }
    }

    private async loadKeywords(project: ITag, isCloudProject: boolean): Promise<void> {
        const actionType = isCloudProject ? WorkflowActionType.ReviewResponse : WorkflowActionType.ReviewResponseNpc;
        try {
            const workflowActionKeywordsResponse = await this.props.rfiApiService.getRfiActionKeywords(
                project.key,
                actionType
            );
            const sortedWorkflowActionKeywords = ProjectHelper.sortKeywords(workflowActionKeywordsResponse);
            const actionSortedKeywords: DetailedKeyword[] = sortedWorkflowActionKeywords.map((keyword, key) => ({
                displayOrder: key,
                name: keyword.name,
                type: keyword.type,
            }));
            this.setState({ actionKeywords: actionSortedKeywords });

            if (!isCloudProject) {
                const npcKeywordsResponse = await this.props.projectsService.getProjectKeywords(
                    project.key,
                    KeywordListType.RfiKeywords
                );
                const sortedKeywords = ProjectHelper.sortKeywords(npcKeywordsResponse);
                const keywords: IPersonaProps[] = sortedKeywords.map((keyword) => ({
                    text: keyword.name,
                    data: keyword,
                }));
                this.setState({ keywords, allowCustomKeywords: npcKeywordsResponse.allowCustom });
            }
        } catch (error) {
            this.props.logger.error("RfiReviewResponseComponent - load keywords error", error);

            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.onShowToast(
                this.props.translate("SHARED.ERRORS.FAILED_LOADING_KEYWORDS") as string,
                MessageBarType.severeWarning
            );
        }
    }

    private onSubjectChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({ subject: newValue || "" });
    }

    private onReceivedDateChanged(date: Date | undefined): void {
        this.setState({ receivedDate: date });
    }

    private onFromChange(people: IPersonaProps[]): void {
        this.setState({ from: people });
    }

    private onToChange(people: IPersonaProps[]): void {
        this.setState({ to: people });
    }

    private onCcChange(people: IPersonaProps[]): void {
        this.setState({ cc: people });
    }

    private onAttachmentsUpdated(files: AttachmentItem[]): void {
        this.setState({
            attachments: files,
        });
        document.querySelector(".rfiReviewResponseComponent")?.scrollTo({
            top: document.querySelector(".rfiReviewResponseComponent")?.scrollHeight,
            behavior: "auto",
        });
    }

    private onRemarksChange(newValue?: string): void {
        this.setState({ remarks: newValue || "" });
    }

    private onOldEditorRemarksChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ remarks: newValue || "" });
    }

    private onActionChange(option: DetailedKeyword | null): void {
        this.setState({ selectedActionKeyword: option });
    }

    private getKeywordPlaceholder(items: any[], selectText: string): string {
        if (!this.state.selectedProject) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.NO_PROJECT_SELECTED") as string;
        }

        if (this.state.isLoadingKeywords) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.LOADING") as string;
        }

        if (!items.length) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.NO_ITEMS") as string;
        }

        return selectText;
    }

    onRenderRfiNumbers(props: ITag, itemProps: ISuggestionItemProps<any>): JSX.Element {
        return <SuggestedItemWithSubtextComponent primaryText={props.name} subtext={(props as any).description} />;
    }

    async onRfisFiltered(filter: string, selectedItems?: ITag[]): Promise<ITag[]> {
        if (!filter) {
            return this.state.rfis;
        }

        return this.state.rfis.filter((x) => {
            return (
                x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
                (x as any).description.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
            );
        });
    }

    private onRfiSelected(selectedItem?: ITag): ITag | null {
        if (!selectedItem) {
            this.setState({ selectedRfi: undefined, selectedKeywords: [] });
            return null;
        }

        if (!this.state.isCloudProject && !!this.state.selectedProject) {
            this.props.rfiApiService
                .getRfiDetails(selectedItem.key as string, this.state.selectedProject.key as string)
                .then((rfiDetails) => {
                    const keywords = rfiDetails.keywords.map((keyword) => ({ text: keyword.name, data: keyword }));
                    this.setState({ selectedKeywords: keywords });
                })
                .catch((error) => {
                    this.handleApiError(error);
                });
        }

        this.setState({
            selectedRfi: {
                ...selectedItem,
                name: `${selectedItem.name} ${(selectedItem as any).description}`.trim(),
            },
        });

        return selectedItem;
    }

    private onRfiChange(items?: ITag[]): void {
        if (!items?.length) {
            this.setState({ selectedRfi: undefined, selectedKeywords: [] });
        }
    }

    private onKeywordsChanged(items: IPersonaProps[]): void {
        this.setState({ selectedKeywords: items });
    }

    private onEmptyRfiNumberInputFocus(selectedItems?: ITag[]): ITag[] {
        return this.state.rfis;
    }

    private async onFormSubmit(): Promise<void> {
        this.props.logger.info("File Email as RFI Response form submitted");
        this.setState({ isFiling: true });
        try {
            const messageNrn = this.props.officeWrapper.getCurrentMessageNrn();
            const rfiDetails = await this.props.rfiApiService.getRfiDetails(
                this.state.selectedRfi?.key as string,
                this.state.selectedProject?.key as string
            );
            const requestParams: RfiReviewResponseRequestParams = {
                from: this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.from)[0],
                to: this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.to),
                cc: this.state.cc.length
                    ? this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.cc)
                    : undefined,
                action: this.state.isCloudProject
                    ? this.props.formValidationHelpers.mapDetailedKeywordToKeyword(
                          this.state.selectedActionKeyword as DetailedKeyword
                      )
                    : undefined,
                response: this.state.isCloudProject
                    ? this.state.remarks.trim()
                    : this.props.formValidationHelpers.mapDetailedKeywordToKeyword(
                          this.state.selectedActionKeyword as DetailedKeyword
                      ),
                keywords: !this.state.isCloudProject
                    ? this.props.formValidationHelpers.mapPersonaPropsToKeywords(this.state.selectedKeywords)
                    : undefined,
                remarks: !this.state.isCloudProject ? this.state.remarks : undefined,
                version: rfiDetails.version,
                subject: !this.state.isCloudProject ? (this.state.subject as string).trim() : undefined,
                receivedDate: this.state.isCloudProject
                    ? undefined
                    : this.props.dateHelpers.getNewDateAtStartOfDay(this.state.receivedDate as Date).toISOString(),
                via: this.state.isCloudProject ? undefined : { name: "Email", type: "transferMethod" },
            };
            await this.props.rfiApiService.logEmailAsRfiReviewResponse(
                this.state.selectedProject?.key as string,
                messageNrn,
                this.state.selectedRfi?.key as string,
                requestParams,
                this.state.attachments,
                this.fileUploadCallback.bind(this),
                this.props.isFileTransferAndEditorSupported
            );

            try {
                const projectAsAny = this.state.selectedProject as any;
                this.props.analyticsManager.recordSmartFilingEvents(
                    projectAsAny.suggestedProject,
                    projectAsAny.suggestionIndex
                );
                await this.props.smartFilingManager.addToFiledHistory(
                    this.state.selectedProject ?? { key: "", name: "" },
                    this.props.mailboxItem?.conversationId as string,
                    this.props.mailboxItem?.sender.emailAddress as string
                );
            } catch (error) {
                this.props.logger.error("RfiReviewResponseComponent - failed to update smart filing history", error);
            }

            this.props.onShowToast(
                this.props.translate("RFI.RESPONSE.SUCCESS_MESSAGE") as string,
                MessageBarType.success
            );
            this.setState({
                selectedRfi: undefined,
                selectedActionKeyword: null,
                selectedKeywords: [],
            });
        } catch (error) {
            this.handleApiError(error);
        } finally {
            this.setState({
                isFiling: false,
                fileUploadIsInProgress: false,
            });
        }
    }

    isFormValid(): boolean {
        const {
            selectedProject,
            selectedRfi,
            selectedActionKeyword,
            from,
            to,
            cc,
            isFiling,
            isLoadingProjects,
            isLoadingKeywords,
            actionKeywords,
            subject,
            receivedDate,
            isCloudProject,
        } = this.state;
        return (
            !!selectedProject &&
            !!selectedRfi &&
            selectedActionKeyword !== null &&
            !!from.length &&
            !!to.length &&
            !!actionKeywords &&
            !isFiling &&
            !isLoadingProjects &&
            !isLoadingKeywords &&
            (isCloudProject ? true : !!subject) &&
            (isCloudProject ? true : !!receivedDate) &&
            this.props.formValidationHelpers.areAssigneesValid(from, true) &&
            this.props.formValidationHelpers.areAssigneesValid(to, true) &&
            this.props.formValidationHelpers.areAssigneesValid(cc, false)
        );
    }

    private handleApiError(error: any): void {
        this.props.logger.error("RfiReviewResponseComponent API error", error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        if (error.level !== undefined && error.level === ApiRequestErrorLevel.WARNING) {
            this.props.onShowToast(this.props.translate(error.messageToDisplay) as string, MessageBarType.warning);
            return;
        }

        if (error.level !== undefined && error.level === ApiRequestErrorLevel.ERROR) {
            const failedAttachmentNames = this.state.failedUploadAttachmentNames;
            if (failedAttachmentNames.length) {
                const messageToDisplay = (this.props.translate(error.messageToDisplay) as string)
                    .replace("[[attachment-names]]", failedAttachmentNames.join("\n"))
                    .replace("[[failed-attachment-count]]", failedAttachmentNames.length.toString());
                this.props.onShowToast(messageToDisplay, MessageBarType.severeWarning);
                return;
            }
            this.props.onShowToast(
                this.props.translate(error.messageToDisplay) as string,
                MessageBarType.severeWarning
            );
            return;
        }

        if (error.level === undefined) {
            this.props.onShowToast(
                this.props.translate("RFI.RESPONSE.FAILED_GENERIC") as string,
                MessageBarType.severeWarning
            );
        }
    }

    fileUploadCallback(isInProgress: boolean, failedIds: string[]) {
        const failedAttachmentNames = !isInProgress ? this.getFailedAttachmentNames(failedIds) : [];
        this.setState({
            fileUploadIsInProgress: isInProgress,
            failedUploadAttachmentNames: failedAttachmentNames,
        });
    }

    private getFailedAttachmentNames(failedIds: string[]): string[] {
        return failedIds.length > 0
            ? this.state.attachments.filter((x) => failedIds.includes(x.id)).map((x) => x.name)
            : [];
    }

    private async refreshProjects(): Promise<void> {
        await this.props.invalidateCacheService.invalidateCache(ProjectsCacheKeys.rfisCacheName);
        return this.loadProjects();
    }

    render(): JSX.Element {
        return (
            <div className="rfiReviewResponseComponent">
                <div className="newforma-form newforma-rfiReviewResponseForm">
                    <SuggestedProjectPickerComponent
                        logger={this.props.logger}
                        className="newforma-formSpacing"
                        projects={this.state.projects}
                        onProjectSelected={this.onProjectSelected.bind(this)}
                        disabled={this.state.isLoadingProjects || this.state.isFiling || this.state.isLoadingKeywords}
                        smartFilingManager={this.props.smartFilingManager}
                        isLoadingProjects={this.state.isLoadingProjects}
                        mailboxItem={this.props.mailboxItem}
                        onRefresh={this.refreshProjects.bind(this)}
                        theme={this.props.theme}
                        myProjects={this.state.projects}
                    />
                    {!this.state.isCloudProject ? (
                        <div>
                            <Label required={true}>
                                {this.props.translate("RFI.RESPONSE.RFI_SUBJECT_LABEL") as string}
                            </Label>
                            <TextField
                                className="newforma-formSpacing"
                                id="rfiReviewResponse-subject"
                                value={this.state.subject}
                                onChange={this.onSubjectChange.bind(this)}
                                disabled={this.state.isFiling || this.state.isLoadingProjects}
                                maxLength={256}
                            />
                        </div>
                    ) : null}
                    <LabelComponent text={this.props.translate("RFI.RESPONSE.RFI_NUMBER") as string} required={true} />
                    <TagPicker
                        className={`newforma-rfiNumber newforma-formSpacing ${
                            !this.state.selectedProject || this.state.isLoadingKeywords || !this.state.rfis.length
                                ? `disabledStyle`
                                : ``
                        }`}
                        selectedItems={this.state.selectedRfi ? [this.state.selectedRfi] : []}
                        pickerSuggestionsProps={{
                            suggestionsHeaderText: this.props.translate(
                                "RFI.RESPONSE.RFI_NUMBER_PICKER_HEADER"
                            ) as string,
                            noResultsFoundText: this.props.translate("RFI.RESPONSE.RFI_NUMBER_NONE") as string,
                        }}
                        onRenderSuggestionsItem={this.onRenderRfiNumbers.bind(this)}
                        disabled={
                            !this.state.selectedProject ||
                            this.state.isLoadingKeywords ||
                            this.state.isFiling ||
                            !this.state.rfis.length
                        }
                        itemLimit={1}
                        onResolveSuggestions={this.onRfisFiltered.bind(this)}
                        onItemSelected={this.onRfiSelected.bind(this)}
                        onChange={this.onRfiChange.bind(this)}
                        pickerCalloutProps={{ calloutWidth: 280 }}
                        resolveDelay={300}
                        inputProps={{
                            placeholder: this.getKeywordPlaceholder(
                                this.state.rfis,
                                this.props.translate("RFI.RESPONSE.RFI_NUMBER_PLACEHOLDER") as string
                            ),
                        }}
                        onEmptyInputFocus={this.onEmptyRfiNumberInputFocus.bind(this)}
                    />
                    <TranslatedDatePickerComponent
                        date={this.state.receivedDate}
                        label={this.props.translate("RFI.RESPONSE.RECEIVED_DATE_LABEL") as string}
                        onDateChange={this.onReceivedDateChanged.bind(this)}
                        className={`newforma-subReviewResponseReceivedDate newforma-formSpacing ${
                            this.state.isCloudProject ? "newforma-none" : ""
                        }`}
                        disabled={this.state.isFiling || this.state.isCloudProject}
                        required={!this.state.isCloudProject}
                        clearDateButtonVisibility={ElementVisibility.None}
                    />
                    <KeywordsDropdown
                        className="newforma-rfiActionDropdown newforma-formSpacing"
                        id="rfiReviewResponse-actionDropdown"
                        options={this.state.actionKeywords}
                        label={this.props.translate("RFI.RESPONSE.RESPONSE_LABEL") as string}
                        placeholder={this.props.translate("RFI.RESPONSE.RESPONSE_PLACEHOLDER") as string}
                        disabled={
                            !this.state.selectedProject ||
                            this.state.isLoadingKeywords ||
                            this.state.isFiling ||
                            !this.state.actionKeywords.length
                        }
                        isLoading={this.state.isLoadingKeywords}
                        isProjectSelected={!!this.state.selectedProject}
                        required={true}
                        onSelectionChange={this.onActionChange.bind(this)}
                        selectFirstOption={false}
                        theme={this.props.theme}
                        selectedPurposeKey={
                            this.state.selectedActionKeyword ? this.state.selectedActionKeyword.displayOrder : null
                        }
                    />
                    <FromAndToComponent
                        className={"newforma-formSpacing"}
                        isFromRequired={true}
                        isToRequired={true}
                        isCcRequired={false}
                        includeCc={true}
                        officeWrapper={this.props.officeWrapper}
                        disabled={this.state.isFiling}
                        formValidationHelpers={this.props.formValidationHelpers}
                        mailboxItem={this.props.mailboxItem}
                        onFromChange={this.onFromChange.bind(this)}
                        onToChange={this.onToChange.bind(this)}
                        onCcChange={this.onCcChange.bind(this)}
                        logger={this.props.logger}
                        projectsService={this.props.projectsService}
                        onError={this.props.onShowToast}
                        project={this.state.selectedProject}
                        shouldHideFrom={false}
                    />
                    <Label required={false}>{this.props.translate("RFI.RESPONSE.REMARK_LABEL") as string}</Label>
                    {this.props.isFileTransferAndEditorSupported ? (
                        <HTMLEditor
                            value={this.state.remarks}
                            onRemarksUpdate={this.onRemarksChange.bind(this)}
                            configService={this.props.configService}
                            isFiling={this.state.isFiling}
                        />
                    ) : (
                        <TextField
                            className="newforma-formSpacing"
                            id="rfiReviewResponse-remarks"
                            multiline
                            resizable={true}
                            value={this.state.remarks}
                            onChange={this.onOldEditorRemarksChange.bind(this)}
                            disabled={this.state.isFiling}
                            rows={5}
                            maxLength={65000}
                        />
                    )}
                    {!this.state.isCloudProject ? (
                        <ChipPickerComponent
                            className="newforma-formSpacing newforma-submittalRevResponseKeywords"
                            required={false}
                            disabled={
                                this.state.isFiling || this.state.isLoadingKeywords || !this.state.selectedProject
                            }
                            items={this.state.keywords}
                            selectedItems={this.state.selectedKeywords}
                            onSelectedItemsChanged={this.onKeywordsChanged.bind(this)}
                            label={this.props.translate("SUBMITTALS.KEYWORDS_LABEL") as string}
                            allowCustomInput={this.state.allowCustomKeywords}
                        />
                    ) : null}
                    <AttachmentsComponent
                        attachments={this.state.attachments}
                        disabled={this.state.isFiling}
                        allowFileUploads={true}
                        onAttachmentsUpdated={this.onAttachmentsUpdated.bind(this)}
                        isCloudProject={this.state.isCloudProject}
                        logger={this.props.logger}
                        isFilePathInAttachmentsSupported={this.props.isFilePathInAttachmentsSupported}
                        classNameForScroll=".rfiReviewResponseComponent"
                    />
                </div>
                {this.state.fileUploadIsInProgress ? (
                    <ProgressIndicator
                        label={this.props.translate("SHARED.FILE_UPLOAD_PROGRESS_LABEL") as string}
                        className="newforma-progressIndicator"
                        styles={{
                            itemName: ".ms-label",
                        }}
                    />
                ) : null}
                <div id="footer" key="footer" className="newforma-footer">
                    <DefaultButton
                        className="newforma-footerButton"
                        id="fileAsRfiReviewResponseButton"
                        primary={true}
                        onClick={this.onFormSubmit.bind(this)}
                        text={(this.props.translate("RFI.RESPONSE.SUBMIT_BUTTON") as string).toLocaleUpperCase()}
                        disabled={!this.isFormValid()}
                    />
                </div>
            </div>
        );
    }
}

export default withLocalize(RfiReviewResponseComponent);

import { IndexedDbHelper } from "../../helpers/IndexedDbHelper";
import { KeywordListType, Paging, ProjectKeywordsResponse } from "../../models/ProjectKeywordsResponse";
import { ContractsResponse } from "../../models/projects/ContractsResponse";
import { ProjectSettingsResponse } from "../../models/projects/ProjectSettingsResponse";
import { Project, ProjectsResponse } from "../../models/ProjectsResponse";
import { CreateProjectTeamMembersResponse } from "../../models/projectTeam/CreateProjectTeamMembersResponse";
import { TeamMemberNormalized } from "../../models/projectTeam/TeamViewResponse";
import { ProjectsCacheKeys } from "../../models/StorageKeys";
import { Logger } from "../Logger";
import { IProjectsService } from "./IProjectsService";

export class ProjectsCacheService implements IProjectsService {
    constructor(
        private readonly projectService: IProjectsService,
        private readonly logger: Logger,
        private readonly indexedDbHelper: IndexedDbHelper,
        private readonly maxCacheLifetimeMs: number
    ) {}

    getMyProjects(): Promise<ProjectsResponse> {
        return this.cacheDataUsingIndexedDB(ProjectsCacheKeys.myProjectsCacheName, () =>
            this.projectService.getMyProjects()
        );
    }
    getGlobalProjects(paging: Paging): Promise<ProjectsResponse> {
        return this.cacheDataUsingIndexedDB(
            ProjectsCacheKeys.globalProjectsCacheName,
            () => this.projectService.getGlobalProjects(paging),
            paging
        );
    }

    getProjectsSupportingActionItems(): Promise<ProjectsResponse> {
        return this.cacheDataUsingIndexedDB(ProjectsCacheKeys.actionItemsCacheName, () =>
            this.projectService.getProjectsSupportingActionItems()
        );
    }

    getProjectsSupportingSubmittals(): Promise<ProjectsResponse> {
        return this.cacheDataUsingIndexedDB(ProjectsCacheKeys.submittalsCacheName, () =>
            this.projectService.getProjectsSupportingSubmittals()
        );
    }

    getProjectsSupportingRfis(): Promise<ProjectsResponse> {
        return this.cacheDataUsingIndexedDB(ProjectsCacheKeys.rfisCacheName, () =>
            this.projectService.getProjectsSupportingRfis()
        );
    }
    getProjectsSupportingFileTransfer(): Promise<ProjectsResponse> {
        return this.cacheDataUsingIndexedDB(ProjectsCacheKeys.fileTransferCacheName, () =>
            this.projectService.getProjectsSupportingFileTransfer()
        );
    }

    getProjectsSupportingSearch(disableCache: boolean): Promise<ProjectsResponse> {
        return this.projectService.getProjectsSupportingSearch(disableCache);
    }

    getProjectEmailFilingAddress(projectNrn: string): Promise<string | null> {
        return this.projectService.getProjectEmailFilingAddress(projectNrn);
    }

    getProjects(): Promise<ProjectsResponse> {
        return this.projectService.getProjects();
    }

    getInternalCloudProjects(): Promise<Project[]> {
        return this.projectService.getInternalCloudProjects();
    }

    getProjectKeywords(
        projectNrn: string,
        keywordListType: KeywordListType,
        query?: string
    ): Promise<ProjectKeywordsResponse> {
        return this.projectService.getProjectKeywords(projectNrn, keywordListType, query);
    }

    getProjectSettings(projectNrn: string): Promise<ProjectSettingsResponse | null> {
        return this.projectService.getProjectSettings(projectNrn);
    }

    getProjectSubmittalPurposesKeywords(projectNrn: string): Promise<ProjectKeywordsResponse> {
        return this.projectService.getProjectSubmittalPurposesKeywords(projectNrn);
    }

    getProjectForwardSubmittalPurposesKeywords(projectNrn: string): Promise<ProjectKeywordsResponse> {
        return this.projectService.getProjectForwardSubmittalPurposesKeywords(projectNrn);
    }

    getAllTeamMembersNormalized(projectNrn: string, query: string = ""): Promise<TeamMemberNormalized[]> {
        return this.projectService.getAllTeamMembersNormalized(projectNrn, query);
    }

    getTeamMembersNormalized(projectNrn: string, query: string = ""): Promise<TeamMemberNormalized[]> {
        return this.projectService.getTeamMembersNormalized(projectNrn, query);
    }

    getContracts(projectNrn: string): Promise<ContractsResponse> {
        return this.projectService.getContracts(projectNrn);
    }

    createProjectTeamMembers(emails: string[], projectNrn: string): Promise<CreateProjectTeamMembersResponse> {
        return this.projectService.createProjectTeamMembers(emails, projectNrn);
    }

    private async cacheDataUsingIndexedDB<T>(
        cacheName: string,
        getData: () => Promise<T>,
        paging?: Paging
    ): Promise<T> {
        if (!this.indexedDbHelper.isSupported()) {
            console.warn("This browser doesn't support IndexedDB.");
            this.logger.warning("This browser doesn't support IndexedDB.");
            return getData();
        }
        let db: IDBDatabase | undefined;
        try {
            db = await this.indexedDbHelper.openDb();
            const cachedData = await this.tryGetCachedIndexedDb<T>(db, this.getFullCacheName(cacheName, paging));
            if (cachedData) {
                return cachedData;
            }
        } catch (error) {
            this.logger.warning("Something went wrong with indexedDB.", error);
        }
        const data = await getData();
        if (db) {
            await this.tryPutCacheIndexedDb<T>(db, cacheName, data, paging);
        }
        return data;
    }

    private getFullCacheName(cacheName: string, paging?: Paging): string {
        return paging?.offsetToken ? `${cacheName}-${paging.offsetToken}` : cacheName;
    }

    private async tryGetCachedIndexedDb<T>(db: IDBDatabase, cacheName: string): Promise<T | undefined> {
        try {
            const data = await this.getCachedIndexedDb<T>(db, cacheName);
            if (data) {
                return data;
            }
        } catch (error) {
            this.logger.warning("Something went wrong reading data from indexedDB.", error);
        }
    }
    private async getCachedIndexedDb<T>(db: IDBDatabase, cacheName: string): Promise<T | undefined> {
        const cachedValues = await this.indexedDbHelper.getData(db, cacheName);
        if (cachedValues && this.isValid(cachedValues.lastUpdatedTime)) {
            return typeof cachedValues.data === "string" ? JSON.parse(cachedValues.data) : cachedValues.data;
        }
    }

    private async tryPutCacheIndexedDb<T>(db: IDBDatabase, cacheName: string, data: T, paging?: Paging): Promise<void> {
        try {
            const fullCacheName = this.getFullCacheName(cacheName, paging);
            const cachedValues = await this.indexedDbHelper.getData(db, fullCacheName);
            await this.indexedDbHelper.putData(db, {
                cacheName: fullCacheName,
                data: data,
                lastUpdatedTime: new Date().getTime(),
            });
            if (!cachedValues) {
                // fresh values
                return;
            }
            const newNextPaging = (data as any).paging as Paging | undefined;
            if (!newNextPaging?.offsetToken) {
                // last page from NL
                return;
            }
            const cachedData = cachedValues.data;
            const oldNextPage = cachedData?.paging as Paging | undefined;
            if (!oldNextPage?.offsetToken) {
                // last cached page
                return;
            }
            const fullOldNextPage = this.getFullCacheName(cacheName, oldNextPage);
            const nextPageCachedValues = await this.indexedDbHelper.getData(db, fullOldNextPage);
            if (!nextPageCachedValues) {
                this.logger.warning(`Can't get cached data from old next page. Cache name: ${fullOldNextPage}.`);
                return;
            }
            const fullNewNextPage = this.getFullCacheName(cacheName, newNextPaging);
            if (fullNewNextPage === fullOldNextPage) {
                return;
            }
            await this.indexedDbHelper.putData(db, {
                cacheName: fullNewNextPage,
                data: nextPageCachedValues.data,
                lastUpdatedTime: nextPageCachedValues.lastUpdatedTime,
            });
            await this.indexedDbHelper.removeData(db, fullOldNextPage);
        } catch (error) {
            this.logger.warning("Something went wrong writing data to indexedDB.", error);
        }
    }

    private isValid(lastUpdatedTime: number): boolean {
        if (!Number.isInteger(lastUpdatedTime)) {
            return false;
        }
        const cacheLifetimeInHours = this.calculateLastStoredTime(lastUpdatedTime);
        return cacheLifetimeInHours < this.maxCacheLifetimeMs;
    }

    private calculateLastStoredTime(storedTime: number) {
        const currentTime = new Date().getTime();
        return currentTime - storedTime;
    }
}

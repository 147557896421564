import { AttachmentDetailsMetadata } from "../../models/AttachmentDetailsMetadata";

export function mapAttachmentsMetadataToAttachment(
    attachmentsDetails: AttachmentDetailsMetadata[],
    isLastModifiedDateSupported?: boolean
) {
    if (isLastModifiedDateSupported) {
        return attachmentsDetails.map((x) => ({
            fileName: x.fileName,
            sizeInBytes: x.file.size,
            contentType: x.file.type || "application/octet-stream",
            lastModifiedDate: x.lastModifiedDate,
        }));
    } else {
        return attachmentsDetails.map((x) => ({
            fileName: x.fileName,
            sizeInBytes: x.file.size,
            contentType: x.file.type || "application/octet-stream",
        }));
    }
}

import { KeywordListType, Paging, ProjectKeywordsResponse } from "../../models/ProjectKeywordsResponse";
import { ContractsResponse } from "../../models/projects/ContractsResponse";
import { ProjectSettingsResponse } from "../../models/projects/ProjectSettingsResponse";
import { Project, ProjectsResponse } from "../../models/ProjectsResponse";
import { CreateProjectTeamMembersResponse } from "../../models/projectTeam/CreateProjectTeamMembersResponse";
import { TeamMemberNormalized } from "../../models/projectTeam/TeamViewResponse";
import { Logger } from "../Logger";
import { IProjectsService } from "./IProjectsService";
import * as pRetry from "p-retry";

export class ProjectsRetryService implements IProjectsService {
    private readonly retryOptions: pRetry.Options;

    constructor(
        private readonly projectService: IProjectsService,
        private readonly logger: Logger,
        minTimeout?: number,
        maxTimeout?: number
    ) {
        this.retryOptions = {
            retries: 5,
            minTimeout,
            maxTimeout,
            onFailedAttempt: (error) => {
                this.logger.warning(
                    `Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`,
                    error
                );
            },
        };
    }

    getMyProjects(): Promise<ProjectsResponse> {
        return this.retry(() => this.projectService.getMyProjects());
    }
    getGlobalProjects(paging: Paging): Promise<ProjectsResponse> {
        return this.retry(() => this.projectService.getGlobalProjects(paging));
    }

    getProjectsSupportingActionItems(): Promise<ProjectsResponse> {
        return this.retry(() => this.projectService.getProjectsSupportingActionItems());
    }

    getProjectsSupportingSubmittals(): Promise<ProjectsResponse> {
        return this.retry(() => this.projectService.getProjectsSupportingSubmittals());
    }

    getProjectsSupportingRfis(): Promise<ProjectsResponse> {
        return this.retry(() => this.projectService.getProjectsSupportingRfis());
    }
    getProjectsSupportingFileTransfer(): Promise<ProjectsResponse> {
        return this.retry(() => this.projectService.getProjectsSupportingFileTransfer());
    }

    getProjectsSupportingSearch(disableCache: boolean): Promise<ProjectsResponse> {
        return this.retry(() => this.projectService.getProjectsSupportingSearch(disableCache));
    }

    getProjectEmailFilingAddress(projectNrn: string): Promise<string | null> {
        return this.retry(() => this.projectService.getProjectEmailFilingAddress(projectNrn));
    }

    getProjects(): Promise<ProjectsResponse> {
        return this.retry(() => this.projectService.getProjects());
    }

    getInternalCloudProjects(): Promise<Project[]> {
        return this.retry(() => this.projectService.getInternalCloudProjects());
    }

    getProjectKeywords(
        projectNrn: string,
        keywordListType: KeywordListType,
        query?: string
    ): Promise<ProjectKeywordsResponse> {
        return this.retry(() => this.projectService.getProjectKeywords(projectNrn, keywordListType, query));
    }

    getProjectSettings(projectNrn: string): Promise<ProjectSettingsResponse | null> {
        return this.retry(() => this.projectService.getProjectSettings(projectNrn));
    }

    getProjectSubmittalPurposesKeywords(projectNrn: string): Promise<ProjectKeywordsResponse> {
        return this.retry(() => this.projectService.getProjectSubmittalPurposesKeywords(projectNrn));
    }

    getProjectForwardSubmittalPurposesKeywords(projectNrn: string): Promise<ProjectKeywordsResponse> {
        return this.retry(() => this.projectService.getProjectForwardSubmittalPurposesKeywords(projectNrn));
    }

    getAllTeamMembersNormalized(projectNrn: string, query: string = ""): Promise<TeamMemberNormalized[]> {
        return this.retry(() => this.projectService.getAllTeamMembersNormalized(projectNrn, query));
    }

    getTeamMembersNormalized(projectNrn: string, query: string = ""): Promise<TeamMemberNormalized[]> {
        return this.retry(() => this.projectService.getTeamMembersNormalized(projectNrn, query));
    }

    getContracts(projectNrn: string): Promise<ContractsResponse> {
        return this.retry(() => this.projectService.getContracts(projectNrn));
    }

    createProjectTeamMembers(emails: string[], projectNrn: string): Promise<CreateProjectTeamMembersResponse> {
        return this.retry(() => this.projectService.createProjectTeamMembers(emails, projectNrn));
    }

    private retry<T>(getData: () => Promise<T>): Promise<T> {
        return pRetry(async () => {
            try {
                return await getData();
            } catch (error) {
                const castedError = error as Error & { statusCode: number };
                if (
                    castedError.statusCode &&
                    castedError.statusCode !== 408 &&
                    castedError.statusCode !== 500 &&
                    castedError.statusCode !== 502 &&
                    castedError.statusCode !== 504
                ) {
                    throw new pRetry.AbortError(castedError);
                }
                throw error;
            }
        }, this.retryOptions);
    }
}

import { FilingLocation } from "../../models/FilingLocationsResponse";
import { OfficeWrapper } from "../../services/OfficeWrapper";
import { Logger } from "../../services/Logger";
import { IProjectsService } from "../../services/NewformaApi/IProjectsService";
import EmailAddressDetails = Office.EmailAddressDetails;

export class SendAndFileHelpers {
    private lastSelectedProjectEmailAddress: string | null = null;
    private officeEnumRecipient = "externalUser";
    private officeEnumResponse = "none";
    private updateRecipientsWithProjectEmailAddressInProgress: Promise<void> | null = null;

    constructor(
        private officeWrapper: OfficeWrapper,
        private logger: Logger,
        private projectsService: IProjectsService
    ) {}

    async updateRecipientsWithProjectEmailAddress(filingLocation: FilingLocation | null): Promise<void> {
        if (!this.updateRecipientsWithProjectEmailAddressInProgress) {
            this.updateRecipientsWithProjectEmailAddressInProgress = this.updateRecipientsWithProjectEmailAddressImpl(
                filingLocation
            ).finally(() => {
                this.updateRecipientsWithProjectEmailAddressInProgress = null;
            });
            return;
        }

        this.updateRecipientsWithProjectEmailAddressInProgress = this.updateRecipientsWithProjectEmailAddressInProgress.then(
            () => {
                return this.updateRecipientsWithProjectEmailAddressImpl(filingLocation);
            }
        );
    }

    async addUniqueEmailsTo(emails: EmailAddressDetails[]): Promise<void> {
        const currentRecipients = await this.officeWrapper.getToAsync();
        const filteredRecipients = currentRecipients.filter(
            (recipient) => !emails.some((email) => recipient.emailAddress === email.emailAddress)
        );

        await this.officeWrapper.setToAsync([...filteredRecipients, ...emails]);
    }

    async addUniqueEmailsCc(emails: EmailAddressDetails[]): Promise<void> {
        const currentRecipients = await this.officeWrapper.getCcAsync();
        const filteredRecipients = currentRecipients.filter(
            (recipient) => !emails.some((email) => recipient.emailAddress === email.emailAddress)
        );

        await this.officeWrapper.setCcAsync([...filteredRecipients, ...emails]);
    }

    private async updateRecipientsWithProjectEmailAddressImpl(filingLocation: FilingLocation | null): Promise<void> {
        let toRecipients = await this.officeWrapper.getToAsync();
        let ccRecipients = await this.officeWrapper.getCcAsync();

        if (!filingLocation) {
            await this.removeEmailAddressFromRecipients(ccRecipients, toRecipients);

            this.lastSelectedProjectEmailAddress = null;
            return;
        }

        const result = await this.removeEmailAddressFromRecipients(ccRecipients, toRecipients);
        ccRecipients = result.ccRecipients;
        toRecipients = result.toRecipients;

        const filingAddress = await this.projectsService.getProjectEmailFilingAddress(filingLocation.nrn);

        if (!filingAddress) {
            this.logger.info(`email filing address not set for project ${filingLocation.name}. returning`);
            return;
        }

        this.lastSelectedProjectEmailAddress = filingAddress;

        if (
            toRecipients.some((x) => x.emailAddress === filingAddress) ||
            ccRecipients.some((x) => x.emailAddress === filingAddress)
        ) {
            this.logger.info(`email filing address ${filingAddress} already in email recipients. returning`);
            return;
        }

        const emailDetailToAdd = {
            displayName: filingLocation.name,
            emailAddress: filingAddress,
            appointmentResponse: this.officeEnumResponse,
            recipientType: this.officeEnumRecipient,
        };
        ccRecipients.push(emailDetailToAdd);

        this.logger.info(`adding project email address ${filingAddress} to cc field`);
        await this.officeWrapper.setCcAsync(ccRecipients);
    }

    private async removeEmailAddressFromRecipients(
        ccRecipients: EmailAddressDetails[],
        toRecipients: EmailAddressDetails[]
    ): Promise<{ ccRecipients: EmailAddressDetails[]; toRecipients: EmailAddressDetails[] }> {
        let filteredCcRecipients: EmailAddressDetails[] = ccRecipients;
        let filteredToRecipients: EmailAddressDetails[] = toRecipients;

        if (ccRecipients.some((x) => x.emailAddress === this.lastSelectedProjectEmailAddress)) {
            this.logger.info(
                `removing project email address ${this.lastSelectedProjectEmailAddress} from cc recipients`
            );

            const filteredRecipients = ccRecipients.filter(
                (x) => x.emailAddress !== this.lastSelectedProjectEmailAddress
            );
            await this.officeWrapper.setCcAsync(filteredRecipients);
            filteredCcRecipients = filteredRecipients;
        }
        if (toRecipients.some((x) => x.emailAddress === this.lastSelectedProjectEmailAddress)) {
            this.logger.info(
                `removing project email address ${this.lastSelectedProjectEmailAddress} from to recipients`
            );

            const filteredRecipients = toRecipients.filter(
                (x) => x.emailAddress !== this.lastSelectedProjectEmailAddress
            );
            await this.officeWrapper.setToAsync(filteredRecipients);
            filteredToRecipients = filteredRecipients;
        }

        return {
            ccRecipients: filteredCcRecipients,
            toRecipients: filteredToRecipients,
        };
    }
}

/* istanbul ignore file */
import * as React from "react";

export interface InnerHtmlComponentProps {
    htmlToRender: string;
}

export default class InnerHtmlComponent extends React.Component<InnerHtmlComponentProps, any> {
    constructor(props: InnerHtmlComponentProps, context: any) {
        super(props, context);
    }

    render(): JSX.Element {
        return <span dangerouslySetInnerHTML={{ __html: this.props.htmlToRender }} />;
    }
}

import { MessageBarType } from "office-ui-fabric-react";

export function getClassForToast(
    toastType: MessageBarType | undefined,
    translatedMessageLength: number
): string | undefined {
    const result =
        toastType === MessageBarType.success || toastType === MessageBarType.severeWarning
            ? toastType === MessageBarType.severeWarning
                ? `${getLength(translatedMessageLength)} newforma-messageBar`
                : "newforma-messageBar"
            : "newforma-messageBarInfo";
    return result;
}

function getLength(translatedMessageLength: number) {
    if (translatedMessageLength < 50) {
        return "newforma-messageBar-oneLine";
    } else {
        return "";
    }
}

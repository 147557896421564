import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Label } from "office-ui-fabric-react";
import "./InfoComponent.less";

export interface InfoComponentProps extends LocalizeContextProps {
    primaryMessage: string;
    secondaryMessage?: string;
    icon?: string;
}

class InfoComponent extends React.Component<InfoComponentProps> {
    constructor(props: InfoComponentProps) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <div className="newforma-infoComponent">
                {this.props.icon ? <img src={`assets/${this.props.icon}`} className="newforma-infoImage" /> : null}
                {this.props.primaryMessage ? (
                    <Label className="newforma-infoPrimary">{this.props.primaryMessage}</Label>
                ) : null}
                {this.props.secondaryMessage ? (
                    <Label className="newforma-infoSecondary">{this.props.secondaryMessage}</Label>
                ) : null}
            </div>
        );
    }
}

export default withLocalize(InfoComponent);

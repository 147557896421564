export enum ApiRequestErrorLevel {
    WARNING,
    ERROR,
}

export class ApiRequestErrorWithMessage extends Error {
    level: ApiRequestErrorLevel;
    messageToDisplay: string;
    status: number;
    constructor(
        public message: string,
        apiRequestErrorLevel: ApiRequestErrorLevel,
        messageToDisplay: string,
        status: number
    ) {
        super(message);

        Object.setPrototypeOf(this, ApiRequestErrorWithMessage.prototype);

        this.level = apiRequestErrorLevel;
        this.messageToDisplay = messageToDisplay;
        this.status = status;
    }
}

import { renderToStaticMarkup } from "react-dom/server";
import { LocalizeContextProps } from "react-localize-redux";
import { Logger } from "./Logger";
const englishTranslations = require("./../i18n/en.json");
const germanTranslations = require("./../i18n/de.json");
const pseudoTranslations = require("./../i18n/af.json");

export enum LanguageCode {
    English = "en",
    French = "fr",
    German = "de",
    Pseudo = "af",
}

export class TranslationService {
    constructor(private logger: Logger) {}
    getTranslations(props: LocalizeContextProps) {
        // NOTE: For help with i18n, see https://ryandrewjohnson.github.io/react-localize-redux-docs/
        props.initialize({
            languages: [
                { name: "English", code: LanguageCode.English },
                { name: "French", code: LanguageCode.French },
                { name: "German", code: LanguageCode.German },
                { name: "Pseudo", code: LanguageCode.Pseudo },
            ],
            options: {
                renderToStaticMarkup,
                defaultLanguage: LanguageCode.English,
            },
        });
        let language: string = LanguageCode.English;
        try {
            language = Office.context.displayLanguage.substr(0, 2);
        } catch (error) {
            this.logger.error(
                `TranslationService. Failed to load translation strings from ${Office.context.displayLanguage}. Returning english strings.`,
                error
            );
            props.addTranslationForLanguage(englishTranslations, LanguageCode.English);
            return;
        }
        switch (language) {
            case LanguageCode.English:
                props.addTranslationForLanguage(englishTranslations, language);
                break;
            // this is being indefinitely disabled due to limited customer usage in the other languages
            // see issue: https://newforma.atlassian.net/browse/PROG-43860
            // case LanguageCode.French:
            //     props.addTranslationForLanguage(frenchTranslations, LanguageCode.French);
            //     break;
            case LanguageCode.German:
                props.addTranslationForLanguage(germanTranslations, language);
                break;
            case LanguageCode.Pseudo:
                props.addTranslationForLanguage(pseudoTranslations, language);
                break;
            default:
                this.logger.info(`unsupported language ${language} defaulting to english`);
                language = LanguageCode.English;
                props.addTranslationForLanguage(englishTranslations, language);
                break;
        }
        this.logger.info(`setting language to ${language}`);
        props.setActiveLanguage(language);
    }
}

import * as React from "react";
import "./SuggestedItemWithSubtextComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { getTheme } from "office-ui-fabric-react";

export interface SuggestedItemWithSubtextComponentProps extends LocalizeContextProps {
    primaryText: string;
    subtext: string;
}

class SuggestedItemWithSubtextComponent extends React.Component<SuggestedItemWithSubtextComponentProps, any> {
    constructor(props: SuggestedItemWithSubtextComponentProps) {
        super(props);
    }

    render(): JSX.Element {
        const theme = getTheme();
        return (
            <>
                <div className="newforma-suggestedItemWithSubtextContainer">
                    <div className="newforma-primaryText">{this.props.primaryText}</div>
                    <div
                        className="newforma-subtext"
                        style={{
                            color: `${
                                theme.palette.themeLighterAlt !== ""
                                    ? theme.palette.themeLighterAlt
                                    : theme.palette.neutralPrimary
                            }`,
                        }}
                    >
                        {this.props.subtext}
                    </div>
                </div>
            </>
        );
    }
}

export default withLocalize(SuggestedItemWithSubtextComponent);

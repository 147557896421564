import "./AddNewTeamMemberComponent.less";
import "../ProjectTeamComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { MailboxItem, OfficeWrapper } from "../../../services/OfficeWrapper";
import { Checkbox, DefaultButton, ITag, MessageBarType } from "office-ui-fabric-react";
import LabelComponent from "../../shared/label/LabelComponent";
import TeamMemberRowComponent from "../teamMemberRow/TeamMemberRowComponent";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import {
    CreateProjectTeamMemberResult,
    CreateProjectTeamMembersResponse,
} from "../../../models/projectTeam/CreateProjectTeamMembersResponse";
import { ExpiredSessionError } from "../../../models/ExpiredSessionError";
import { Logger } from "../../../services/Logger";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../../services/AnalyticsManager";
import EmailAddressDetails = Office.EmailAddressDetails;

export interface EmailAddressDetailsWithSelectedStatus extends EmailAddressDetails {
    isSelected: boolean;
}

export interface AddNewTeamMemberComponentProps extends LocalizeContextProps {
    selectedProject: ITag | null;
    officeWrapper: OfficeWrapper;
    mailboxItem: MailboxItem | null;
    projectsService: IProjectsService;
    logger: Logger;
    onExpiredSession: () => void;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    analyticsManager: AnalyticsManager;
}

export interface AddNewTeamMemberComponentState {
    contacts: EmailAddressDetailsWithSelectedStatus[];
    isAdding: boolean;
}

export const defaultAddNewTeamMemberComponentState: AddNewTeamMemberComponentState = {
    contacts: [],
    isAdding: false,
};

class AddNewTeamMemberComponent extends React.Component<
    AddNewTeamMemberComponentProps,
    AddNewTeamMemberComponentState
> {
    constructor(props: AddNewTeamMemberComponentProps, context: AddNewTeamMemberComponentState) {
        super(props, context);

        this.state = defaultAddNewTeamMemberComponentState;
    }

    async componentDidMount(): Promise<void> {
        await this.getEmailContacts();
    }

    async componentDidUpdate(
        prevProps: Readonly<AddNewTeamMemberComponentProps>,
        prevState: Readonly<AddNewTeamMemberComponentState>,
        snapshot?: any
    ): Promise<void> {
        if (prevProps.mailboxItem !== this.props.mailboxItem) {
            await this.getEmailContacts();
        }
    }

    private async getEmailContacts(): Promise<void> {
        let contacts: EmailAddressDetails[];

        const from = this.props.officeWrapper.getSender();
        const [to, cc] = await Promise.all([
            this.props.officeWrapper.getToAsync(),
            this.props.officeWrapper.getCcAsync(),
        ]);

        contacts = [...to, ...cc];

        if (!!from) {
            contacts.push(from);
        }

        const userEmail = this.props.officeWrapper.userProfileEmailAddress;
        const contactsNoUser = contacts.filter((contact) => contact.emailAddress !== userEmail);
        const contactsSorted = contactsNoUser.sort((a, b) => a.emailAddress.localeCompare(b.emailAddress));
        const uniqueContacts: EmailAddressDetails[] = [];
        contactsSorted.forEach((contact) => {
            if (!uniqueContacts.some((c) => c.emailAddress === contact.emailAddress)) {
                uniqueContacts.push(contact);
            }
        });
        const contactsWithSelectedStatus = uniqueContacts.map((contact) => ({ ...contact, isSelected: false }));

        this.setState({ contacts: contactsWithSelectedStatus });
    }

    private selectedContacts(): EmailAddressDetailsWithSelectedStatus[] {
        return this.state.contacts.filter((contact) => contact.isSelected);
    }

    private isSelectAllIndeterminate(): boolean {
        const selectedContacts = this.selectedContacts();
        return selectedContacts.length > 0 && selectedContacts.length !== this.state.contacts.length;
    }

    private areAllItemsSelected(): boolean {
        const selectedContacts = this.selectedContacts();
        return selectedContacts.length > 0 && selectedContacts.length === this.state.contacts.length;
    }

    private onSelectAllChanged(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        if (checked === undefined) {
            return;
        }

        const contacts = this.state.contacts;

        if (checked || (!checked && this.isSelectAllIndeterminate())) {
            const contactsAllSelected = contacts.map((contact) => {
                return { ...contact, isSelected: true };
            });
            this.setState({
                contacts: contactsAllSelected,
            });
            return;
        }

        if (!checked) {
            const contactsNoneSelected = contacts.map((contact) => ({ ...contact, isSelected: false }));
            this.setState({
                contacts: contactsNoneSelected,
            });
            return;
        }
    }

    private onRowSelectionStatusChange(id: string, checked: boolean): void {
        const currentContacts = this.state.contacts;
        const updatedContacts = currentContacts.map((contact) => {
            if (contact.emailAddress === id) {
                return { ...contact, isSelected: checked };
            }

            return contact;
        });

        this.setState({ contacts: updatedContacts });
    }

    private renderTeamMemberRows(): JSX.Element {
        if (!this.state.contacts.length) {
            return (
                <div className="newforma-projectTeamInfo">
                    {this.props.translate("PROJECT_TEAM.NO_CONTACTS") as string}
                </div>
            );
        }

        const teamMemberRows = this.state.contacts.map((contact, index) => {
            return (
                <TeamMemberRowComponent
                    teamMember={{
                        displayName: contact.displayName,
                        email: contact.emailAddress,
                        nrn: contact.emailAddress,
                    }}
                    isSelected={contact.isSelected}
                    onSelectionChange={this.onRowSelectionStatusChange.bind(this)}
                    key={`teamMemberKey-${index}`}
                    disabled={this.state.isAdding}
                />
            );
        });

        return <>{teamMemberRows}</>;
    }

    private isFormValid(): boolean {
        return !!this.selectedContacts().length && !!this.props.selectedProject;
    }

    private async onSubmit(): Promise<void> {
        const emailsToAdd: string[] = this.selectedContacts().map((contact) => contact.emailAddress);
        if (!emailsToAdd.length || !this.props.selectedProject?.key) {
            return;
        }

        this.setState({ isAdding: true });
        try {
            const result: CreateProjectTeamMembersResponse = await this.props.projectsService.createProjectTeamMembers(
                emailsToAdd,
                this.props.selectedProject.key
            );
            this.props.analyticsManager.recordEvent(
                AnalyticsCategoryType.UserActions,
                AnalyticsActionType.ProjectTeamAddNew,
                `count: ${emailsToAdd.length}`
            );
            const failedOperations = result.body.filter((operation) => operation.status !== 200);
            this.unselectSuccessfulContacts(failedOperations);

            if (failedOperations.length) {
                throw new Error("Adding some team members failed!");
            }

            const successMessage = (this.props.translate("PROJECT_TEAM.ADDING_TEAM_MEMBERS_SUCCESS") as string).replace(
                "[[count]]",
                `${result.body.length}`
            );
            this.props.onShowToast(successMessage, MessageBarType.success);
        } catch (error) {
            this.handleApiError(error, this.props.translate("PROJECT_TEAM.ERROR_ADDING_TEAM_MEMBERS") as string);
        } finally {
            this.setState({ isAdding: false });
        }
    }

    private unselectSuccessfulContacts(failedOperations: CreateProjectTeamMemberResult[]): void {
        const updatedContacts = this.state.contacts.map((contact) => {
            if (failedOperations.some((operation) => operation.params.email === contact.emailAddress)) {
                return contact;
            }
            return { ...contact, isSelected: false };
        });

        this.setState({ contacts: updatedContacts });
    }

    private handleApiError(error: any, messageToDisplay: string) {
        this.props.logger.error(`AddNewTeamMemberComponent API error: ${messageToDisplay}`, error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        this.props.onShowToast(messageToDisplay, MessageBarType.severeWarning);
    }

    private primaryButtonLabel(): string {
        const selectedCount = this.selectedContacts().length;
        const count = !!selectedCount ? ` (${selectedCount})` : "";
        const currentLabel = this.props.translate("PROJECT_TEAM.ADD_NEW_MEMBERS_BUTTON_LABEL") as string;
        return `${currentLabel}${count}`;
    }

    render(): JSX.Element {
        return (
            <>
                <div className="newforma-selectTeamMemberHeader">
                    <Checkbox
                        id="projectTeam-selectAll"
                        className="newforma-checkbox"
                        checked={this.areAllItemsSelected()}
                        indeterminate={this.isSelectAllIndeterminate()}
                        onChange={this.onSelectAllChanged.bind(this)}
                        disabled={!this.state.contacts.length || this.state.isAdding}
                    />
                    <LabelComponent
                        text={this.props.translate("PROJECT_TEAM.SELECT_CONTACTS") as string}
                        required={true}
                    />
                </div>
                <div className="newforma-scrollableContactList">{this.renderTeamMemberRows()}</div>
                <div id="footer" key="footer" className="newforma-footer">
                    <DefaultButton
                        className="newforma-footerButton"
                        id="addNewTeamMembersButton"
                        primary={true}
                        onClick={this.onSubmit.bind(this)}
                        text={this.primaryButtonLabel().toLocaleUpperCase()}
                        disabled={!this.isFormValid() || this.state.isAdding}
                    />
                </div>
            </>
        );
    }
}

export default withLocalize(AddNewTeamMemberComponent);

import { Checkbox } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import "./forwardCheckbox.less";

export interface ForwardCheckboxProps extends LocalizeContextProps {
    label: string;
    subtext: string;
    shouldForward: () => void;
    checked: boolean;
    isFiling: boolean;
}

class ForwardCheckbox extends React.Component<ForwardCheckboxProps> {
    constructor(props: ForwardCheckboxProps) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <div className="newforma-checkboxContainer">
                <Checkbox
                    label={this.props.label}
                    onChange={() => this.props.shouldForward()}
                    className={this.props.isFiling ? "checkboxFiling" : "checkbox"}
                    checked={this.props.checked}
                    disabled={this.props.isFiling}
                />
                <div
                    className={
                        this.props.isFiling
                            ? "newforma-subtextCheckboxContainerFiling"
                            : "newforma-subtextCheckboxContainer"
                    }
                >
                    {this.props.subtext}
                </div>
            </div>
        );
    }
}

export default withLocalize(ForwardCheckbox);

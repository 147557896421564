import AttachmentDetails = Office.AttachmentDetails;
import { Logger } from "./Logger";
import EmailAddressDetails = Office.EmailAddressDetails;

export type MailboxItem = Office.Item &
    Office.ItemCompose &
    Office.ItemRead &
    Office.MessageRead &
    Office.MessageCompose &
    Office.AppointmentRead &
    Office.AppointmentCompose;

/* istanbul ignore file */
export class OfficeWrapper {
    constructor(public logger: Logger) {}

    addHandlerAsync(
        eventType: Office.EventType,
        handler: (type: Office.EventType) => void,
        options?: Office.AsyncContextOptions,
        callback?: (asyncResult: Office.AsyncResult<void>) => void
    ) {
        const callbackFunction = callback ? callback : (asyncResult: Office.AsyncResult<void>) => null;
        Office.context.mailbox.addHandlerAsync(eventType, handler, options || {}, callbackFunction);
    }

    displayDialogAsync(
        url: string,
        options: Office.DialogOptions,
        callback: (asyncResult: Office.AsyncResult<Office.Dialog>) => void
    ): void {
        try {
            Office.context.ui.displayDialogAsync(url, options, callback);
        } catch (error) {
            this.logger.error(
                "OfficeWrapper. There was an error with the Office.context.ui.displayDialogAsync() operation",
                error
            );
        }
    }
    removeRoamingSetting(key: string): void {
        Office.context.roamingSettings.remove(key);
    }

    setRoamingSetting(key: string, value: any): void {
        Office.context.roamingSettings.set(key, value);
    }

    getRoamingSetting(key: string): string | null {
        return Office.context.roamingSettings.get(key);
    }

    saveRoamingSettings(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Office.context.roamingSettings.saveAsync((result: Office.AsyncResult<void>) => {
                if (result.error || result.status === Office.AsyncResultStatus.Failed) {
                    reject(result.error);
                } else {
                    resolve(result.value);
                }
            });
        });
    }

    get userProfileEmailAddress(): string {
        return Office.context.mailbox?.userProfile?.emailAddress;
    }

    get userProfileDisplayName(): string {
        return Office.context.mailbox.userProfile.displayName;
    }

    get userProfileEmailDomain(): string {
        return this.userProfileEmailAddress.split("@")[1];
    }

    getSender(): EmailAddressDetails | null {
        return this.currentContextItem?.sender;
    }

    getSenderEmailAddress(): string | null {
        return this.currentContextItem?.sender?.emailAddress;
    }

    getContextMailboxItemSenderEmailDomain(): string | null {
        return this.currentContextItem?.sender?.emailAddress?.split("@")[1];
    }

    getCurrentEmailSubject(): Promise<string> {
        const item = Office.context.mailbox.item;
        const subject = item ? item.subject : "";
        if (typeof subject === "string" || (subject as any) instanceof String) {
            return Promise.resolve(subject);
        } else {
            return new Promise<string>((resolve, reject) => {
                (subject as any).getAsync((getSubjectResult: any) => {
                    if (!getSubjectResult.error) {
                        resolve(getSubjectResult.value);
                    } else {
                        reject(getSubjectResult.error);
                    }
                });
            });
        }
    }

    get currentContextItem(): MailboxItem {
        return Office.context.mailbox.item;
    }

    getCurrentEmailBody(): Promise<string> {
        const item = Office.context.mailbox.item;
        const body = item ? item.body : "";
        if (typeof body === "string" || (body as any) instanceof String) {
            return Promise.resolve(body.toString());
        } else {
            return new Promise<string>((resolve, reject) => {
                body.getAsync(Office.CoercionType.Text, (result) => {
                    if (!result.error) {
                        resolve(result.value);
                    } else {
                        reject(result.error);
                    }
                });
            });
        }
    }

    getCurrentEmailAttachmentDetails(): AttachmentDetails[] {
        const item = Office.context.mailbox.item;
        return item.attachments;
    }

    getCurrentEmailToRecipients(): EmailAddressDetails[] {
        return this.currentContextItem?.to;
    }

    getCurrentEmailCcRecipients(): EmailAddressDetails[] {
        return this.currentContextItem?.cc;
    }

    getCurrentEmailDate(): Date {
        return this.currentContextItem.dateTimeCreated;
    }

    isMailboxVersionSupported(versionToCheck: string): boolean {
        return Office.context.requirements.isSetSupported("mailbox", versionToCheck);
    }

    getInternetHeader(headerName: string): Promise<string | null> {
        const mailboxItem = this.currentContextItem;

        return new Promise((resolve, reject) => {
            mailboxItem.internetHeaders.getAsync(
                [headerName],
                (asyncResult: Office.AsyncResult<Office.InternetHeaders>) => {
                    if (asyncResult.error) {
                        resolve(null);
                    } else {
                        const headerValue = asyncResult.value ? (asyncResult.value as any)[headerName] : null;
                        resolve(headerValue);
                    }
                }
            );
        });
    }

    removeInternetHeaders(headerNames: string[]): Promise<void> {
        const mailboxItem = this.currentContextItem;

        return new Promise((resolve, reject) => {
            mailboxItem.internetHeaders.removeAsync(
                headerNames,
                (asyncResult: Office.AsyncResult<Office.InternetHeaders>) => {
                    if (asyncResult.error) {
                        reject(asyncResult.error);
                    } else {
                        resolve();
                    }
                }
            );
        });
    }

    setInternetHeaders(headers: Object): Promise<void> {
        const mailboxItem = this.currentContextItem;

        return new Promise((resolve, reject) => {
            mailboxItem.internetHeaders.setAsync(headers, (asyncResult: Office.AsyncResult<void>) => {
                if (asyncResult.error) {
                    reject(asyncResult.error);
                } else {
                    resolve();
                }
            });
        });
    }

    convertToRestId(item: MailboxItem): string {
        return Office.context.mailbox.convertToRestId(item.itemId, Office.MailboxEnums.RestVersion.v2_0);
    }

    getCurrentMessageNrn(): string {
        const mailboxItem = this.currentContextItem;
        const restId = this.convertToRestId(mailboxItem);
        return `nrn:email:message:ms-graph:${restId}`;
    }

    getRestUrl(): string {
        return Office.context.mailbox.restUrl;
    }

    private async getCallbackToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            Office.context.mailbox.getCallbackTokenAsync((asyncResult) => {
                if (asyncResult.error) {
                    this.logger.error(
                        "OfficeWrapper. There was an error retrieving ewsCallbackToken",
                        asyncResult.error
                    );
                    reject(asyncResult.error);
                } else {
                    resolve(asyncResult.value);
                }
            });
        });
    }

    // under some circumstances the getCallbackTokenAsync method fails with a generic error 9018. it seems to be something on microsoft's side.
    async getCallbackTokenWithRetry(attempt: number = 1, error?: any): Promise<string> {
        const maxAttempts = 5;
        if (attempt > maxAttempts) {
            this.logger.error(`OfficeWrapper. Could not get ewsCallbackToken after ${maxAttempts} attempts`);
            return Promise.reject(error);
        }
        try {
            return await this.getCallbackToken();
        } catch (error) {
            const incrementedAttempt = attempt + 1;
            await new Promise((resolve) => {
                setTimeout(resolve, 500);
            }); // sleep
            return this.getCallbackTokenWithRetry(incrementedAttempt, error);
        }
    }

    getLatestSupportedOfficeApiVersion(): string | null {
        let setIsSupported = true;
        let minorVersion = 0;
        while (setIsSupported) {
            setIsSupported = Office.context.requirements.isSetSupported("mailbox", `1.${minorVersion}`);
            if (!setIsSupported) {
                minorVersion--;
                break;
            }
            minorVersion++;
        }

        return minorVersion >= 0 ? `1.${minorVersion}` : null;
    }

    getClientPlatform(): string {
        const platform = Office.context.diagnostics.platform;

        switch (platform) {
            case Office.PlatformType.Android:
                return "Android";
            case Office.PlatformType.iOS:
                return "iOS";
            case Office.PlatformType.Mac:
                return "Mac";
            case Office.PlatformType.OfficeOnline:
                return "Office Online";
            case Office.PlatformType.PC:
                return "Windows";
            case Office.PlatformType.Universal:
                return "WinRT";
            default:
                return "unknown";
        }
    }

    getClientVersion(): string {
        return Office.context.diagnostics.version;
    }

    getTimezone(): string {
        return Office.context.mailbox.userProfile.timeZone;
    }

    getCurrentMessageId(): string {
        if (Office.context.mailbox.diagnostics.hostName === "OutlookIOS") {
            // itemId is already REST-formatted.
            return Office.context.mailbox.item.itemId;
        } else {
            // Convert to an item ID for API v2.0.
            return Office.context.mailbox.convertToRestId(
                Office.context.mailbox.item.itemId,
                Office.MailboxEnums.RestVersion.v2_0
            );
        }
    }

    async getCcAsync(): Promise<EmailAddressDetails[]> {
        const cc = this.currentContextItem.cc;
        if (cc instanceof Array) {
            return Promise.resolve(cc);
        }
        return new Promise((resolve, reject) => {
            this.currentContextItem.cc.getAsync((result) => {
                if (result.error) {
                    this.logger.error(
                        "OfficeWrapper. There was an error retrieving the cc recipients of the email",
                        result.error
                    );
                    reject(result.error);
                }
                resolve(result.value);
            });
        });
    }

    async getToAsync(): Promise<EmailAddressDetails[]> {
        const to = this.currentContextItem.to;
        if (to instanceof Array) {
            return Promise.resolve(to);
        }
        return new Promise((resolve, reject) => {
            this.currentContextItem.to.getAsync((result) => {
                if (result.error) {
                    this.logger.error(
                        "OfficeWrapper. There was an error retrieving the to recipients of the email",
                        result.error
                    );
                    reject(result.error);
                }
                resolve(result.value);
            });
        });
    }

    async setToAsync(emails: EmailAddressDetails[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.currentContextItem.to.setAsync(emails, (asyncResult: Office.AsyncResult<void>) => {
                if (asyncResult.error) {
                    this.logger.error(
                        "OfficeWrapper. There was an error updating the to recipients",
                        asyncResult.error
                    );
                    reject(asyncResult.error);
                }
                resolve(asyncResult.value);
            });
        });
    }

    async setCcAsync(emails: EmailAddressDetails[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.currentContextItem.cc.setAsync(emails, (asyncResult: Office.AsyncResult<void>) => {
                if (asyncResult.error) {
                    this.logger.error(
                        "OfficeWrapper. There was an error updating the cc recipients",
                        asyncResult.error
                    );
                    reject(asyncResult.error);
                }
                resolve(asyncResult.value);
            });
        });
    }

    isReadMode(): boolean {
        return !!Office.context.mailbox.item.itemId;
    }
}

import { LocalStorageKeys, SessionStorageKeys } from "../models/StorageKeys";
import { Logger } from "./Logger";

/* istanbul ignore file */
export class StorageWrapper {
    constructor(private logger: Logger) {}

    saveSessionStorage(key: SessionStorageKeys, value: string): void {
        try {
            sessionStorage.setItem(key, value);
        } catch (error) {
            this.logger.error(`StorageWrapper. Failed to save sessionStorage. key: ${key}, value: ${value}`, error);
        }
    }

    loadSessionStorage(key: SessionStorageKeys): string | null {
        try {
            return sessionStorage.getItem(key);
        } catch (error) {
            this.logger.error(`StorageWrapper. Failed to retrieve sessionStorage. key: ${key}`, error);
            return null;
        }
    }

    saveLocalStorage(key: LocalStorageKeys, value: string): void {
        try {
            localStorage.setItem(key, value);
        } catch (error) {
            this.logger.error(`StorageWrapper. Failed to save localStorage. key: ${key}, value: ${value}`, error);
        }
    }

    loadLocalStorage(key: LocalStorageKeys): string | null {
        try {
            return localStorage.getItem(key);
        } catch (error) {
            this.logger.error(`StorageWrapper. Failed to retrieve localStorage. key: ${key}`, error);
            return null;
        }
    }

    removeLocalStorageItem(key: LocalStorageKeys): void {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            this.logger.error(`StorageWrapper. Failed to remove localStorage key: ${key}`, error);
        }
    }
}

// These values will be pulled from default.json by node-config module and webpack define plugin.
import { Logger } from "./Logger";

declare var __HOST_NAME_WITH_PROTOCOL__: string;
declare var __ORIGIN_URL__: string;
declare var __REDIRECT_URL__: string;
declare var __VERSION__: string;
declare var __WEB_APP_URL__: string;
declare var __FROALA_LICENSE_KEY__: string;

export class ConfigurationService {
    constructor(private logger: Logger) {
        this.logger.info(
            `Configuration: hostNameWithProtocol = ${__HOST_NAME_WITH_PROTOCOL__}; originURL = ${__ORIGIN_URL__}; redirectURL = ${__REDIRECT_URL__}; webAppURL = ${__WEB_APP_URL__}; froala license key declares;`
        );
    }

    get originURL(): string {
        return __ORIGIN_URL__;
    }

    get redirectURL(): string {
        return __REDIRECT_URL__;
    }

    get hostNameWithProtocol(): string {
        return __HOST_NAME_WITH_PROTOCOL__;
    }

    get version(): string {
        return __VERSION__;
    }

    get webAppURL(): string {
        return __WEB_APP_URL__;
    }

    get froalaLicenseKey(): string {
        return __FROALA_LICENSE_KEY__;
    }
}

// Require Editor CSS files.
import "./Editor.less";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/themes/gray.css";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import FroalaEditorComponent from "react-froala-wysiwyg";
import { ConfigurationService } from "../../../services/ConfigurationService";

// Import all Froala Editor plugins;
import "froala-editor/js/plugins.pkgd.min.js";

export interface HTMLEditorProps extends LocalizeContextProps {
    value: string | undefined;
    onRemarksUpdate: (remarks: string | undefined) => void;
    configService?: ConfigurationService;
    isFiling: boolean;
}

export interface HTMLEditorComponentState {
    remarks: string;
}

export const HTMLEditorComponentDefaultState: HTMLEditorComponentState = {
    remarks: "",
};

class HTMLEditor extends React.Component<HTMLEditorProps, HTMLEditorComponentState> {
    constructor(props: HTMLEditorProps) {
        super(props);
        this.state = HTMLEditorComponentDefaultState;

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({ remarks: this.props.value });
        }
    }

    async componentDidUpdate(prevProps: Readonly<HTMLEditorProps>, prevState: Readonly<HTMLEditorComponentState>) {
        // updating text value in editor if email changes while add-in is pinned
        if (this.props.value !== prevProps.value && this.props.value) {
            this.setState({ remarks: this.props.value });
        }
    }

    onValueChanged(content: any) {
        this.setState({
            remarks: content,
        });
        this.props.onRemarksUpdate(content);
    }

    changeTooltipText(commandName: string, tooltipText: string) {
        const tooltipElements = document.querySelectorAll(`.fr-command[data-cmd="${commandName}"]`);
        tooltipElements.forEach((tooltipElement) => {
            tooltipElement.setAttribute("title", tooltipText);
        });
    }

    updateTooltips() {
        const baseKey = "APP.FROALA_EDITOR.TOOLBAR.";
        const tooltips = {
            bold: "BOLD",
            italic: "ITALIC",
            underline: "UNDERLINE",
            fontSize: "FONT_SIZE",
            textColor: "TEXT_COLOR",
            backgroundColor: "TEXT_HIGHLIGHT",
            fontFamily: "FONT_FAMILY",
        };

        Object.entries(tooltips).forEach(([command, key]) => {
            this.changeTooltipText(command, this.props.translate(`${baseKey}${key}`) as string);
        });
    }

    onEditorInitialized() {
        this.updateTooltips();
    }

    render(): JSX.Element {
        return (
            <div className={`newforma-editorContainer ${this.props.isFiling ? "disable-editor" : ""}`}>
                <FroalaEditorComponent
                    model={this.state.remarks ? this.state.remarks : this.props.value}
                    config={{
                        toolbarButtons: [
                            "bold",
                            "italic",
                            "underline",
                            "fontSize",
                            "textColor",
                            "backgroundColor",
                            "fontFamily",
                        ],
                        key: this.props.configService?.froalaLicenseKey,
                        attribution: false,
                        editorClass: "newforma-froalaEditor",
                        wordCounterCount: false,
                        charCounterCount: false,
                        quickInsertEnabled: false,
                        imageEditButtons: [],
                        tableEditButtons: [],
                        heightMin: 50,
                        heightMax: 200,
                        theme: "gray",
                        colorsStep: 7,
                        tableDefaultWidth: "auto",
                        tableInsertHelper: false,
                        htmlUntouched: true,
                        fontSize: ["8", "10", "12", "14", "16", "18", "20", "24", "36"],
                        fontSizeDefaultSelection: "10",
                        fontSizeUnit: "pt",
                        linkEditButtons: [],
                        lineBreakerTags: [],
                        events: {
                            initialized: () => {
                                this.onEditorInitialized();
                            },
                        },
                    }}
                    onModelChange={this.onValueChanged}
                    tag="textarea"
                />
            </div>
        );
    }
}

export default withLocalize(HTMLEditor);

import { Project, ProjectsResponse } from "../../models/ProjectsResponse";
import { Request } from "aws-sign-web";
import { KeywordListType, Paging, ProjectKeywordsResponse } from "../../models/ProjectKeywordsResponse";
import {
    TeamGroup,
    TeamMember,
    TeamMemberNormalized,
    TeamMemberType,
    TeamMemberView,
    TeamViewResponse,
} from "../../models/projectTeam/TeamViewResponse";
import { Logger } from "../Logger";
import { NewformaApiClient } from "./NewformaApiClient";
import { UserDisplayService } from "../UserDisplayService";
import { HttpRequestWrapper } from "../HttpRequestWrapper";
import { NrnServiceWrapper } from "../NrnServiceWrapper";
import { OfficeRoamingSettings } from "../OfficeRoamingSettings";
import { GetProjectDetailsResponse } from "../../models/GetProjectDetailsResponse";
import { ProjectType } from "../../models/projects/ProjectType";

import { CreateProjectTeamMembersResponse } from "../../models/projectTeam/CreateProjectTeamMembersResponse";
import {
    CreateProjectTeamMemberOperation,
    CreateProjectTeamMemberOps,
    CreateProjectTeamMembersRequest,
} from "../../models/projectTeam/CreateProjectTeamMembersRequest";
import { ProjectSettingsResponse } from "../../models/projects/ProjectSettingsResponse";
import { ContractsResponse } from "../../models/projects/ContractsResponse";
import { IProjectsService } from "./IProjectsService";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { StorageWrapper } from "../../services/StorageWrapper";
import { LocalStorageKeys } from "../../models/StorageKeys";
import { Log } from "../Logger";

const default_maxProjectPageSize: number = 2000;

export class ProjectsApiService implements IProjectsService {
    private readonly projectsUri = "/v1/projects";
    // empirically determined through testing as a good fit for memory used and response
    // private default_maxProjectPageSize:number = 2000;

    private _maxProjectPageSize: number = default_maxProjectPageSize;

    constructor(
        private logger: Logger,
        private newformaApiClient: NewformaApiClient,
        private userDisplayService: UserDisplayService,
        private requestWrapper: HttpRequestWrapper,
        private nrnServiceWrapper: NrnServiceWrapper,
        private officeRoamingSettings: OfficeRoamingSettings,
        private storageWrapper: StorageWrapper
    ) {
        // check to see if the key exists
        const value = this.storageWrapper.loadLocalStorage(LocalStorageKeys.maxProjectPageSize);
        if (value === null || value === "") {
            // no then create it
            this.storageWrapper.saveLocalStorage(
                LocalStorageKeys.maxProjectPageSize,
                this._maxProjectPageSize.toString()
            );
        } else {
            this.maxProjectPageSize = +value;
        }
    }

    get maxProjectPageSize() {
        return this._maxProjectPageSize;
    }

    set maxProjectPageSize(PageSize: number) {
        if (PageSize <= 0 || isNaN(PageSize)) {
            this._maxProjectPageSize = default_maxProjectPageSize;
        } else {
            this._maxProjectPageSize = PageSize;
        }
    }

    async getMyProjects(): Promise<ProjectsResponse> {
        this.logger.info(`In getMyProjects - Retrieving my project list`);
        const projectsResponse = await this.getProjects(undefined, undefined, true);
        this.logger.info(`Retrieved my project list`);
        return this.getUniqueWithoutShared(projectsResponse);
    }

    async getGlobalProjects(paging: Paging): Promise<ProjectsResponse> {
        const fileEmailConversion = this.officeRoamingSettings.getFileEmailConversation();
        if (fileEmailConversion && !this.officeRoamingSettings.getShowAllGlobalProjects()) {
            return {
                projects: [],
            };
        }
        this.logger.info("getGlobalProjects");
        const offsetToken = paging.offsetToken;
        const projectsResponse = await this.getProjects(undefined, offsetToken);
        return this.getUniqueWithoutShared(projectsResponse);
    }

    async getInternalCloudProjects(): Promise<Project[]> {
        const projects = await this.getInternalProjects();
        return projects.filter((project) => project.type !== ProjectType.npc);
    }

    getProjects(filter?: string, offsetToken?: string, disableCloudCache?: boolean): Promise<ProjectsResponse> {
        this.logger.info(`Retrieving project list with filter '${filter || ""}' and offsetToken ${offsetToken}`);

        let urlPart = `?maxSize=${this.maxProjectPageSize}`;
        if (filter && filter !== "infoExchangeEnabled") {
            urlPart += `&filter=${filter}`;
        }
        if (offsetToken) {
            urlPart += `&offsetToken=${offsetToken}`;
        }
        if (disableCloudCache) {
            urlPart += "&disableCache=true";
        }
        if (filter === "infoExchangeEnabled") {
            urlPart += `&infoExchangeEnabled=true`;
        }
        return this.makeRequest(this.getOptions(urlPart));
    }

    async getProjectsSupportingActionItems(): Promise<ProjectsResponse> {
        return this.getProjects("actionitems");
    }

    async getProjectsSupportingSubmittals(): Promise<ProjectsResponse> {
        const projectsResponse = await this.getProjects("submittals");
        if (projectsResponse?.projects.length === 0) {
            this.logger.info(
                "There were no projects in the list, you might have no project access, NL outdated or NPC activity center is disabled"
            );
        }
        return projectsResponse;
    }

    async getProjectsSupportingRfis(): Promise<ProjectsResponse> {
        const projectsResponse = await this.getProjects("rfis");
        if (projectsResponse?.projects.length === 0) {
            this.logger.info(
                "There were no projects in the list, you might have no project access, NL outdated or NPC activity center is disabled"
            );
        }
        return projectsResponse;
    }
    async getProjectsSupportingFileTransfer(): Promise<ProjectsResponse> {
        const projectsResponse = await this.getProjects("infoExchangeEnabled");
        if (projectsResponse?.projects.length === 0) {
            this.logger.info(
                "There were no projects in the list, you might have no project access, NL outdated or NPC activity center is disabled"
            );
        }
        return projectsResponse;
    }

    getProjectsSupportingSearch(disableCache: boolean): Promise<ProjectsResponse> {
        return this.getProjects(undefined, undefined, disableCache);
    }

    async getProjectKeywords(
        projectNrn: string,
        keywordListType: KeywordListType,
        query?: string
    ): Promise<ProjectKeywordsResponse> {
        this.logger.info(`Retrieving project keywords ${keywordListType}`);
        const result = await this.getProjectKeywordsPage(projectNrn, keywordListType, undefined, query);

        let offsetToken = result.paging?.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getProjectKeywordsPage(projectNrn, keywordListType, offsetToken, query);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.paging?.offsetToken;
        }

        result.paging = undefined;
        return result;
    }

    async getProjectSubmittalPurposesKeywords(projectNrn: string): Promise<ProjectKeywordsResponse> {
        this.logger.info("Retrieving submittal purposes keywords");
        const result = await this.getProjectSubmittalPurposesKeywordsPage(projectNrn);

        let offsetToken = result.paging?.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getProjectSubmittalPurposesKeywordsPage(projectNrn, offsetToken);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.paging?.offsetToken;
        }

        result.paging = undefined;
        return result;
    }

    async getProjectForwardSubmittalPurposesKeywords(projectNrn: string): Promise<ProjectKeywordsResponse> {
        this.logger.info("Retrieving forward submittal purposes keywords");
        const result = await this.getProjectForwardSubmittalPurposesKeywordsPage(projectNrn);

        let offsetToken = result.paging?.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getProjectForwardSubmittalPurposesKeywordsPage(projectNrn, offsetToken);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.paging?.offsetToken;
        }

        result.paging = undefined;
        return result;
    }

    async getTeamMembersNormalized(projectNrn: string, query: string = ""): Promise<TeamMemberNormalized[]> {
        Log.info("getTeamMembersNormalized");
        const teamViewResponse = await this.getTeamMembers(projectNrn, query);
        return this.normalizeTeamMembers(teamViewResponse.items);
    }

    async getTeamMembers(projectNrn: string, query: string = "", offsetToken: string = ""): Promise<TeamViewResponse> {
        this.logger.info("Retrieving project team members");
        const maxPageSize = 50;
        let urlPath = `/${projectNrn}/team/view?maxSize=${maxPageSize}`;
        if (offsetToken) {
            urlPath = `${urlPath}&offsetToken=${offsetToken}`;
        }
        if (query) {
            urlPath = `${urlPath}&q=${query}`;
        }

        return this.makeRequest(this.getOptions(urlPath));
    }

    async getAllTeamMembersNormalized(projectNrn: string, query: string = ""): Promise<TeamMemberNormalized[]> {
        Log.info("getAllTeamMembersNormalized");
        const result = await this.getTeamMembers(projectNrn, query);

        let offsetToken = result.paging?.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getTeamMembers(projectNrn, query, offsetToken);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.paging?.offsetToken;
        }
        Log.info("-- After");
        return this.normalizeTeamMembers(result.items);
    }

    async createProjectTeamMembers(emails: string[], projectNrn: string): Promise<CreateProjectTeamMembersResponse> {
        this.logger.info("Adding new project team members");
        const operations: CreateProjectTeamMemberOperation[] = emails.map((email) => ({
            op: CreateProjectTeamMemberOps.add,
            params: { email },
        }));
        const requestBody: CreateProjectTeamMembersRequest = {
            operations,
        };
        const payload = JSON.stringify(requestBody);

        const url = `${this.newformaApiClient.getHostNameWithProtocol()}${this.projectsUri}/${encodeURIComponent(
            projectNrn
        )}/team/members`;

        const options: Request = {
            hostname: this.newformaApiClient.getHostName(),
            url: url,
            method: "POST",
            body: payload,
            headers: {
                "x-newforma-agent": this.newformaApiClient.getNewformaAgent(),
            },
        };
        return this.makePostRequest(options, payload);
    }

    getProjectDetails(projectNrn: string): Promise<GetProjectDetailsResponse> {
        this.logger.info("Retrieving project details");
        const urlPart = `/${projectNrn}`;
        return this.makeRequest(this.getOptions(urlPart));
    }

    async getProjectEmailFilingAddress(projectNrn: string): Promise<string | null> {
        this.logger.info("Retrieving project email filing address");
        if (!this.nrnServiceWrapper.isCloudProject(projectNrn)) {
            this.logger.info("can only retrieve project email filing address for cloud projects");
            return null;
        }

        const projectDetails = await this.getProjectDetails(projectNrn);
        const filingAddress = projectDetails.fullEmailFilingAddress;

        this.logger.info(`retrieved project email filing address: ${filingAddress}`);
        return filingAddress;
    }

    async getProjectSettings(projectNrn: string): Promise<ProjectSettingsResponse | null> {
        const urlPart = `/${projectNrn}/settings`;

        if (this.nrnServiceWrapper.isCloudProject(projectNrn)) {
            return null;
        }
        this.logger.info("getting project settings");
        return this.makeRequest(this.getOptions(urlPart));
    }

    async getTransferMethods(projectNrn: string): Promise<ProjectKeywordsResponse> {
        if (this.nrnServiceWrapper.isCloudProject(projectNrn)) {
            return { items: [], allowCustom: false };
        }
        return this.getProjectKeywords(projectNrn, KeywordListType.TransferMethods);
    }

    async getContracts(projectNrn: string): Promise<ContractsResponse> {
        this.logger.info("retrieving contracts for project");

        const result: ContractsResponse = await this.getContractsPage(projectNrn);

        let offsetToken = result.offsetToken;
        while (offsetToken) {
            const nextPage = await this.getContractsPage(projectNrn, offsetToken);
            result.items = result.items.concat(nextPage.items);
            offsetToken = nextPage.offsetToken;
        }

        result.offsetToken = undefined;

        return result;
    }

    private async getInternalProjects(): Promise<Project[]> {
        const projectsResponse = await this.getProjects();
        return projectsResponse.projects.filter((project) => project.type !== ProjectType.shared);
    }

    private getProjectKeywordsPage(
        projectNrn: string,
        keywordListType: KeywordListType,
        offsetToken?: string,
        query?: string
    ): Promise<ProjectKeywordsResponse> {
        const urlPart = `/${projectNrn}/keywords?listType=${keywordListType}&offsetToken=${offsetToken ||
            ""}&query=${query || ""}`;
        return this.makeRequest(this.getOptions(urlPart));
    }

    private getProjectSubmittalPurposesKeywordsPage(
        projectNrn: string,
        offsetToken?: string
    ): Promise<ProjectKeywordsResponse> {
        const urlPart = `/${projectNrn}/submittals/workflowactions/log/keywords?offsetToken=${offsetToken || ""}`;
        return this.makeRequest(this.getOptions(urlPart));
    }

    private getProjectForwardSubmittalPurposesKeywordsPage(
        projectNrn: string,
        offsetToken?: string
    ): Promise<ProjectKeywordsResponse> {
        const urlPart = `/${projectNrn}/submittals/workflowactions/forward/keywords?offsetToken=${offsetToken || ""}`;
        return this.makeRequest(this.getOptions(urlPart));
    }

    private getContractsPage(projectNrn: string, offsetToken?: string): Promise<ContractsResponse> {
        const maxPageSize = 50;
        const offsetParam = offsetToken ? `&offsetToken=${offsetToken}` : "";
        const urlPart = `/${projectNrn}/contracts?maxSize=${maxPageSize}${offsetParam}`;
        return this.makeRequest(this.getOptions(urlPart));
    }

    private getOptions(urlPart: string, method: string = "GET"): Request {
        return {
            hostname: this.newformaApiClient.getHostName(),
            url: `${this.newformaApiClient.getHostNameWithProtocol()}${this.projectsUri}${urlPart}`,
            method,
            headers: { "x-newforma-agent": this.newformaApiClient.getNewformaAgent() },
        };
    }

    private normalizeTeamMembers(teamMembers: TeamMemberView[]): TeamMemberNormalized[] {
        Log.info(`NormalizeTeamMembers`);

        const normalizedTeamMembers: TeamMemberNormalized[] = teamMembers.map((teamMember) => {
            const { MEMBER, GROUP } = TeamMemberType;
            const teamMemberDetails = teamMember.details as TeamMember;
            const { email, firstName, lastName, discipline } = teamMemberDetails;

            const nrn = teamMember.nrn;
            let displayName: string = "";
            // teamMember can be of type GROUP or type MEMBER
            if (teamMember.type === GROUP) {
                displayName = (teamMember.details as TeamGroup).name;
                return { displayName, nrn };
            }
            displayName = this.userDisplayService.getUserDisplayName(email, firstName, lastName);

            return {
                displayName,
                nrn,
                email,
                discipline: discipline?.name ?? "",
            };
        });

        return normalizedTeamMembers;
    }

    private getUniqueWithoutShared(
        projectsResponse: ProjectsResponse
    ): ProjectsResponse | PromiseLike<ProjectsResponse> {
        return {
            projects: this.getUniqueProjects(this.withoutShared(projectsResponse?.projects)),
            paging: projectsResponse?.paging,
        };
    }
    private withoutShared(projects: Project[]): Project[] {
        return projects?.filter((project) => project.type !== ProjectType.shared);
    }
    private getUniqueProjects(projects: Project[]): Project[] {
        return projects?.filter((a, i) => projects.findIndex((s) => a.nrn === s.nrn) === i);
    }

    private async makePostRequest<T>(request: Request, data?: any): Promise<T> {
        try {
            return await this.newformaApiClient.makeRequest(request, (signedOptions) =>
                this.requestWrapper.post(signedOptions.url, undefined, signedOptions.headers, data)
            );
        } catch (error) {
            const errorMessage = "Something went wrong making request to the NL.";
            this.logger.warning(errorMessage, error);
            const castedError = error as any;
            const newError = Error(castedError.responseText || errorMessage) as any;
            newError.statusCode = castedError.status;
            throw newError;
        }
    }
    private async makeRequest<T>(request: Request, data?: any): Promise<T> {
        try {
            return await this.newformaApiClient.makeRequest(request, (signedOptions) =>
                this.requestWrapper.get(signedOptions.url, undefined, signedOptions.headers, data)
            );
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                throw error;
            }
            const errorMessage = "Something went wrong making request to the NL.";
            this.logger.warning(errorMessage, error);
            const castedError = error as any;
            const newError = Error(castedError.responseText || errorMessage) as any;
            newError.statusCode = castedError.status;
            throw newError;
        }
    }
}

import { IdpConfigurationParameters } from "../models/IdpConfigurationParameters";
import { ConfigurationService } from "./ConfigurationService";

export class UrlBuilder {
    private readonly redirectUrl: string;

    constructor(config: ConfigurationService) {
        this.redirectUrl = config.redirectURL;
    }

    private validateRequiredSettings(idpConfigurationParameters: IdpConfigurationParameters, requiredKeys: string[]) {
        for (const key of requiredKeys) {
            if (!(idpConfigurationParameters as any)[key]) {
                throw new Error(`Missing required setting: ${key}`);
            }
        }
    }

    refreshUrl(urlTemplate: string, idpConfigurationParameters: IdpConfigurationParameters): string {
        const requiredKeys = ["response_type", "nonce", "client_id", "response_mode"];
        this.validateRequiredSettings(idpConfigurationParameters, requiredKeys);

        return urlTemplate
            .replace("{response_type}", idpConfigurationParameters.response_type || "")
            .replace("{nonce}", idpConfigurationParameters.nonce || "")
            .replace("{client_id}", idpConfigurationParameters.client_id || "")
            .replace("{redirect_uri}", encodeURIComponent(this.redirectUrl))
            .replace("{response_mode}", idpConfigurationParameters.response_mode || "")
            .replace("{access_type}", idpConfigurationParameters.access_type || "")
            .replace("{scope}", idpConfigurationParameters.scope || "")
            .replace("{tenant}", idpConfigurationParameters.tenant || "");
    }

    loginUrl(urlTemplate: string, idpConfigurationParameters: IdpConfigurationParameters): string {
        const requiredKeys = ["response_type", "response_mode", "nonce", "client_id", "token_response_type"];
        this.validateRequiredSettings(idpConfigurationParameters, requiredKeys);

        return urlTemplate
            .replace("{response_type}", encodeURIComponent(idpConfigurationParameters.response_type || ""))
            .replace("{response_mode}", idpConfigurationParameters.response_mode || "")
            .replace("{nonce}", idpConfigurationParameters.nonce || "")
            .replace("{client_id}", idpConfigurationParameters.client_id || "")
            .replace("{prompt}", idpConfigurationParameters.prompt || "")
            .replace("{tenant}", idpConfigurationParameters.tenant || "")
            .replace("{scope}", encodeURIComponent(idpConfigurationParameters.scope || ""))
            .replace("{token_response_type}", idpConfigurationParameters.token_response_type || "")
            .replace("{token_scope}", encodeURIComponent(idpConfigurationParameters.token_scope || ""))
            .replace("{access_type}", idpConfigurationParameters.access_type || "")
            .replace("{redirect_uri}", encodeURIComponent(this.redirectUrl));
    }
}

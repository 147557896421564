import { OfficeWrapper } from "../../services/OfficeWrapper";
import { OpenIDCredentials } from "../../models/OpenIDCredentials";
import AsyncResult = Office.AsyncResult;
import Dialog = Office.Dialog;
import { ConfigurationService } from "../../services/ConfigurationService";
import { Logger } from "../../services/Logger";
import { WindowWrapper } from "../../services/WindowWrapper";

export default class LoginDialog {
    constructor(
        private office: OfficeWrapper,
        private configService: ConfigurationService,
        private windowWrapper: WindowWrapper,
        private logger: Logger
    ) {}

    /**
     *
     * @param loginUrl
     * @param promptBeforeOpen This prevents the "Allow popup" dialog from showing. This COULD cause some popup blockers to
     * block the login dialog, but so far in testing, it hasn't made a difference except when refreshing tokens,
     * because then it pops up without any user action triggering it. It seems that as long as the popup opens in
     * response to a user click, it is ok.
     */
    async openAuthDialog(loginUrl: string, promptBeforeOpen: boolean): Promise<OpenIDCredentials> {
        return new Promise<OpenIDCredentials>((resolve, reject) => {
            this.office.displayDialogAsync(
                `${this.configService.originURL}/index.html?redirectUrl=${encodeURIComponent(loginUrl)}#loginrouting`,
                {
                    height: 50,
                    width: 50,
                    promptBeforeOpen: promptBeforeOpen,
                },
                (asyncResult: AsyncResult<Dialog>) => {
                    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                        reject(asyncResult.error);
                        window.localStorage.setItem("inProgressToLogin", false.toString());

                        // this allows the event listener for storage in LoginComponent to be run
                        window.dispatchEvent(new Event("storage"));
                        return;
                    }

                    const dialog = asyncResult.value;

                    window.localStorage.setItem("inProgressToLogin", true.toString());

                    /*Messages are sent by developers programmatically from the dialog using office.context.ui.messageParent(...)*/
                    /*Events are sent by the platform in response to user actions or errors. For example, the dialog is closed via the 'x' button*/
                    dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
                        try {
                            const jsonResult = JSON.parse(arg.message);
                            if (!jsonResult) {
                                reject(new Error("Ill-formatted or missing message"));
                                window.localStorage.setItem("inProgressToLogin", false.toString());
                                return;
                            }

                            const url = new URL(jsonResult.value.replace("#", "?"));
                            const idToken = url.searchParams.get("id_token");
                            const code = url.searchParams.get("code");

                            if (!idToken || !code) {
                                const errorMessage = `Required parameters "id_token" and "code" cannot be found in url : "${jsonResult.value}"`;
                                reject(new Error(errorMessage));
                                return;
                            }

                            resolve({ idToken, code });
                        } finally {
                            dialog.close();
                        }
                    });

                    dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg: any) => {
                        this.logger.warning(`Login dialog closed unexpectedly\n${JSON.stringify(arg)}`);
                        const errorMessage = `Unexpected error in LoginDialog.eventHandler: ${JSON.stringify(arg)}`;
                        window.localStorage.setItem("inProgressToLogin", false.toString());

                        // this allows the event listener for storage in LoginComponent to be run
                        window.dispatchEvent(new Event("storage"));

                        reject(new Error(errorMessage));
                    });
                }
            );
        });
    }

    async refreshToken(refreshUrl: string): Promise<OpenIDCredentials> {
        console.log("refreshing credentials");
        return new Promise<OpenIDCredentials>((resolve, reject) => {
            let count = 0;
            const refreshFrame = this.windowWrapper.getRefreshFrame();

            this.logger.info(`LoginDialog: In refreshToken method`);
            this.windowWrapper.openUrlInFrame(refreshUrl, this.windowWrapper.refreshFrameName);

            const iframeChecker = setInterval(() => {
                count++;
                if (count >= 10) {
                    this.logger.info(
                        `LoginDialog: count is greater than or equal to 10. iframeChecker: ${iframeChecker}`
                    );
                    clearInterval(iframeChecker);
                    try {
                        this.windowWrapper.openUrlInFrame("about:blank", this.windowWrapper.refreshFrameName);
                    } catch (error) {
                        this.logger.error(`LoginDialog: failed to openUrlInFrame in refresh method`);
                    }
                    this.logger.error(`LoginDialog: failed to refresh credentials after 10 seconds`);
                    reject(new Error("Unable to refresh credentials after 10 seconds"));
                }

                if (
                    refreshFrame &&
                    refreshFrame.location.origin === this.windowWrapper.locationOrigin() &&
                    (refreshFrame.location.search.includes("code") || refreshFrame.location.hash.includes("code"))
                ) {
                    clearInterval(iframeChecker);
                    this.logger.info(`LoginDialog: Trying to refresh credentials`);
                    this.windowWrapper.openUrlInFrame("about:blank", this.windowWrapper.refreshFrameName);

                    this.logger.info(`LoginDialog: Trying to refresh credentials - after opening url in frame`);

                    const rawParams =
                        refreshFrame.location.search.length > 0
                            ? refreshFrame.location.search
                            : decodeURIComponent(refreshFrame.location.hash);
                    const params = new URLSearchParams(rawParams.slice(1));

                    const idToken = params.get("id_token") as string;
                    const code = params.get("code") as string;

                    this.logger.info(`LoginDialog: credentials successfully refreshed`);
                    console.log("credentials successfully refreshed");
                    resolve({
                        idToken: idToken,
                        code: code,
                    });
                }
            }, 1000);
        });
    }
}

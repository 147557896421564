import "./ProjectTeamComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Icon, ITag, MessageBarType, Panel, PanelType } from "office-ui-fabric-react";
import { Logger } from "../../services/Logger";
import { IProjectsService } from "../../services/NewformaApi/IProjectsService";
import { MailboxItem, OfficeWrapper } from "../../services/OfficeWrapper";
import SuggestedProjectPickerComponent from "../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import { SendAndFileHelpers } from "../../helpers/SendAndFile/SendAndFileHelpers";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../services/AnalyticsManager";
import AddNewTeamMemberComponent from "./addNewTeamMember/AddNewTeamMemberComponent";
import InsertTeamMemberComponent from "./insertTeamMember/InsertTeamMemberComponent";

export enum ProjectTeamComponentView {
    insert,
    new,
}

export interface ProjectTeamComponentProps extends LocalizeContextProps {
    logger: Logger;
    projectsService: IProjectsService;
    officeWrapper: OfficeWrapper;
    smartFilingManager: SmartFilingManager;
    mailBoxItem: MailboxItem | null;
    onExpiredSession: () => void;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    showPanel: boolean;
    onDismiss: () => void;
    sendAndFileHelpers: SendAndFileHelpers;
    analyticsManager: AnalyticsManager;
    theme: string;
}

export interface ProjectTeamComponentState {
    projects: ITag[];
    selectedProject: ITag | null;
    isLoadingProjects: boolean;
    view: ProjectTeamComponentView;
}

export const defaultProjectTeamComponentState: ProjectTeamComponentState = {
    projects: [],
    selectedProject: null,
    isLoadingProjects: false,
    view: ProjectTeamComponentView.insert,
};

class ProjectTeamComponent extends React.Component<ProjectTeamComponentProps, ProjectTeamComponentState> {
    constructor(props: ProjectTeamComponentProps, context: ProjectTeamComponentState) {
        super(props, context);

        this.state = defaultProjectTeamComponentState;
    }

    async componentDidMount(): Promise<void> {
        this.props.logger.info("ProjectTeamComponent mounted");
    }

    private async loadProjects(): Promise<void> {
        this.setState({ isLoadingProjects: true, projects: [] });
        try {
            const projects = await this.props.projectsService.getInternalCloudProjects();
            const projectsITag: ITag[] = projects.map((project) => {
                const projectDisplay = project.number ? `${project.number} - ${project.name}` : project.name;
                return { key: project.nrn, name: projectDisplay, data: project };
            });
            this.setState({ projects: projectsITag });
        } catch (error) {
            this.handleApiError(error, this.props.translate("SHARED.ERRORS.LOADING_PROJECTS_GENERIC") as string);
            this.setState({
                projects: [],
            });
        } finally {
            this.setState({ isLoadingProjects: false });
        }
    }

    private handleApiError(error: any, messageToDisplay?: string) {
        this.props.logger.error(`ProjectTeamComponent API error: ${messageToDisplay}`, error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        if (messageToDisplay) {
            this.props.onShowToast(messageToDisplay, MessageBarType.severeWarning);
        }
    }

    private async onProjectSelected(selectedProject: ITag | null): Promise<void> {
        this.setState({ selectedProject });
    }

    private changeView(): void {
        if (this.state.view === ProjectTeamComponentView.insert) {
            this.props.analyticsManager.recordEvent(
                AnalyticsCategoryType.UserActions,
                AnalyticsActionType.ProjectTeamAddNewViewed
            );
            this.setState({ view: ProjectTeamComponentView.new });
        } else {
            this.setState({ view: ProjectTeamComponentView.insert });
        }
    }

    render(): JSX.Element {
        return (
            <div className="newforma-projectTeamComponent">
                <Panel
                    isOpen={this.props.showPanel}
                    type={PanelType.smallFixedFar}
                    closeButtonAriaLabel={this.props.translate("APP.SETTINGS.CLOSE") as string}
                    onOuterClick={() => {}}
                    onDismiss={() => this.props.onDismiss()}
                    className="newforma-projectTeamPanel"
                    onOpened={this.loadProjects.bind(this)}
                    layerProps={{ styles: { root: { zIndex: 999998 } } }} // index must be less than the toast
                    data-theme={this.props.theme}
                >
                    <div className="newforma-header">
                        {this.props.translate("APP.COMMANDBAR.PROJECT_TEAM") as string}
                    </div>
                    <div
                        className="newforma-changeTeamViewButton newforma-formSpacing"
                        onClick={this.changeView.bind(this)}
                    >
                        <Icon iconName="PeopleAdd" className="newforma-peopleAddIcon" />
                        <div>
                            {this.state.view === ProjectTeamComponentView.insert
                                ? this.props.translate("PROJECT_TEAM.CHANGE_VIEW_ADD_NEW")
                                : this.props.translate("PROJECT_TEAM.CHANGE_VIEW_INSERT")}
                        </div>
                    </div>
                    <SuggestedProjectPickerComponent
                        className="newforma-formSpacing team-suggestedProjectPickerComponent"
                        logger={this.props.logger}
                        onProjectSelected={this.onProjectSelected.bind(this)}
                        projects={this.state.projects}
                        myProjects={this.state.projects}
                        smartFilingManager={this.props.smartFilingManager}
                        disabled={this.state.isLoadingProjects}
                        isLoadingProjects={this.state.isLoadingProjects}
                        mailboxItem={this.props.mailBoxItem}
                        onRefresh={this.loadProjects.bind(this)}
                        theme={this.props.theme}
                    />
                    {this.state.view === ProjectTeamComponentView.insert ? (
                        <InsertTeamMemberComponent
                            selectedProject={this.state.selectedProject}
                            projectsService={this.props.projectsService}
                            logger={this.props.logger}
                            analyticsManager={this.props.analyticsManager}
                            sendAndFileHelpers={this.props.sendAndFileHelpers}
                            officeWrapper={this.props.officeWrapper}
                            onShowToast={this.props.onShowToast}
                            onExpiredSession={this.props.onExpiredSession}
                            isLoadingProjects={this.state.isLoadingProjects}
                        />
                    ) : (
                        <AddNewTeamMemberComponent
                            selectedProject={this.state.selectedProject}
                            officeWrapper={this.props.officeWrapper}
                            mailboxItem={this.props.mailBoxItem}
                            projectsService={this.props.projectsService}
                            logger={this.props.logger}
                            onExpiredSession={this.props.onExpiredSession}
                            onShowToast={this.props.onShowToast}
                            analyticsManager={this.props.analyticsManager}
                        />
                    )}
                </Panel>
            </div>
        );
    }
}

export default withLocalize(ProjectTeamComponent);

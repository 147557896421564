import { OfficeWrapper } from "./OfficeWrapper";
import { Logger } from "./Logger";
import { GraphRequestWrapper } from "./GraphRequestWrapper";
import {
    MsGraphGetMessageResponse,
    MsGraphGetMessagesResponse,
    MsGraphMessageItem,
} from "../models/MsGraphMessageResponse";
import { EmailListItem, EmailListResponse } from "../models/EmailListResponse";
import { MsGraphGetFolderResponse } from "../models/MsGraphGetFolderResponse";
import { ClearMessageService } from "./ClearMessageService";

export class MsGraphApiService {
    private readonly graphUrl = "https://graph.microsoft.com/v1.0";
    private readonly retryAfterHeaderKey = "Retry-After";
    private readonly defaultRetryTimeInSeconds = 1;
    private readonly maxRetryAttempts = 5;
    private readonly messageListPageSize = 50;
    private readonly filedMessageCategory = ["Filed by Newforma", "Abgelegt durch Newforma", "Archivé par Newforma"];

    constructor(
        private officeWrapper: OfficeWrapper,
        private graphRequestWrapper: GraphRequestWrapper,
        private clearMessageService: ClearMessageService,
        private logger: Logger
    ) {}

    async getCurrentFolderId(): Promise<string> {
        const messageDetails = await this.getCurrentMessageResponseData();
        return messageDetails.parentFolderId;
    }

    async getFolderName(folderId: string): Promise<string> {
        const folderResponseData = await this.getFolderResponseData(folderId);
        return folderResponseData.displayName;
    }

    async getCurrentMessageAsEmailListItem(): Promise<EmailListItem> {
        const messageDetails = await this.getCurrentMessageResponseData();
        return this.convertMsGraphMessageItemToEmailListItem(messageDetails);
    }

    async getFolderEmails(
        folderId: string,
        offsetToken: string | null,
        searchValue: string | null
    ): Promise<EmailListResponse> {
        let emailsGraphResponse;
        emailsGraphResponse = await this.getFolderEmailsResponseData(folderId, offsetToken, searchValue);
        const emailsGraphResponseMapped: EmailListResponse = {
            items: emailsGraphResponse.value.map((email) => {
                return this.convertMsGraphMessageItemToEmailListItem(email);
            }),
            paging: {
                offsetToken: emailsGraphResponse["@odata.nextLink"],
            },
        };
        return emailsGraphResponseMapped;
    }

    async getCurrentClearMessageBody(isFroalaEditorSupported: boolean): Promise<string> {
        const currentEmail = await this.getCurrentMessageResponseData();
        return this.clearMessageService.getClearMessageBody(currentEmail.body.content, isFroalaEditorSupported);
    }

    private async getFolderEmailsResponseData(
        folderId: string,
        offsetToken: string | null,
        searchValue: string | null
    ): Promise<MsGraphGetMessagesResponse> {
        this.logger.info(`Retrieving folder emails from graph API for folder: ${folderId}`);
        const searchFilter = !!searchValue ? `&$search="${searchValue}"` : "";
        let url = `${this.graphUrl}/me/mailFolders/${this.convertToUnifiedId(folderId)}/messages?$top=${
            this.messageListPageSize
        }${searchFilter}`;
        if (!!offsetToken) {
            url = offsetToken;
        }
        return this.makeRequestWithRetry(() => this.graphRequestWrapper.get(url));
    }

    private isFiled(categories: string[]): boolean {
        if (categories && !categories.length) {
            return false;
        }
        return categories.some((r) => this.filedMessageCategory.includes(r));
    }

    async getCurrentMessageResponseData(): Promise<MsGraphGetMessageResponse> {
        this.logger.info("Retrieving current message data from graph API");
        const itemId = this.officeWrapper.getCurrentMessageId();
        const url = `${this.graphUrl}/me/messages/${this.convertToUnifiedId(itemId)}`;
        return this.makeRequestWithRetry(() => this.graphRequestWrapper.get(url));
    }

    async getAttachmentDetails(): Promise<any> {
        const itemId = this.officeWrapper.getCurrentMessageId();
        const url = `${this.graphUrl}/me/messages/${this.convertToUnifiedId(itemId)}/attachments`;
        return this.makeRequestWithRetry(() => this.graphRequestWrapper.get(url));
    }

    private async getFolderResponseData(folderId: string): Promise<MsGraphGetFolderResponse> {
        this.logger.info("Retrieving folder emails from graph API");
        const url = `${this.graphUrl}/me/mailFolders/${this.convertToUnifiedId(folderId)}`;
        return this.makeRequestWithRetry(() => this.graphRequestWrapper.get(url));
    }

    private convertToUnifiedId(id: string) {
        return id.replace(/\//g, "-").replace(/\+/g, "_");
    }

    private convertMsGraphMessageItemToEmailListItem(email: MsGraphMessageItem): EmailListItem {
        return {
            id: email.id,
            sentDateTime: email.sentDateTime,
            hasAttachments: email.hasAttachments,
            ...(email.categories && { isFiled: this.isFiled(email.categories) }),
            subject: email.subject,
            bodyPreview: email.bodyPreview,
            sender: {
                ...(email.sender && { address: email.sender.emailAddress.address }),
                ...(email.sender && { name: email.sender.emailAddress.name }),
            },
            from: {
                ...(email.from && { address: email.from.emailAddress.address }),
                ...(email.from && { name: email.from.emailAddress.name }),
            },
        };
    }

    private async makeRequestWithRetry(request: () => Promise<any>, attemptCount: number = 1): Promise<any> {
        try {
            return await request();
        } catch (error) {
            if (attemptCount === this.maxRetryAttempts) {
                this.logger.error(
                    `MsGraphApiService. Max retry attempts (${this.maxRetryAttempts}) reached for request.`,
                    error
                );
                throw error;
            }

            if ((error as any).status && (error as any).status === 429) {
                const retryAfter =
                    ((error as any).getResponseHeader(this.retryAfterHeaderKey) || this.defaultRetryTimeInSeconds) *
                    1000;
                this.logger.error(
                    `MsGraphApiService. The request was throttled by the office api on attempt (${attemptCount}). retrying after ${retryAfter}ms... `
                );
                await new Promise((resolve) => {
                    setTimeout(resolve, retryAfter);
                });
                return this.makeRequestWithRetry(request, attemptCount + 1);
            }
        }
    }
}
